<template>
  <div>
    <button
      style="display: none"
      type="button"
      class="btn-preview-mail-modal"
      data-bs-toggle="modal"
      data-bs-target="#modalPreviewMail"
    ></button>
    <div
      class="modal fade"
      id="modalPreviewMail"
      tabindex="-1"
      aria-labelledby="modalPreviewMailLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ mailSubject ? mailSubject : "You haven't entered a email subject yet" }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div>{{ mailMessage ? mailMessage : "You haven't entered a email message yet" }}</div>
            <img class="image" v-if="image" :src="image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExchangeStatus",
  props: {
    mailSubject: {
      type: String,
      default: "",
    },
    mailMessage: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
.btn-close {
  color: #222222;
}
.btn-close:hover {
  color: #222222;
}
@media (min-width: 576px) {
  .image {
    max-width: 25%;
    margin: 1.75rem auto;
  }
  .modal-dialog {
    max-width: 60%;
    margin: 1.75rem auto;
  }
}
</style>