<template>
    <div>
        <div class="row mb-2 pe-4">
            <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
            <div class="color-common">
                <!-- <h3 class="title-page mb-0 ps-3">POS STAFF</h3> -->
            </div>
            </div>
            <div class="col-lg-4 col-md-5 col-5 d-flex justify-content-end">
                <material-button
                    @click="addNewField()"
                    :disabled="disableBtnSubmit"
                    class="mx-2 btn-background btn-sm"
                >
                    Add new field
                </material-button>
            </div>
        </div>
        <div class="page-header align-items-start min-vh-100 my-transacions">
            <span class="mask bg-white"></span>
            <div class="container">
                <div class="row">
                    <div class="notifications">
                    <material-alert
                        v-if="error"
                        color="danger"
                        icon="ni ni-like-2 ni-lg"
                        dismissible
                    >
                        {{ error }}
                    </material-alert>
                    </div>
                    <table class="table align-items-center mb-0">
                    <thead>
                        <tr>
                        <th class="text-left font-weight-bolder w-5">
                            FIELD NAME
                        </th>
                        <th class="text-left font-weight-bolder w-5">
                            DISPLAY
                        </th>
                        <th class="text-left font-weight-bolder w-5">
                            REQUIRED
                        </th>
                        <th class="text-left font-weight-bolder w-5">
                            REWARD QUALIFIED
                        </th>
                        <th class="text-left font-weight-bolder w-5">
                            FIELD TYPE
                        </th>
                        <th class="text-left font-weight-bolder w-70">
                            DATA
                        </th>
                        <th class="text-left font-weight-bolder w-5">
                            
                        </th>
                        <th class="text-left font-weight-bolder w-5">
                            
                        </th>
                        </tr>
                    </thead>
                    <tbody v-if="data.length">
                        <tr v-for="(value, key) in data" :key="key">
                        <td class="text-left text-xss">
                            {{ value.field_name }}
                            <br />
                            <span v-if="value.field_name === 'Date of Birth' && value.is_display" style="font-size: 11px;font-style: italic;">Turn on Allow reset passcode <br /> to member birthday DDMM will <br /> also make Birthday field in Profile <br /> displays and be compulsory. <br /> Please check General > Profile tab</span>
                        </td>
                        <td class="text-left text-xss">
                            <material-checkbox
                                @change="updateFields(value)"
                                v-model="value.is_display"
                                :checked="value.is_display"
                                name="is_display"
                                class="align-items-center px-3"
                                ></material-checkbox>
                        </td>
                        <td class="text-left text-xss">
                            <material-checkbox
                                @change="updateFields(value)"
                                v-model="value.is_required"
                                :checked="value.is_required"
                                name="is_required"
                                class="align-items-center px-3"
                                ></material-checkbox>
                        </td>
                        <td class="text-left text-xss">
                            <material-checkbox
                                @change="updateFields(value)"
                                v-model="value.is_reward"
                                :checked="value.is_reward"
                                name="is_reward"
                                class="align-items-center px-3"
                                ></material-checkbox>
                        </td>
                        <td class="text-left text-xss">
                            {{ value.field_type }}
                        </td>
                        <td class="text-left text-xss">
                            <div style="white-space: break-spaces;">
                                {{ value.data }}
                            </div>
                        </td>
                        <td class="text-center text-xss">
                            <a
                            @click="editField(value)"
                            v-if="value.is_another_field == 1"
                            class="btn btn-link text-dark"
                            >
                            <i
                                class="fas fa-pencil-alt text-dark me-2"
                                aria-hidden="true"
                            ></i
                            >
                            </a>
                        </td>
                        <td class="text-center text-xss">
                            <a
                            @click="deleteField(value.id)"
                            v-if="value.is_another_field == 1"
                            class="btn btn-link text-dark"
                            >
                            <i
                                class="fas fa-trash text-dark me-2"
                                aria-hidden="true"
                            ></i
                            >
                            </a>
                        </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                        <td colspan="5" class="text-center">
                            No item available
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
            <div class="position-fixed top-1 end-1 z-index-2 message-success">
            <material-snackbar
                v-if="snackbar === 'danger' || snackbar === 'success'"
                title=""
                date=""
                :description="message"
                :icon="{ component: 'campaign', color: 'white' }"
                :color="snackbar"
                :close-handler="closeSnackbar"
            />
            </div>
            <material-loading :active="loaderActive" />

            <div>
                <button
                    style="display: none"
                    type="button"
                    id="action-group"
                    data-bs-toggle="modal"
                    data-bs-target="#modalAddNewField"
                >
                </button>
                <div class="modal fade" id="modalAddNewField" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">ADD NEW FIELD</h5>
                        </div>
                        <div class="modal-body">
                            <div class="row d-flex bd-highlight">
                                <div class="col-lg-4 col-4 align-self-center">
                                    Field Name<span class="text-danger ms-2">*</span>
                                </div>
                                <div
                                    class="col-lg-8 col-8 d-flex bd-highlight d-flex justify-content-end"
                                >
                                    <material-input
                                    v-model="field_name_new"
                                    extraClass="ml-5"
                                    placeholder="Enter field name"
                                    />
                                </div>
                                <div class="row d-flex bd-highlight" v-if="fieldNameRequired">
                                    <div class="col-lg-4 col-4 align-self-center"></div>
                                    <div class="col-lg-8 col-8 d-flex bd-highlight">
                                        <span class="is-invalid">The field is required.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex bd-highlight mt-3">
                                <div class="col-lg-4 col-4 align-self-center">
                                    Field Type<span class="text-danger ms-2">*</span>
                                </div>
                                <div
                                    class="col-lg-8 col-8 d-flex bd-highlight d-flex justify-content-end"
                                >
                                    <Multiselect
                                        v-model="field_type_new"
                                        :options="fieldTypeData"
                                        :canClear="false"
                                    >
                                        <template v-slot:singlelabel="{ value }">
                                            <div class="multiselect-single-label">
                                            <!-- <img class="character-label-icon" :src="value.icon"> {{ value.label }} -->
                                            <i :class="value.icon" aria-hidden="true"></i>
                                            <div>
                                                {{ value.label }}
                                            </div>
                                            </div>
                                        </template>

                                        <template v-slot:option="{ option }">
                                            <!-- <img class="character-option-icon" :src="option.icon"> {{ option.label }} -->
                                            <i :class="option.icon" aria-hidden="true" style="width: 5%;"></i> 
                                            <div style="width: 95%;">
                                                {{ option.label }}
                                            </div>
                                        </template>
                                    </Multiselect>
                                </div>
                                <div class="row d-flex bd-highlight" v-if="fieldTypeRequired">
                                    <div class="col-lg-4 col-4 align-self-center"></div>
                                    <div class="col-lg-8 col-8 d-flex bd-highlight">
                                        <span class="is-invalid">The field is required.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex bd-highlight mt-3" v-if="field_type_new == 4 || field_type_new == 5">
                                <div class="col-lg-4 col-4 align-self-center">
                                    Options<span class="text-danger ms-2">*</span>
                                </div>
                                <div
                                    class="col-lg-8 col-8 d-flex bd-highlight d-flex justify-content-end"
                                >
                                    <material-input
                                    v-model="field_options_new"
                                    extraClass="ml-5"
                                    placeholder="Each option is separated by a comma. Ex : Option 1,Option 2"
                                    />
                                </div>
                            </div>
                            <div class="mt-3" style="gap: 10px;display: inline-flex;">
                                <div
                                    :class="{ active: field_display_new }"
                                    class="col-lg-6 col-6 d-flex bd-highlight d-flex"
                                >
                                    Display
                                    <MaterialToggleButton
                                        labelEnableText=""
                                        labelDisableText=""
                                        id="event_handle_field_display_new"
                                        v-on:change="triggerEventDisplayField"
                                    />
                                </div>
                                <div
                                    :class="{ active: field_required_new }"
                                    class="col-lg-6 col-6 d-flex bd-highlight d-flex"
                                >
                                    Required
                                    <MaterialToggleButton
                                        labelEnableText=""
                                        labelDisableText=""
                                        id="event_handle_field_required_new"
                                        v-on:change="triggerEventRequiredField"
                                    />
                                </div>
                            </div>
                        </div>
                        <!-- Modal content-->
                        <div class="modal-footer">
                            <button
                            type="button"
                            class="btn btn-primary btn-close-modal btn-sm"
                            data-bs-dismiss="modal"
                            >
                            Cancel
                            </button>
                            <button
                            type="button"
                            class="btn btn-success btn-background btn-sm"
                            @click="saveNewField"
                            >
                            Save
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalConfirm
                message="Are you sure you want to delete ?"
                :process-yes="processYes"
            />
        </div>
    </div>
</template>
  
<script>
  import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
  import "bootstrap/dist/js/bootstrap.bundle";
  import MaterialLoading from "@/components/MaterialLoading.vue";
  import "bootstrap/js/dist/modal";
  import MaterialButton from "@/components/MaterialButton.vue";
  import "@vuepic/vue-datepicker/dist/main.css";
//   import ModalConfirm from "@/components/ModalConfirm.vue";
  import "@vueform/multiselect/themes/default.css";
  import MaterialInput from "@/components/MaterialInput.vue";
//   import $ from "jquery";
  import TokenService from "../../../services/token.service";
//   import MaterialRadio from "@/components/MaterialRadio.vue";
//   import MemberPreferenceService from "../../../services/memberPreference.service";
  import MemberService from "../../../services/member.service";
  import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
  import Multiselect from "@vueform/multiselect";
  import MaterialToggleButton from "@/components/MaterialToggleButton.vue";
  import $ from "jquery";
  import ModalConfirm from "@/components/ModalConfirm.vue";
  
  export default {
    name: "pos-staff",
    components: {
      MaterialSnackbar,
      MaterialLoading,
      MaterialCheckbox,
      MaterialButton,
    //   ModalConfirm,
      MaterialInput,
      Multiselect,
    //   MaterialRadio,
    MaterialToggleButton,
    ModalConfirm
    },
    data() {
        return {
            message: "",
            snackbar: null,
            loaderActive: false,
            data: [],
            isLoadData: false,
            token: TokenService.getLocalAccessToken(),
            merchantCodeExport: localStorage.getItem("MERCHANT_CODE", ""),
            field_name_new: "",
            field_type_new: null,
            field_options_new: "",
            field_display_new: false,
            field_required_new: false,
            fieldTypeData: [
                {value: 9, label: "Short answer",icon: 'fas fa-stream text-dark me-2'},
                {value: 10, label: "Paragraph",icon: 'fas fa-align-left text-dark me-2'},
                {value: 4, label: "Single Choice",icon: 'fas fa-check-circle text-dark me-2'},
                {value: 5, label: "Multiple Choice",icon: 'fas fa-check-double text-dark me-2'},
                {value: 6, label: "File Upload",icon: 'fas fa-file-upload text-dark me-2'},
                {value: 7, label: "Date",icon: 'fas fa-calendar-day text-dark me-2'},
                {value: 8, label: "Time",icon: 'fas fa-clock text-dark me-2'},
            ],
            fieldId: 0,
            fieldNameRequired: false,
            fieldTypeRequired: false
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        closeSnackbar() {
            this.snackbar = null;
        },
  
        setTimeOutNotification() {
            setTimeout(() => {
            this.snackbar = null;
            }, 3000);
        },
  
        getData() {
            this.isLoadData = false;
            // this.data = [];
            MemberService.getFieldsMemberProfile().then(
            (response) => {
                if (response.data.result.isSuccess) {
                    if(response.data.data && response.data.data.length > 0) {
                        this.data = response.data.data;
                    }
                    this.isLoadData = true;
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
            },
            (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.hideLoader();
                this.setTimeOutNotification();
            }
            );
        },
  
        updateFields(value) {
            this.showLoader();

            var data = {
                minty_field_member_profile_id: value.id,
                is_display: value.is_display == true ? 1 : 0,
                is_required: value.is_required == true ? 1 : 0,
                is_reward: value.is_reward == true ? 1 : 0,
                is_another_field: value.is_another_field
            }

            MemberService.updateFieldsMemberProfile(data).then(
                (response) => {
                if (response.data.result.isSuccess) {
                    this.getData();
                    this.snackbar = "success";
                    this.message = "Update sucessfully!";
                    this.setTimeOutNotification();
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
                this.hideLoader();
                },
                (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.hideLoader();
                this.setTimeOutNotification();
                }
            );
        },
  
        showLoader() {
            this.loaderActive = true;
        },
  
        hideLoader() {
            this.loaderActive = false;
        },
        addNewField() {
            this.resetData();
            const modalAdd = document.getElementById("action-group");
            modalAdd.click();
        },
        saveNewField() {
            var checkError = false;

            if(this.field_name_new == "") {
                this.fieldNameRequired = true;
                checkError = true;
            }

            if(this.field_type_new == null) {
                this.fieldTypeRequired = true;
                checkError = true;
            }
            if(checkError == false) {
                this.showLoader();

                var data = {
                    id: this.fieldId,
                    field_name: this.field_name_new,
                    field_type: this.field_type_new,
                    data: this.field_options_new,
                    is_display: this.field_display_new,
                    is_required: this.field_required_new
                }

                MemberService.addNewFieldsMemberProfile(data).then(
                    (response) => {
                    if (response.data.result.isSuccess) {
                        this.getData();
                        this.snackbar = "success";
                        this.message = "Save sucessfully!";
                        this.setTimeOutNotification();
                        this.resetData();
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                    $(".btn-close-modal").click();
                    this.hideLoader();
                    },
                    (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.hideLoader();
                    this.setTimeOutNotification();
                    }
                );
            }
        },
        triggerEventDisplayField(value) {
            if (typeof value == "boolean") {
                this.field_display_new = !this.field_display_new;
            }
        },
        triggerEventRequiredField(value) {
            if (typeof value == "boolean") {
                this.field_required_new = !this.field_required_new;
            }
        },
        resetData() {
            this.fieldId = 0;
            this.field_name_new = "";
            this.field_type_new = null;
            this.field_options_new = "";
            this.field_display_new = false;
            this.field_required_new =  false;
            this.fieldNameRequired = false;
            this.fieldTypeRequired = false;
        },
        editField(item) {
            this.fieldId = item.id;
            this.field_name_new = item.field_name;
            this.field_type_new = item.field_type_id;
            this.field_options_new = item.data;
            this.field_display_new = item.is_display;
            this.field_required_new = item.is_required;

            const modalAdd = document.getElementById("action-group");
            modalAdd.click();
        },
        deleteField(id) {
            this.fieldId = id;
            $(".btn-confirm-modal").click();
        },
        processYes() {
            this.showLoader();
            MemberService.deleteFieldsMemberProfile(this.fieldId).then(
                (response) => {
                if (response.data.result.isSuccess) {
                    $(".btn-close-modal").click();
                    this.hideLoader();
                    this.getData();
                    this.snackbar = "success";
                    this.message = "Delete sucessfully!";
                    this.setTimeOutNotification();
                    this.resetData();
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
                this.hideLoader();
                },
                (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.hideLoader();
                this.setTimeOutNotification();
                }
            );
        }
    },
  };
  </script>
  <style scoped>
    .text-title {
        color: #82001e !important;
        font-weight: 600 !important;
        font-size: 20px !important;
        font-family: "Roboto Slab", sans-serif !important;
    }
    
    .text-label {
        color: #5b848d !important;
        font-weight: 500 !important;
        font-size: 17px !important;
        font-family: "Roboto Slab", sans-serif !important;
    }
    
    .checkbox-auto-save label {
        padding-top: 11px !important;
    }
    
    .custom-name {
        word-break: break-all;
        max-width: 200px;
        white-space: normal;
    }
    
    .wrap-action .form-check:first-child {
        padding-left: 2px;
    }
    
    .multiselect {
        min-width: 135px;
    }
    
    .r-no-expiry .form-check {
        padding-left: 0 !important;
    }
    
    #preview img {
        width: 120px;
        height: 60px;
        object-fit: cover;
    }
    
    input[type="search"] {
        border: none;
        background: transparent;
        margin: 0;
        padding: 7px 8px;
        font-size: 14px;
        color: inherit;
        border: 1px solid transparent;
        border-radius: inherit;
    }
    
    input[type="search"]::placeholder {
        color: #bbb;
        text-align: left;
        font-weight: normal;
    }
    
    input[type="search"]:focus {
        border-color: #72959d;
        outline: none;
        border: none;
        border-bottom: 2px solid #72959d;
    }
    
    input.nosubmit {
        border: none;
        border-bottom: 1px solid #d2d2d2;
        width: 100%;
        padding: 9px 4px 9px 40px;
        background: transparent
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
        no-repeat 13px center;
    }
    
    @media (min-width: 576px) {
        .modal-dialog {
        max-width: 50%;
        margin: 1.75rem auto;
        }
    }
  </style>
  