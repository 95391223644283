import api from './api';

class SettingCashbackService {
  getDataCashback() {
    return api.get('/merchant/cashback-setting');
  }

  addOrUpdateCashback(dataForm) {
    return api.post('/merchant/cashback-setting', dataForm);
  }

  deleteCashback(cashbackId) {
    return api.post('/merchant/cashback-setting/delete/' + cashbackId);
  }
}

export default new SettingCashbackService();
