import api from "./api";

class PaymentMethodService {
  getList(dataForm) {
    return api.get("/merchant/payment-method", { params: dataForm });
  }

  detail(methodId) {
    return api.get("/merchant/payment-method/" + methodId);
  }

  store(dataForm) {
    return api.post("/merchant/payment-method", dataForm);
  }

  update(dataForm) {
    return api.put("/merchant/payment-method/" + dataForm.id, dataForm);
  }
}

export default new PaymentMethodService();
