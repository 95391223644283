<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">POS STAFF</h3>
        </div>
      </div>
      <div class="col-lg-4 col-md-5 col-5 d-flex justify-content-end">
        <material-button
          @click="addStaff()"
          :disabled="disableBtnSubmit"
          class="mx-2 btn-background btn-sm"
        >
          Add new staff
        </material-button>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row my-3 d-flex align-items-left">
          <div class="col-lg-6 col-md-6 col-12">
            <input
              class="nosubmit"
              type="search"
              id="keyword_search"
              v-model="searchKeyword"
              extraClass
              name="keyword_search"
              placeholder="Search by staff id, name, phone"
              @input="onChangesearchKeyword"
            />
          </div>
        </div>
        <div class="row">
          <div class="notifications">
            <material-alert
              v-if="error"
              color="danger"
              icon="ni ni-like-2 ni-lg"
              dismissible
            >
              {{ error }}
            </material-alert>
          </div>
          <div class="col-lg-12 col-md-12 col-12 mx-auto" v-if="isLoadData">
            <div class="row mt-4">
              <div class="col-12">
                <div class="card-custom">
                  <div class="px-0 pb-2">
                    <div class="table-responsive p-0 custom-table">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th class="text-center font-weight-bolder w-5">
                              ID
                            </th>
                            <th class="text-center font-weight-bolder w-20">
                              Name
                            </th>
                            <th class="text-center font-weight-bolder w-20">
                              Phone Number
                            </th>
                            <th class="text-center font-weight-bolder w-20">
                              Status
                            </th>
                            <th class="text-center font-weight-bolder w-15">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="staffs.length">
                          <tr v-for="(value, key) in staffs" :key="key">
                            <td class="text-center text-xss">
                              {{ value.id }}
                            </td>
                            <td class="text-left text-xss">
                              {{ value.name }}
                            </td>
                            <td class="text-center text-xss">
                              {{ value.phone_number }}
                            </td>
                            <td class="align-middle text-center w-10">
                              <span
                                class="badge badge-sm"
                                v-bind:class="
                                  value.status == 1
                                    ? 'bg-gradient-success'
                                    : 'bg-danger'
                                "
                              >
                                {{
                                  value.status == 1 ? "Active" : "Inactive"
                                }}</span
                              >
                            </td>
                            <td class="align-middle text-center text-sm">
                              <a
                                class="btn btn-link text-dark px-3 mb-0"
                                @click="editStaff(value.id)"
                              >
                                <i
                                  class="fas fa-pencil-alt text-dark me-2"
                                  aria-hidden="true"
                                ></i
                                >Edit
                              </a>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="5" class="text-center">
                              No item available
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <pagination
                    v-if="pagination.last_page > 1"
                    :pagination="pagination"
                    :current_page="pagination.current_page"
                    :last_page="pagination.last_page"
                  />
                  <div class="text-center mt-2 d-flex justify-content-end">
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle btn-sm"
                        type="button"
                        id="dropdownMenuButton1"
                        style="margin-top: 13px"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        aria-haspopup="true"
                      >
                        Download
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a
                            class="dropdown-item"
                            @click="exportFile('csv')"
                            href="#"
                            >Export CSV</a
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            @click="exportFile('xlsx')"
                            href="#"
                            >Export Excel</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirm
        message="Are you sure you want to delete ?"
        :process-yes="processYes"
      />
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
      <form
        :action="`${actionDownload}/merchant/download-staffs`"
        method="POST"
      >
        <input type="hidden" name="merchantId" :value="merchantIdExport" />
        <input type="hidden" name="merchant_code" :value="merchantCodeExport" />
        <input type="hidden" name="search_keyword" :value="searchKeyword" />
        <input type="hidden" name="token" :value="token" />
        <input type="hidden" name="action_export" :value="action_export" />
        <input type="hidden" name="action" value="export" />
        <input id="downloadForm" type="submit" class="d-none" />
      </form>

      <div>
        <button
          style="display: none"
          type="button"
          id="action-group"
          data-bs-toggle="modal"
          data-bs-target="#modalEdit"
        ></button>
        <div class="modal fade" id="modalEdit" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{ labelGroup }}</h5>
              </div>
              <div class="modal-body">
                <div class="row d-flex bd-highlight">
                  <div class="col-lg-4 col-4 align-self-center">
                    Name<span class="text-danger ms-2">*</span>
                  </div>
                  <div
                    class="col-lg-8 col-8 d-flex bd-highlight d-flex justify-content-end"
                  >
                    <material-input
                      v-model="dataStaff.name"
                      extraClass="ml-5"
                      placeholder="Enter staff name"
                    />
                  </div>
                </div>
                <div
                  class="row d-flex bd-highlight"
                  v-if="fieldRequired.isStaffNameRequire"
                >
                  <div class="col-lg-4 col-4 align-self-center"></div>
                  <div class="col-lg-8 col-8 d-flex bd-highlight">
                    <span class="is-invalid">{{
                      textRequireStaffNameCustom
                    }}</span>
                  </div>
                </div>
                <div class="row d-flex bd-highlight pt-2">
                  <div class="col-lg-4 col-4 align-self-center">
                    Phone Number<span class="text-danger ms-2">*</span>
                  </div>
                  <div
                    class="col-lg-8 col-8 d-flex bd-highlight d-flex justify-content-end"
                  >
                    <material-input
                      v-model="dataStaff.phone_number"
                      extraClass="ml-5"
                      placeholder="Enter staff phone no"
                    />
                  </div>
                </div>
                <div
                  class="row d-flex bd-highlight"
                  v-if="fieldRequired.isStaffPhoneNoRequire"
                >
                  <div class="col-lg-4 col-4 align-self-center"></div>
                  <div class="col-lg-8 col-8 d-flex bd-highlight">
                    <span class="is-invalid">{{
                      textRequireStaffPhoneNoCustom
                    }}</span>
                  </div>
                </div>
                <div class="row d-flex bd-highlight pt-2">
                  <div class="col-lg-4 col-4 align-self-center">Status</div>
                  <div class="col-lg-8 col-8 d-flex bd-highlight d-flex">
                    <MaterialRadio
                      id="status_1"
                      value="1"
                      class="ps-0"
                      v-model="dataStaff.status"
                    >
                      Active
                    </MaterialRadio>
                    <MaterialRadio
                      id="status_2"
                      value="0"
                      v-model="dataStaff.status"
                    >
                      Inactive
                    </MaterialRadio>
                  </div>
                </div>
              </div>
              <!-- Modal content-->
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary btn-close-modal btn-sm"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-success btn-background btn-sm"
                  @click="saveStaff"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Pagination from "@/components/Pagination.vue";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import ModalConfirm from "@/components/ModalConfirm.vue";
import "@vueform/multiselect/themes/default.css";
import MaterialInput from "@/components/MaterialInput.vue";
import $ from "jquery";
import TokenService from "../../../services/token.service";
import MaterialRadio from "@/components/MaterialRadio.vue";
import PosStaffService from "../../../services/posStaff.service";

export default {
  name: "pos-staff",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    ModalConfirm,
    Pagination,
    MaterialInput,
    MaterialRadio,
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      staffs: [],
      searchKeyword: "",
      role: [
        {
          value: "Cashier",
          label: "Cashier",
        },
        {
          value: "Manager",
          label: "Manager",
        },
      ],
      pagination: {},
      page: 1,
      methodId: null,
      isLoadData: false,
      labelGroup: "ADD NEW STAFF",
      urlImagePreview: null,
      image: null,
      messageImageError: "",
      isErrorUploadImage: false,
      isImageTooSmall: false,
      dataStaff: {
        id: 0,
        name: "",
        phone_number: "",
        status: 0
      },
      fieldRequired: {},
      textRequireStaffNameCustom: "",
      textRequireStaffEmailCustom: "",
      textRequireStaffPhoneNoCustom: "",
      action_export: "csv",
      actionDownload: process.env.VUE_APP_BASE_URL,
      token: TokenService.getLocalAccessToken(),
      merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
      merchantCodeExport: localStorage.getItem("MERCHANT_CODE", ""),
    };
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    this.getList(this.page);
  },
  methods: {
    exportFile(type = "csv") {
      this.action_export = type;
      setTimeout(() => {
        $("#downloadForm").click();
      }, 150);
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    getList(page) {
      this.isLoadData = false;
      this.staffs = [];
      var dataForm = {
        search_keyword: this.searchKeyword,
        page: page,
      };
      PosStaffService.getList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.staffs = response.data.data.items;
            this.pagination = response.data.data;
            this.page = this.pagination.current_page;
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    saveStaff() {
      if (this.processValidate()) {
        if (!this.dataStaff.id) {
          this.showLoader();
          PosStaffService.store(this.dataStaff).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Save Staff Success";
                this.setTimeOutNotification();
                $(".btn-close-modal").click();
                this.page = 1;
                this.searchKeyword = '';
                this.getList(this.page);
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        } else {
          PosStaffService.update(this.dataStaff).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Update Staff Success";
                this.setTimeOutNotification();
                $(".btn-close-modal").click();
                this.searchKeyword = '';
                this.getList(this.page);
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        }
      }
    },

    resetRequired() {
      this.fieldRequired.isStaffNameRequire = false;
      this.fieldRequired.isStaffPhoneNoRequire = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.dataStaff.name == "" || this.dataStaff.name == null) {
        this.fieldRequired.isStaffNameRequire = true;
        this.textRequireStaffNameCustom = "The field is required.";
        isValid = false;
      }

      if (
        this.dataStaff.phone_number == "" ||
        this.dataStaff.phone_number == null
      ) {
        this.fieldRequired.isStaffPhoneNoRequire = true;
        this.textRequireStaffPhoneNoCustom = "The field is required.";
        isValid = false;
      }

      return isValid;
    },

    isPositiveInteger(str) {
      return parseFloat(str) > 0;
    },

    addStaff() {
      this.labelGroup = "ADD NEW STAFF";
      this.dataStaff.id = 0;
      this.dataStaff.name = "";
      this.dataStaff.phone_number = "";
      this.dataStaff.status = 0;
      const modalAdd = document.getElementById("action-group");
      modalAdd.click();
      this.resetRequired();
    },

    async editStaff(methodId) {
      this.resetRequired();
      this.isLoadDoneData = false;
      this.showLoader();
      await PosStaffService.detail(methodId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            const staff = response.data.data;
            this.isLoadDoneData = true;
            this.dataStaff.id = staff.id ?? 0;
            this.dataStaff.name = staff.name ?? "";
            this.dataStaff.phone_number = staff.phone_number ?? "";
            this.dataStaff.status = staff.status ?? 0;
            this.labelGroup = `EDIT STAFF`;
            const editStaff = document.getElementById("action-group");
            editStaff.click();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    onChangesearchKeyword(event) {
      this.searchKeyword = event.target.value;
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getList(this.page);
      }, 1000);
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
<style scoped>
.text-title {
  color: #82001e !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.text-label {
  color: #5b848d !important;
  font-weight: 500 !important;
  font-size: 17px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.checkbox-auto-save label {
  padding-top: 11px !important;
}

.custom-name {
  word-break: break-all;
  max-width: 200px;
  white-space: normal;
}

.wrap-action .form-check:first-child {
  padding-left: 2px;
}

.multiselect {
  min-width: 135px;
}

.r-no-expiry .form-check {
  padding-left: 0 !important;
}

#preview img {
  width: 120px;
  height: 60px;
  object-fit: cover;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
}

input[type="search"]:focus {
  border-color: #72959d;
  outline: none;
  border: none;
  border-bottom: 2px solid #72959d;
}

input.nosubmit {
  border: none;
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 50%;
    margin: 1.75rem auto;
  }
}
</style>
