<template>
  <label :for="id + '_button'" :class="{ active: isActive }" class="toggle__button">
    <span v-if="isActive" class="toggle__label">{{ enableText }}</span>
    <span v-if="!isActive" class="toggle__label">{{ disabledText }}</span>

    <input type="checkbox" :disabled="disabled" :id="id + '_button'" v-model="checkedValue" />
    <span class="toggle__switch"></span>
  </label>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    labelEnableText: {
      type: String,
      default: "Đang bật",
    },

    labelDisableText: {
      type: String,
      default: "Đang tắt",
    },

    id: {
      type: String,
      default: "primary",
    },

    defaultState: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentState: this.defaultState,
    };
  },

  watch: {
    defaultState: function defaultState() {
      this.currentState = Boolean(this.defaultState);
    },
  },

  computed: {
    // currentState() {
    //     return this.defaultState;
    // },

    // isActive() {
    //   return this.currentState;
    // },

    enableText() {
      return this.labelEnableText;
    },

    disabledText() {
      return this.labelDisableText;
    },

    checkedValue: {
      get() {
        return this.currentState;
      },

      set(newValue) {
        this.currentState = newValue;
        this.$emit("change", newValue);
      },
    },
  },
};
</script>

<style scoped>
.toggle__button {
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  position: relative;
}

.toggle__label {
  position: absolute;
  left: 55px;
  top: 3px;
}

.toggle__button input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
}

.toggle__button .toggle__switch {
  display: inline-block;
  height: 1.59rem;
  border-radius: 3.25rem;
  width: 3.1rem;
  background: #ffffff;
  border: 1px solid #b5b6b8;
  position: relative;
  transition: all 0.25s;
}

.toggle__button .toggle__switch::after,
.toggle__button .toggle__switch::before {
  content: "";
  position: absolute;
  display: block;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  left: 4px;
  top: 3px;
  transform: translateX(0);
  transition: all 0.25s cubic-bezier(0.5, -0.6, 0.5, 1.6);
}

.toggle__button .toggle__switch::after {
  background: #b5b6b8;
  box-shadow: 0 0 1px #666;
}

.toggle__button .toggle__switch::before {
  background: #b5b6b8;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
  opacity: 0;
}

.active .toggle__switch {
  background: #82001e ;
  box-shadow: inset 0 0 1px #82001e ;
}

.active .toggle__switch::after,
.active .toggle__switch::before {
  transform: translateX(40px - 18px);
}

.active .toggle__switch::after {
  left: 26px;
  background: #ffffff;
  box-shadow: 0 0 1px #ffffff;
}
</style>
