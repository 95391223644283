<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#modalAddGroupAndType"
      id="show-modal-add-group-and-type"
    ></material-button>
    <div
      class="modal fade"
      id="modalAddGroupAndType"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{
                (idSelected && !isAddGroup ? "Edit" : "Add") +
                " " +
                (isType ? "Type" : "Group")
              }}
            </h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal justify-content-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              >clear</i
            >
          </div>
          <div class="modal-body">
            <div
              class="w-100"
              :class="{ 'mb-5': !fieldRequired.isNameRequire }"
            >
              <label class="fw-bold" for=""
                >Name
                <span class="text-danger">*</span>
              </label>
              <material-input
                v-model="item.name"
                type="text"
                id="name"
                name="name"
                placeholder="Enter name"
              />
            </div>
            <div class="w-100 mb-5">
              <span class="is-invalid" v-if="fieldRequired.isNameRequire"
                >The field is required.</span
              >
            </div>
            <div class="d-flex justify-content-end">
              <material-button
                class="mx-2 btn btn-sm btn-background"
                @click="saveGroupType()"
              >
                Save
              </material-button>
              <material-button
                class="mx-2 btn btn-sm btn-background"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <material-loading :active="loaderActive" />
  </div>
</template>
<script>
import $ from "jquery";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import ReportGroupService from "../services/product/reportGroup.service";

export default {
  name: "create-category",
  components: {
    MaterialInput,
    MaterialButton,
    MaterialLoading,
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    idSelected: {
      type: Number,
      default: 0,
    },
    isType: {
      type: Boolean,
      default: true,
    },
    isAddGroup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      message: "",
      snackbar: null,
      fieldRequired: {},
      loaderActive: false,
      item: {
        id: 0,
        type_id: 0,
        name: "",
      },
    };
  },
  methods: {
    getGroupTypeDetail() {
      if (this.idSelected) {
        this.showLoader();
        ReportGroupService.detail(this.idSelected).then(
          (response) => {
            if (response.data.result.isSuccess) {
              let item = response.data.data;
              this.item = item;
              this.item.name = item.group_name;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    saveGroupType() {
      if (this.processValidate()) {
        this.showLoader();
        if (this.item.id) {
          // Edit
          ReportGroupService.update(this.item).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.item = {
                  id: 0,
                  type_id: 0,
                  name: "",
                };
                this.$emit("reload-data", "Update " + (this.isType ? "Type" : "Group") + " Success");
                $(".btn-close-modal").click();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        } else {
          // Add
          if (this.isAddGroup) {
            this.item.type_id = this.idSelected;
          }

          ReportGroupService.store(this.item).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.item = {
                  id: 0,
                  type_id: 0,
                  name: "",
                };
                this.$emit("reload-data", "Add " + (this.isType ? "Type" : "Group") + " Success");
                $(".btn-close-modal").click();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        }
      }
    },

    resetRequired() {
      this.fieldRequired.isNameRequire = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.item.name == "" || this.item.name == null) {
        this.fieldRequired.isNameRequire = true;
        isValid = false;
      }

      return isValid;
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    keyReload() {
      this.resetRequired();
      this.item = {
        id: 0,
        type_id: 0,
        name: "",
      };
      if (this.idSelected && !this.isAddGroup) {
        this.getGroupTypeDetail();
      }
    }
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 85vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.modal-body .row {
  margin-top: 10px;
}
.content {
  cursor: pointer;
  text-align: center;
  width: 250px;
  height: 250px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
}
.font-18 {
  font-size: 18px;
}
</style>
