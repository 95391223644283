<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">EMAIL TEMPLATE LIST</h3>
        </div>
      </div>
      <div class="col-lg-4 col-md-5 col-5 d-flex justify-content-end">
        <material-button
          class="mx-2 btn btn-sm btn-background"
          @click="createEmail()"
        >
          Create Email
        </material-button>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="notifications">
            <material-alert
              v-if="error"
              color="danger"
              icon="ni ni-like-2 ni-lg"
              dismissible
            >
              {{ error }}
            </material-alert>
          </div>
          <div class="px-4">
            <div
              class="row mt-3"
              style="align-items: center; border-bottom: 1px solid #d2d2d2"
            >
              <div class="col-12">
                <input
                  class="nosubmit"
                  type="search"
                  id="keyword_search"
                  extraClass
                  name="keyword_search"
                  placeholder="Search email ..."
                  v-model="searchKeyword"
                  @input="onChangesearchKeyword"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-12 mx-auto" v-if="isLoadData">
            <div class="row mt-4">
              <div class="col-12">
                <div class="card-custom">
                  <div class="px-0 pb-2">
                    <div class="table-responsive p-0 custom-table">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th class="text-center font-weight-bolder">
                              Status
                            </th>
                            <th class="text-center font-weight-bolder">
                              Auto-Send
                            </th>
                            <th class="text-center font-weight-bolder">
                              Subject
                            </th>
                            <th class="text-center font-weight-bolder">
                              Recipient
                            </th>
                            <th class="text-center font-weight-bolder">
                              Creator
                            </th>
                            <th class="text-center font-weight-bolder">
                              Enable
                            </th>
                            <th class="text-center font-weight-bolder">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="emails.length">
                          <tr v-for="(email, key) in emails" :key="key">
                            <td class="text-center text-xss">
                              {{ email.status_text }}
                            </td>
                            <td class="text-center text-xss">
                              {{ email.auto_send }}
                            </td>
                            <td class="text-center text-xss">
                              {{ email.subject }}
                            </td>
                            <td class="text-center text-xss">
                              {{ email.total_recipient + ' recipients' }}
                            </td>
                            <td class="text-center text-xss">
                              {{ email.creator }}
                            </td>
                            <td class="text-center text-xss">
                              <i
                                class="fa fa-lock"
                                v-if="email.is_disabled"
                                style="color: #ff3300"
                              ></i>
                              <i
                                class="fa fa-check-square-o"
                                style="color: #33cc33"
                                v-else
                              ></i>
                            </td>
                            <td class="text-center text-xss w-25">
                              <i
                                class="fa fa-ellipsis-v text-secondary cursor-pointer"
                                aria-hidden="true"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                              ></i>
                              <ul
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <li @click="detailEmail(email.id)">
                                  <a
                                    class="btn btn-link text-dark mb-0 unset-ov"
                                    
                                  >
                                    <i class="material-icons">search</i
                                    ><span class="ms-1">View</span>
                                  </a>
                                </li>
                                <li @click="resendEmail(email.id)">
                                  <a
                                    class="btn btn-link text-dark mb-0 unset-ov"
                                  >
                                    <i class="material-icons">replay</i>
                                    <span class="ms-1">Resend</span>
                                  </a>
                                </li>
                                <li @click="makeACopy(email.id)">
                                  <a
                                    class="btn btn-link text-dark mb-0 unset-ov"
                                    
                                  >
                                    <i class="material-icons">content_copy</i>
                                    <span class="ms-1">Make a Copy</span>
                                  </a>
                                </li>
                                <li @click="openModalDeleteEmail(email.id)">
                                  <a
                                    class="btn btn-link text-dark mb-0 unset-ov"
                                  >
                                    <i class="material-icons">delete_outline</i>
                                    <span class="ms-1">Delete</span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    class="btn btn-link text-dark mb-0 unset-ov"
                                    @click="enableDisableEmail(email.id, email.is_disabled)"
                                  >
                                    <i class="material-icons" v-if="email.is_disabled">check_circle_outline</i>
                                    <i class="material-icons" v-else>not_interested</i>
                                    <span class="ms-1">{{ email.is_disabled ? 'Enable' :  'Disable'}}</span>
                                  </a>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="7" class="text-center">
                              No item available
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <pagination
                      v-if="pagination.last_page > 1"
                      :pagination="pagination"
                      :current_page="pagination.current_page"
                      :last_page="pagination.last_page"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2 message-success">
    <material-snackbar
      v-if="snackbar === 'danger' || snackbar === 'success'"
      title=""
      date=""
      :description="message"
      :icon="{ component: 'campaign', color: 'white' }"
      :color="snackbar"
      :close-handler="closeSnackbar"
    />
  </div>
  <material-loading :active="loaderActive" />
  <ModalConfirmDelete
    message="Are you sure you want to delete ?"
    :process-yes="deleteEmail"
  />
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import EmailSetupService from "../../../services/emailSetup.service";
import Pagination from "@/components/Pagination.vue";
import $ from "jquery";
import ModalConfirmDelete from "@/components/ModalConfirmDelete.vue";

export default {
  name: "find-store",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    Pagination,
    ModalConfirmDelete
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      searchTimerId: null,
      emails: [],
      searchKeyword: "",
      pagination: {},
      page: 1,
      isLoadData: false,
      idSelected: 0,
      emailIdDelete: 0,
    };
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    this.getList(this.page);
  },
  mounted() {
  },
  methods: {
    detailEmail(id) {
      this.$router.push('/email-setup/detail/' + id);
    },

    onChangesearchKeyword(event) {
      this.searchKeyword = event.target.value;
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getList(this.page);
      }, 1000);
    },

    getList(page) {
      this.isLoadData = false;
      this.emails = [];
      this.showLoader();
      var dataForm = {
        search_keyword: this.searchKeyword,
        page: page,
      };
      EmailSetupService.getList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.emails = response.data.data.items;
            this.pagination = response.data.data;
            this.page = this.pagination.current_page;
            this.total = this.pagination.total;
            this.$router.push({
              query: Object.assign({}, this.$route.query, { page: this.page }),
            });
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    makeACopy(id) {
      this.showLoader();
      EmailSetupService.makeACopy(id).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = 'success';
              this.message = "Make a copy Success";
              this.setTimeOutNotification();
              this.getList(this.page);
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
    },

    openModalDeleteEmail(id) {
      this.emailIdDelete = id;
      $(".btn-confirm-modal-delete").click();
    },

    deleteEmail() {
      this.showLoader();
      EmailSetupService.deleteEmail(this.emailIdDelete).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = 'success';
              this.message = "Deleted Email Success";
              this.setTimeOutNotification();
              this.getList(this.page);
              $(".btn-close-modal").click();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
    },

    resendEmail(id) {
      this.showLoader();
      EmailSetupService.resend(id).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = 'success';
              this.message = "Resend Email Success";
              this.setTimeOutNotification();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
    },

    enableDisableEmail(id, status) {
      this.showLoader();
      var dataForm = {
        id: id
      };

      EmailSetupService.enableDisableEmail(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = 'success';
              this.message = (status ? 'Enable' : 'Disable') + " Email Success";
              this.getList(this.page);
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    createEmail() {
      this.$router.push('/email-setup/create');
    },

    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
<style scoped>
.nosubmit-parent {
  border: none;
  padding: 0;
}

input.nosubmit {
  border-bottom: 0px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

input[type="search"]:focus {
  border-color: #72959d;
  outline: none;
  border: none;
  border-bottom: 1px solid #72959d;
}
</style>
