import api from "./api";
import TokenService from "./token.service";

class AuthService {
  logout() {
    TokenService.removeUser();
  }

  login({ email, password }) {
    return api
      .post("merchant/login", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.result.isSuccess) {
          TokenService.setMerAdmin(response.data);
        }
        return response.data;
      });
  }
}

export default new AuthService();
