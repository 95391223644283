<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#modalAddPeriod"
      id="show-modal-add-period"
    ></material-button>
    <div
      class="modal fade"
      id="modalAddPeriod"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Period</h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal justify-content-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              >clear</i
            >
          </div>
          <div class="modal-body">
            <div class="w-100">
              <label class="fw-bold" for=""
                >Start Time:
                <span class="text-danger">*</span>
              </label>
              <Datepicker v-model="start_time" timePicker class="date-time" />
            </div>
            <div class="w-100 mb-3">
              <span class="is-invalid" v-if="fieldRequired.isStartTimeRequire"
                >The field is required.</span
              >
            </div>
            <div class="w-100">
              <label class="fw-bold" for=""
                >End Time:
                <span class="text-danger">*</span>
              </label>
              <Datepicker v-model="end_time" timePicker class="date-time" />
            </div>
            <div class="w-100 mb-3">
              <span class="is-invalid" v-if="fieldRequired.isEndTimeRequire"
                >The field is required.</span
              >
            </div>
            <div class="d-flex justify-content-end">
              <material-button
                class="mx-2 btn btn-sm btn-background"
                @click="savePeriod()"
              >
                Add
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <material-loading :active="loaderActive" />
  </div>
</template>
<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import $ from "jquery";
import moment from "moment";

export default {
  name: "create-category",
  components: {
    MaterialButton,
    MaterialLoading,
    Datepicker,
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    day: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fieldRequired: {},
      loaderActive: false,
      start_time: null,
      end_time: null,
      textRequiredDateCustom: "",
    };
  },
  created() {},
  methods: {
    resetRequired() {
      this.fieldRequired.isStartTimeRequire = false;
      this.fieldRequired.isEndTimeRequire = false;
      this.fieldRequired.isDateRequire = false;
      this.textRequiredDateCustom = "";
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;

      if (this.start_time == "" || this.start_time == null) {
        this.fieldRequired.isStartTimeRequire = true;
        isValid = false;
      }

      if (this.end_time == "" || this.end_time == null) {
        this.fieldRequired.isEndTimeRequire = true;
        isValid = false;
      }

      return isValid;
    },

    savePeriod() {
      if (this.processValidate()) {
        $(".btn-close-modal").click();
        this.$emit(
          "handle-add-period",
          this.day,
          moment(this.start_time).format("H:mm"),
          moment(this.end_time).format("H:mm")
        );
      }
    },

    clearData() {
      this.start_time = null;
      this.end_time = null;
      this.fieldRequired = {};
      this.textRequiredDateCustom = "";
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    async keyReload() {
      this.clearData();
    },
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 85vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.modal-body .row {
  margin-top: 10px;
}
.content {
  cursor: pointer;
  text-align: center;
  width: 250px;
  height: 250px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
}
</style>
