<template>
  <div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <!-- <span class="mask bg-white"></span> -->
      <div class="container">
        <div class="row">
          <div class="notifications">
            <material-alert
              v-if="error"
              color="danger"
              icon="ni ni-like-2 ni-lg"
              dismissible
            >
              {{ error }}
            </material-alert>
          </div>
          <div class="col-lg-12 col-md-12 col-12 mx-auto" v-if="isLoadDoneData">
            <div class="card-body mb-5" style="padding: 0rem 0rem">
              <div class="color-common pb-2 font-weight-bolder px-2 pt-2 row">
                <h4 class="ps-0 col-lg-6 col-md-6 col-6 standard-rule">
                  {{ $t('STANDARD_RULE') }}
                </h4>
                <div class="col-6 d-flex justify-content-end">
                  <material-button
                    @click="addRow(1)"
                    :disabled="formStandard.length >= 3 ? true : false"
                    class="btn-background btn-sm"
                  >
                    Add Standard Rule
                  </material-button>
                </div>
              </div>
              <CashbackRule
                :save-cashback="saveCashback"
                :remove-cashback="confirmDelete"
                :field-required-standard="fieldRequiredStandard"
                :form="formStandard"
                type-cashback="1"
                :text-required-standard-date-custom="
                  textRequiredStandardDateCustom
                "
                :pendingTimeUseCashback="pendingTimeUseCashback"
                :expireOfCashbackAmount="expireOfCashbackAmount"
              />
              <div v-if="formStandard.length == 0" class="text-center p-5">
                No Cashback Standard Rules Yet!
              </div>
              <div class="pt-3">
                <div class="color-common pb-2 font-weight-bolder px-2 pt-2 row">
                  <h4 class="ps-0 col-lg-6 col-md-6 col-6 standard-rule">
                    {{ $t('SUPERIOR_RULE') }}
                  </h4>
                  <div class="col-6 d-flex justify-content-end">
                    <material-button
                      @click="addRow(2)"
                      :disabled="formSuperior.length >= 2 ? true : false"
                      class="btn-background-superior btn-background btn-sm"
                    >
                      Add Superior Rule
                    </material-button>
                  </div>
                </div>
                <CashbackRule
                  :save-cashback="saveCashback"
                  :remove-cashback="confirmDelete"
                  type-cashback="2"
                  :text-required-superior-date-custom="
                    textRequiredSuperiorDateCustom
                  "
                  :field-required-superior="fieldRequiredSuperior"
                  :form="formSuperior"
                  :pendingTimeUseCashback="pendingTimeUseCashback"
                  :expireOfCashbackAmount="expireOfCashbackAmount"
                />
                <div v-if="formSuperior.length == 0" class="text-center p-5">
                  No Cashback Superior Rules Yet!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirm
        message="Are you sure you want to delete ?"
        :process-yes="processYes"
      />
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import CashbackRule from "@/components/CashbackRule.vue";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import SettingCashbackService from "../../services/settingCashback.service";
import ModalConfirm from "@/components/ModalConfirm.vue";
import { reactive } from "vue";
import moment from "moment";
import $ from "jquery";
export default {
  name: "find-store",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    CashbackRule,
    ModalConfirm,
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      standardCasbacks: [],
      superiorCasbacks: [],
      formStandard: [],
      formSuperior: [],
      fieldRequiredStandard: [],
      fieldRequiredSuperior: [],
      pendingTimeUseCashback: [],
      expireOfCashbackAmount: [],
      indexItem: 0,
      typeCashbackDelete: null,
      objDataSave: {},
      textRequiredStandardDateCustom: "",
      textRequiredSuperiorDateCustom: "",
      isLoadDoneData: false,
    };
  },
  created() {
    this.getCashbackList();
  },
  methods: {
    dateTimeFormat(startDate, startTime) {
      if (startDate == null) {
        return null;
      } else {
        if (startTime == null) {
          return moment(startDate).format("YYYY-MM-DD") + " " + "00:00:00";
        } else {
          return (
            moment(startDate).format("YYYY-MM-DD") +
            " " +
            moment(startTime).format("H:m")
          );
        }
      }
    },
    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    addRow(type) {
      if (type == 1) {
        this.formStandard.push({
          id: null,
          rule_name: "",
          spend_every: 0,
          rebate: 0,
          cashback_exchange: 1,
          dolar_exchange: 1,
          form_of_cashback: 1,
          start_date: null,
          end_date: null,
          no_end_date: 0,
          start_time: null,
          end_time: null,
          pending_time_use_cashback: 1,
          expire_of_cashback_amount: 1,
          type_cashback: 1,
        });

        this.fieldRequiredStandard.push({
          isRuleNameRequire: false,
          isSpendEveryRequire: false,
          isRebateRequire: false,
          isDateRequire: false,
        });
      } else {
        this.formSuperior.push({
          id: null,
          rule_name: "",
          spend_every: 0,
          rebate: 0,
          cashback_exchange: 1,
          dolar_exchange: 1,
          form_of_cashback: 1,
          start_date: null,
          end_date: null,
          no_end_date: 0,
          start_time: null,
          end_time: null,
          pending_time_use_cashback: 1,
          expire_of_cashback_amount: 1,
          type_cashback: 2,
        });

        this.fieldRequiredSuperior.push({
          isRuleNameRequire: false,
          isSpendEveryRequire: false,
          isRebateRequire: false,
          isDateRequire: false,
        });
      }
    },

    getCashbackList() {
      this.standardCasbacks = [];
      this.showLoader();
      this.isLoadDoneData = false;
      SettingCashbackService.getDataCashback().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.standardCasbacks = response.data.data.stardard_cashbacks;
            this.superiorCasbacks = response.data.data.superior_cashbacks;
            this.pendingTimeUseCashback = response.data.data.pendingTimeUseCashback
            this.expireOfCashbackAmount = response.data.data.expireOfCashbackAmount
            console.log(
              "this.standardCasbacks",
              response.data.data.stardard_cashbacks
            );
            this.formStandard = reactive(this.standardCasbacks);
            this.formSuperior = reactive(this.superiorCasbacks);

            this.formStandard.forEach(() => {
              this.fieldRequiredStandard.push({
                isRuleNameRequire: false,
                isSpendEveryRequire: false,
                isRebateRequire: false,
                isDateRequire: false,
              });
            });
            this.formSuperior.forEach(() => {
              this.fieldRequiredSuperior.push({
                isRuleNameRequire: false,
                isSpendEveryRequire: false,
                isRebateRequire: false,
                isDateRequire: false,
              });
            });
            this.isLoadDoneData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    saveCashback(type, index) {
      this.showLoader();
      if (this.processValidate(type, index)) {
        this.objDataSave = {};
        if (type == 1) {
          this.objDataSave = this.formStandard[index];
        } else {
          this.objDataSave = this.formSuperior[index];
        }
        this.objDataSave.start_date = this.dateTimeFormat(
          this.objDataSave.start_date,
          this.objDataSave.start_time
        );
        this.objDataSave.end_date = this.dateTimeFormat(
          this.objDataSave.end_date,
          this.objDataSave.end_time
        );
        SettingCashbackService.addOrUpdateCashback(this.objDataSave).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message = "Save Rule Success";
              if (type == 1) {
                this.formStandard[index].id = response.data.data.id;
              } else {
                this.formSuperior[index].id = response.data.data.id;
              }
              this.setTimeOutNotification();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      } else {
        this.hideLoader();
      }
    },
    processYes() {
      $(".btn-close-modal").click();
      const type = this.typeCashbackDelete;
      const index = this.indexItem;
      if (type == 1) {
        this.fieldRequiredStandard.forEach((item, index) => {
          this.resetRequired(type, index);
        });
      } else {
        this.fieldRequiredSuperior.forEach((item, index) => {
          this.resetRequired(type, index);
        });
      }

      this.showLoader();
      if (type == 1) {
        if (this.formStandard[index].id == null) {
          this.removeRow(type, index);
          this.hideLoader();
        } else {
          if (this.formStandard[index].id) {
            SettingCashbackService.deleteCashback(
              this.formStandard[index].id
            ).then(
              (response) => {
                if (response.data.result.isSuccess) {
                  this.removeRow(type, index);
                  this.snackbar = "success";
                  this.message = "Delete Rule Success";
                  this.setTimeOutNotification();
                } else {
                  this.snackbar = "danger";
                  this.message = response.data.result.message;
                  this.setTimeOutNotification();
                }
                this.hideLoader();
              },
              (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.hideLoader();
                this.setTimeOutNotification();
              }
            );
          }
        }
      } else {
        if (this.formSuperior[index].id == null) {
          this.removeRow(type, index);
          this.hideLoader();
        } else {
          if (this.formSuperior[index].id) {
            SettingCashbackService.deleteCashback(
              this.formSuperior[index].id
            ).then(
              (response) => {
                if (response.data.result.isSuccess) {
                  this.removeRow(type, index);
                  this.snackbar = "success";
                  this.message = "Delete Rule Success";
                  this.setTimeOutNotification();
                } else {
                  this.snackbar = "danger";
                  this.message = response.data.result.message;
                  this.setTimeOutNotification();
                }
                this.hideLoader();
              },
              (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.hideLoader();
                this.setTimeOutNotification();
              }
            );
          }
        }
      }
    },
    confirmDelete(type, index) {
      this.typeCashbackDelete = type;
      this.indexItem = index;
      $(".btn-confirm-modal").click();
    },

    removeRow(type, index) {
      if (type == 1) {
        if (this.formStandard.length > 0) {
          this.formStandard.splice(index, 1);
        }
      } else {
        if (this.formSuperior.length > 0) {
          this.formSuperior.splice(index, 1);
        }
      }
    },

    resetRequired(type, index) {
      if (type == 1) {
        this.fieldRequiredStandard[index].isRuleNameRequire = false;
        this.fieldRequiredStandard[index].isSpendEveryRequire = false;
        this.fieldRequiredStandard[index].isRebateRequire = false;
        this.fieldRequiredStandard[index].isDateRequire = false;
      } else {
        this.fieldRequiredSuperior[index].isRuleNameRequire = false;
        this.fieldRequiredSuperior[index].isSpendEveryRequire = false;
        this.fieldRequiredSuperior[index].isRebateRequire = false;
        this.fieldRequiredSuperior[index].isDateRequire = false;
      }
    },

    processValidate(type, index) {
      this.resetRequired(type, index);
      var isValid = true;
      if (type == 1) {
        if (
          this.formStandard[index].rule_name == "" ||
          this.formStandard[index].rule_name == null
        ) {
          this.fieldRequiredStandard[index].isRuleNameRequire = true;
          isValid = false;
        }
        if (
          this.formStandard[index].spend_every == "" ||
          this.formStandard[index].spend_every == null
        ) {
          this.fieldRequiredStandard[index].isSpendEveryRequire = true;
          isValid = false;
        }
        if (
          this.formStandard[index].rebate == "" ||
          this.formStandard[index].rebate == null
        ) {
          this.fieldRequiredStandard[index].isRebateRequire = true;
          isValid = false;
        }

        const dateFrom = this.dateTimeFormat(
          this.formStandard[index].start_date,
          this.formStandard[index].start_time
        );
        const dateTo = this.dateTimeFormat(
          this.formStandard[index].end_date,
          this.formStandard[index].end_time
        );
        if (dateFrom == null && dateTo != null) {
          this.fieldRequiredStandard[index].isDateRequire = true;
          this.textRequiredStandardDateCustom =
            "The start date field is required.";
          isValid = false;
        } else if (dateFrom != null && dateTo != null && dateFrom >= dateTo) {
          this.fieldRequiredStandard[index].isDateRequire = true;
          this.textRequiredStandardDateCustom =
            "The end date must be a date after start date.";
          isValid = false;
        }
      } else {
        if (
          this.formSuperior[index].rule_name == "" ||
          this.formSuperior[index].rule_name == null
        ) {
          this.fieldRequiredSuperior[index].isRuleNameRequire = true;
          isValid = false;
        }
        if (
          this.formSuperior[index].spend_every == "" ||
          this.formSuperior[index].spend_every == null
        ) {
          this.fieldRequiredSuperior[index].isSpendEveryRequire = true;
          isValid = false;
        }
        if (
          this.formSuperior[index].rebate == "" ||
          this.formSuperior[index].rebate == null
        ) {
          this.fieldRequiredSuperior[index].isRebateRequire = true;
          isValid = false;
        }

        const dateFrom = this.dateTimeFormat(
          this.formSuperior[index].start_date,
          this.formSuperior[index].start_time
        );
        const dateTo = this.dateTimeFormat(
          this.formSuperior[index].end_date,
          this.formSuperior[index].end_time
        );
        if (dateFrom == null && dateTo != null) {
          this.fieldRequiredSuperior[index].isDateRequire = true;
          this.textRequiredSuperiorDateCustom =
            "The start date field is required.";
          isValid = false;
        } else if (dateFrom != null && dateTo != null && dateFrom >= dateTo) {
          this.fieldRequiredSuperior[index].isDateRequire = true;
          this.textRequiredSuperiorDateCustom =
            "The end date must be a date after start date.";
          isValid = false;
        }
      }

      return isValid;
    },

    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
<style scoped>
.standard-rule {
  color: black !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  font-family: "Roboto Slab", sans-serif !important;
}
</style>