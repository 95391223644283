<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#addOptionGroup"
      id="show-modal-add-option-group"
    ></material-button>
    <div
      class="modal fade"
      id="addOptionGroup"
      tabindex="-1"
      aria-hidden="true"
      style="z-index: 9999"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Option Group</h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal justify-content-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              >clear</i
            >
          </div>
          <div class="modal-body" style="overflow-y: unset">
            <div class="group-option-list">
              <div v-for="(group, index) in groupList" :key="index" >
                <div class="group-info" :class="['group-info-' + group.id, groupIdsSelectAfter.includes(group.id) ? 'selected' : '']" @click="selectGroup(group.id, group)">
                {{ group.name.toUpperCase() }}
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-5">
              <material-button
                class="mx-2 btn btn-sm btn-background"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </material-button>
              <material-button class="mx-2 btn btn-sm btn-background" @click="addOptionGroup()">
                Add
              </material-button>
            </div>
          </div>
        </div>
      </div>
      <material-loading :active="loaderActive" />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import MaterialButton from "@/components/MaterialButton.vue";
import ModifierOptionGroupService from '../services/product/modifierOptionGroup';

export default {
  name: "add-option-group",
  components: {
    MaterialButton
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    setId: {
      type: Number,
      required: true,
    },
    itemId: {
      type: Number,
      default: 0
    },
    groupIdsSelect: {
      type: Array,
      required: true,
    },
    groupsSelect: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      snackbar: null,
      message: "",
      loaderActive: false,
      groupList: [],
      groupIdsSelectAfter: [],
      groupsSelectAfter: []
    }
  },
  created() {
    this.getModifierOptionGroup();
  },
  methods: {
    selectGroup(id, group) {
      $('.group-info-' + id).toggleClass('selected');

      if (this.groupIdsSelect.includes(id)) {
        let index = this.groupIdsSelect.indexOf(id);
        this.groupIdsSelectAfter.splice(index, 1);
        this.groupsSelectAfter.splice(index, 1);
      } else {
        this.groupIdsSelectAfter.push(id);
        this.groupsSelectAfter.push(group);
      }
    },

    getModifierOptionGroup() {
      this.showLoader();
      var dataForm = {
        set_id: this.setId,
        item_id: this.itemId
      };
      this.groupIdsSelectAfter =  this.groupIdsSelect;
      this.groupsSelectAfter = this.groupsSelect;

      ModifierOptionGroupService.getListByMenuSet(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.groupList = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    addOptionGroup() {
      $(".btn-close-modal").click();
      this.$emit('handle-add-option-group', this.groupIdsSelectAfter, this.groupsSelectAfter)
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    keyReload() {
      this.getModifierOptionGroup();
    }
  }
};
</script>
<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.modal-body .row {
  margin-top: 10px;
}
.content {
  cursor: pointer;
  text-align: center;
  width: 250px;
  height: 250px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
}
.css-icon {
  margin-top: 30px;
  width: 1px !important;
  font-size: 74px !important;
  transform: translateX(-332px);
}
.css-icon-menubook {
  position: relative;
  margin-top: 30px;
  width: 1px !important;
  font-size: 74px !important;
  transform: translateX(-35px);
}
.font-18 {
  font-size: 18px;
}

.group-option-list .group-info {
  padding: 15px 10px;
  border-bottom: 1px solid #dee2e6;
  cursor: pointer;
}

.group-option-list .group-info.selected {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background-color: #82ccdd;
}
</style>
