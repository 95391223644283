<template>
  <div>
    <div class="dropdown">
      <button class="btn btn-secondary dropdown-toggle btn-sm click btn-toggle-custom" type="button">
        Show Columns
      </button>
      <div class="muliple-select-custom showup">
        <ul>
          <div v-for="(item, index) in columns" :key="index">
            <li>
              <div class="form-check"  style="padding-left: 0.6em !important; padding-right: 0.6em !important">
                <input class="form-check-input" @click="index == 0 ? checkAll() : checkItem(item.value)" :checked="index == 0 ? isCheckedAll : columnChecked.includes(item?.value)" type="checkbox" :value="item.value" :id="item.value">
                <label class="form-check-label" :for="item.value">
                  {{ item.label }}
                </label>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/js/dist/modal";
import "@vuepic/vue-datepicker/dist/main.css";
import "@vueform/multiselect/themes/default.css";
import $ from "jquery";

export default {
  name: "CashbackRule",
  props: {
    columns: {
      type: Array,
      required: true,
    },
    changeCheckbox: {
      type: Function,
      default: () => { },
    },
    columnCheckedCache: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columnChecked: [],
      isCheckedAll: false,
    };
  },
  mounted() {
    if(!this.columnCheckedCache || this.columnCheckedCache.length == 0){
      $(".muliple-select-custom #all").click()
    }else{
      this.checkAll();
    }
    $(document).ready(function () {
      $('.click').click(function (event) {
        event.stopPropagation();
        $(".showup").slideToggle("fast");
      });
      $(".showup").on("click", function (event) {
        event.stopPropagation();
      });
    });

    $(document).on("click", function () {
      $(".showup").hide();
    });
  },
  methods: {
    async checkAll() {
      await this.columns.forEach((item) => {
          this.deleteItem(item.value);
      });
      if (!this.isCheckedAll) {
        if(!this.columnCheckedCache || this.columnCheckedCache.length == 0){
            await this.columns.forEach((item) => {
              this.columnChecked.push(item.value);
            });
        }else{
          this.columnChecked = this.columnCheckedCache;
        }
      }
      this.checkAllStatus();
    },
    checkItem(cloumnValue) {
      if (this.columnChecked.includes(cloumnValue)) {
        this.deleteItem(cloumnValue);
      } else {
        this.columnChecked.push(cloumnValue);
      }
      this.checkAllStatus();
    },
    deleteItem(cloumnValue) {
      this.columnChecked.forEach((value, index) => {
        if (value == cloumnValue) {
          this.columnChecked.splice(index, 1);
        }
      });
    },
    async checkAllStatus() {
      this.isCheckedAll = true;
      await this.columns.forEach((item, index) => {
        if (!this.columnChecked.includes(item.value) && index != 0) {
          this.isCheckedAll = false;
          return;
        }
      });
      this.changeCheckbox(this.columnChecked)
    },
  },
};
</script>
<style scoped>
.btn-toggle-custom {
  margin-bottom: 0.5rem;
}

.muliple-select-custom {
  background-color: #FFFFFF;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
  border-radius: 0.375rem;
  position: absolute;
  z-index: 1000000 !important;
}

.showup {
  display: none;
}

.muliple-select-custom ul {
  list-style: none;
  padding-left: 0rem;
}
</style>
