import api from './api';

class SettingStoreService {
  getDataBranches(dataForm) {
    return api.post('/merchant/branches', dataForm);
  }

  deleteBranch(branchId) {
    return api.post('/merchant/branch/delete/' + branchId);
  }

  createNewBranch() {
    return api.post('/merchant/branch');
  }

  getDataBranchDetail(branchId) {
    if(branchId) {
      return api.post('/merchant/branch/' + branchId);
    } else {
      return api.post('/merchant/branch');
    }
  }

  addOrUpdateBranch(dataForm) {
    return api.post('/merchant/branch-create-or-update', dataForm, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  changeStatus(branchId) {
    return api.post('/merchant/branch/change-status/' + branchId);
  }
}

export default new SettingStoreService();
