import api from './api';

class SettingDiscountService {
  getDataDiscount() {
    return api.get('/merchant/discount-setting');
  }

  getDataDiscounts(dataForm) {
    return api.post('/merchant/discount/list', dataForm);
  }

  getDataDiscountDetail(discountId) {
    if (discountId) {
      return api.post('/merchant/discount/detail/' + discountId);
    } else {
      return api.post('/merchant/discount/detail');
    }
  }

  addOrUpdateDiscount(dataForm) {
    return api.post('/merchant/discount/create-or-update', dataForm);
  }

  deleteDiscount(discountId) {
    return api.post('/merchant/discount/delete/' + discountId);
  }
}

export default new SettingDiscountService();
