import api from './api';

class BulkVoucherIssueService {
    getDetail(id) {
        return api.post('/merchant/get-detail-bulk-voucher-issue/'+id, {  });
    }
    createBulkVoucherIssue(dataForm) {
        return api.post('/merchant/create-bulk-voucher-issue', dataForm);
    }
    getDataBulkVoucherIssues(dataForm) {
        return api.post('/merchant/get-list-bulk-voucher-issue', dataForm);
    }
    sendVoucherToMember(dataForm) {
        return api.post('/merchant/send-voucher-to-member-from-bulk-voucher-issue', dataForm);
    }
    getIssueCodeList(dataForm) {
        return api.post('/merchant/get-issue-code-list', dataForm);
    }
}

export default new BulkVoucherIssueService();