<template>
    <div>
        <div class="row mb-2 pe-4">
            <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
                <h4
                class="
                    col-lg-10 col-md-10 col-12
                    text-back
                    d-flex
                    align-items-center
                    ps-2
                "
                >
                <i
                    class="fa fa-chevron-circle-left pe-2"
                    aria-hidden="true"
                    @click="backSubscription()"
                    style="cursor: pointer"
                ></i>
                <div @click="backSubscription()" style="cursor: pointer">BACK</div>
                </h4>
            </div>
        </div>
      <div class="row my-2 d-flex">
        <div class="col-lg-6 col-md-6 col-12 text-title">SUBSCRIPTION REPORT</div>
      </div>
      <div class="page-header align-items-start min-vh-100 my-transacions">
        <span class="mask bg-white"></span>
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12 mx-auto">
              <div class="row mt-4">
                <div class="col-12">
                  <div class="card-custom">
                    <div class="table-responsive p-0 custom-table">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th class="text-center font-weight-bolder">Transaction ID</th>
                            <th class="text-center font-weight-bolder">Current Payment Period</th>
                            <th class="text-center font-weight-bolder">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in subscriptions"
                            :index="index"
                            :key="index"
                          >
                            <td class="text-center text-xss w-5">
                                {{ item.transaction_id }}
                            </td>
                            <td class="text-center text-xss w-5">
                                {{ item.payment_period }}
                            </td>
                            <td class="text-center text-xss w-5">
                                <a
                                    class="btn-link text-dark text-underline"
                                    @click="viewInvoice(item.id)"
                                >
                                    View Invoices
                                </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <pagination
                      v-if="pagination.last_page > 1"
                      :pagination="pagination"
                      :current_page="pagination.current_page"
                      :last_page="pagination.last_page"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2 message-success">
          <material-snackbar
            v-if="snackbar === 'danger' || snackbar === 'success'"
            title=""
            date=""
            :description="message"
            :icon="{ component: 'campaign', color: 'white' }"
            :color="snackbar"
            :close-handler="closeSnackbar"
          />
        </div>
        <material-loading :active="loaderActive" />
      </div>
    </div>
  </template>
  
  <script>
  import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
  import "bootstrap/dist/js/bootstrap.bundle";
  import MaterialLoading from "@/components/MaterialLoading.vue";
  import Pagination from "@/components/Pagination.vue";
  import "bootstrap/js/dist/modal";
  import "@vuepic/vue-datepicker/dist/main.css";
  import Subscriptions from "../../services/subscriptions.service";
  
  export default {
    name: "find-store",
    components: {
      MaterialSnackbar,
      MaterialLoading,
      Pagination
    },
    mounted() {
        const code = localStorage.getItem("MERCHANT_CODE", "");
        this.merchantCode = code ? `/${code}` : "";

        this.getData();
    },
    data() {
      return {
        message: "",
        snackbar: null,
        loaderActive: false,
        picked: '',
        subscriptions: [],
        pagination: {},
        merchantCode: '',
        page: 1,
      };
    },
    methods: {
        closeSnackbar() {
            this.snackbar = null;
        },
  
        setTimeOutNotification() {
            setTimeout(() => {
            this.snackbar = null;
            }, 3000);
        },
  
        showLoader() {
            this.loaderActive = true;
        },
        hideLoader() {
            this.loaderActive = false;
        },
        purchase() {

        },
        backSubscription() {
            this.$router.push("/subscriptions");
        },
        getData() {
            this.subscriptions = [];
            this.showLoader();
            var dataForm = {
                
            };
            Subscriptions.getDataSubscriptionsHistory(dataForm).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.subscriptions = response.data.data.items;
                        this.pagination = response.data.data;
                        this.page = this.pagination.current_page;
                        this.$router.push({
                            query: Object.assign({}, this.$route.query, { page: this.page }),
                        });
                        // this.subscriptions = response.data.data;
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                    this.hideLoader();
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.hideLoader();
                    this.setTimeOutNotification();
                }
            );
        },
        viewInvoice(id) {
            this.$router.push(
              "/view-report-subscriptions-invoice/" + id + "?page=" + this.page
            );
        },
    },
  };
  </script>
  <style scoped>
  .text-title {
    color: #82001e  !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    font-family: "Roboto Slab", sans-serif !important;
  }
  
  .text-label {
    color: #5b848d !important;
    font-weight: 500 !important;
    font-size: 17px !important;
    font-family: "Roboto Slab", sans-serif !important;
  }
  
  .checkbox-auto-save label {
    padding-top: 11px !important;
  }
  
  input[type="search"] {
    border: none;
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
  }
  
  input[type="search"]::placeholder {
    color: #bbb;
    text-align: left;
    font-weight: normal;
  }
  
  input[type="search"]:focus {
    border-color: #72959d;
    outline: none;
    border: none;
    border-bottom: 1px solid #72959d;
  }
  
  .nosubmit-parent {
    border: none;
    padding: 0;
  }
  
  input.nosubmit {
    border-bottom: 1px solid #d2d2d2;
    width: 100%;
    padding: 9px 4px 9px 40px;
    background: transparent
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat 13px center;
  }
  
  .custom-title {
    word-break: break-all;
    max-width: 200px;
    white-space: normal;
  }
  .float-right {
      float: right;
  }

  .margin-right-btn {
      margin-right: 5px;
  }

  .text-underline {
    text-decoration-line: underline;
    padding-left: 0px;
    cursor: pointer;
  }
  .text-back {
    color: black !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    font-family: "Roboto Slab", sans-serif !important;
    }
  </style>