<template>
  <div>
    <div class="row mb-3 pe-4">
      <div class="col-lg-8 col-md-6 col-6 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-2">GENERAL</h3>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-6 d-flex justify-content-end" v-if="!isMemberPreference">
        <material-button @click="saveDataConfigs()" class="btn-background btn-sm">
          {{ $t('Save') }}
        </material-button>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <!-- <span class="mask bg-white"></span> -->
      <div class="c-container">
        <div class="row">
          <div
            class="col-lg-12 col-md-12 col-12 mx-auto pb-5 mt-2"
            v-if="isLoadDoneData"
          >
            <div class="card-body" style="padding: 0rem 0rem">
              <div>
                <div class="row d-flex">
                  <div class="tab-list p-0">
                  <ul id="general-tab" class="nav nav-tabs">
                    <li class="nav-item">
                      <a
                        id="nav_item_1"
                        class="nav-link active"
                        @click="changeTab(1)"
                      >
                        {{ $t('Merchant_Info') }}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a id="nav_item_2" class="nav-link" @click="changeTab(2)">
                        {{ $t('Merchant_Appearance') }}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a id="nav_item_3" class="nav-link" @click="changeTab(3)">
                        {{ $t('Options') }}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a id="nav_item_4" class="nav-link" @click="changeTab(4)">
                        {{ $t('Member_Special_Rewards') }}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a id="nav_item_5" class="nav-link" @click="changeTab(5)">
                        SMS Setup Forget Passcode
                      </a>
                    </li>
                    <li class="nav-item">
                      <a id="nav_item_6" class="nav-link" @click="changeTab(6)">
                        {{ $t('Notifications') }}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a id="nav_item_7" class="nav-link" @click="changeTab(7)">
                        Payment Method
                      </a>
                    </li>
                    <li class="nav-item">
                      <a id="nav_item_8" class="nav-link" @click="changeTab(8)">
                        Pos Staff
                      </a>
                    </li>
                    <li class="nav-item">
                      <a id="nav_item_9" class="nav-link" @click="changeTab(9)">
                        Member Preference
                      </a>
                    </li>
                    <li class="nav-item">
                      <a id="nav_item_10" class="nav-link" @click="changeTab(10)">
                        Member Profiles
                      </a>
                    </li>
                  </ul>
                </div>
                  <div class="row card-custom-new mt-3">
                    <div class='col-lg-8 col-md-12 col-12' :class="{ 'col-lg-12': isPaymentMethod || isPosStaff || isMemberPreference || isMemberProfiles || isMemberSpecialRewards }">
                      <div
                        class="col-lg-12 col-md-12 col-12 py-1"
                        v-if="isMerchantInfo"
                      >
                        <div class="row">
                          <div
                            class="
                              col-lg-4 col-md-4 col-12
                              label-form
                              d-flex
                              align-items-center
                            "
                          >
                            {{ $t('Contact_Infomation') }}
                          </div>
                          <div class="col-lg-8 col-md-8 col-12">
                            <material-textarea v-model="form.contact_info" />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-12 col-md-12 col-12 py-1"
                        v-if="isMerchantInfo"
                      >
                        <div class="row">
                          <div
                            class="
                              col-lg-4 col-md-4 col-12
                              label-form
                              d-flex
                              align-items-center
                            "
                          >
                            {{ $t('Contact_Emails') }}
                          </div>
                          <div class="col-lg-8 col-md-8 col-12">
                            <material-input v-model="form.contact_email" />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-12 col-md-12 col-12 py-1"
                        v-if="isMerchantInfo"
                      >
                        <div class="row">
                          <div
                            class="
                              col-lg-4 col-md-4 col-12
                              label-form
                              d-flex
                              align-items-center
                            "
                          >
                            {{ $t('Free_Text') }}
                          </div>
                          <div class="col-lg-8 col-md-8 col-12">
                            <material-textarea v-model="form.free_text" />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-12 col-md-12 col-12"
                        v-if="isMerchantAppearance"
                      >
                        <div class="row">
                          <div
                            class="
                              col-lg-4 col-md-4 col-12
                              label-form
                              d-flex
                              align-items-center
                            "
                          >
                            {{ $t('Merchant_Logo') }}
                          </div>
                          <div
                            class="
                              col-lg-3 col-md-4 col-12
                              d-flex
                              align-items-center
                            "
                          >
                            <div>
                              <material-input
                                style="display: none"
                                type="file"
                                id="image1"
                                @change="handleFileUpload($event)"
                                accept="image/*"
                              />
                              <material-button
                                @click="uploadFile()"
                                class="mx-2 btn-sm btn-background"
                              >
                                {{ $t('UPLOAD_ICON') }}
                              </material-button>
                              <div class="pt-2 text-size-12">
                                Image (480x480, png, jpg/jpeg format only, max size allowed 5MB)
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-5 col-md-4 col-12">
                            <div id="preview" style="position: relative">
                              <img
                                v-if="urlImagePreview || form.merchant_logo"
                                :src="
                                  urlImagePreview
                                    ? urlImagePreview
                                    : form.merchant_logo
                                "
                              />
                              <i
                                class="
                                  fa fa-times
                                  icon-close-sidenav-main
                                  danger
                                "
                                v-if="urlImagePreview || form.merchant_logo" 
                                style="
                                  position: absolute;
                                  top: 0px;
                                  left: 100px;
                                  color: #f44335;
                                  font-size: 20px;
                                "
                                aria-hidden="true"
                                @click="clearImage()"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-12 col-md-12 col-12 py-1"
                        v-if="isMerchantAppearance"
                      >
                        <div class="row">
                          <div
                            class="
                              col-lg-4 col-md-4 col-12
                              label-form
                              d-flex
                              align-items-center
                            "
                          >
                            {{ $t('Loyalty_App_Theme_Color') }}
                          </div>
                          <div class="col-lg-8 col-md-8 col-12">
                            <color-input v-model="form.bg_color" position="right bottom"/>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-12 col-md-12 col-12 py-1"
                        v-if="isMerchantAppearance"
                      >
                        <div class="row">
                          <div
                            class="
                              col-lg-4 col-md-4 col-12
                              label-form
                              d-flex
                              align-items-center
                            "
                          >
                            {{ $t('Loyalty_App_Button_Color') }}
                          </div>
                          <div class="col-lg-8 col-md-8 col-12">
                            <color-input v-model="form.button_color" position="right bottom"/>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-12 col-md-12 col-12 py-1"
                        v-if="isMerchantAppearance"
                      >
                        <div class="row">
                          <div
                            class="
                              col-lg-4 col-md-4 col-12
                              label-form
                              d-flex
                              align-items-center
                            "
                          >
                            {{ $t('Loyalty_App_Button_Text_Color') }}
                          </div>
                          <div class="col-lg-8 col-md-8 col-12">
                            <color-input v-model="form.button_text_color" position="right bottom"/>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-12 col-md-12 col-12 py-1"
                        v-if="isMerchantAppearance"
                      >
                        <div class="row">
                          <div
                            class="
                              col-lg-4 col-md-4 col-12
                              label-form
                              d-flex
                              align-items-center
                            "
                          >
                            {{ $t('Loyalty_App_Text_Header_Color') }}
                          </div>
                          <div class="col-lg-8 col-md-8 col-12">
                            <color-input v-model="form.label_color" position="right bottom"/>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="isOptions">
                        <div
                          class="
                            col-lg-4 col-md-4 col-12
                            label-form
                            d-flex
                            align-items-center
                          "
                        >
                          {{ $t('Allow_set_up_membership_tiering') }}
                        </div>
                        <div
                          class="
                            col-lg-8 col-md-8 col-12
                            d-flex
                            align-items-center
                          "
                        >
                          <div
                            :class="{ active: isActive }"
                            class="toggle_container mt-3"
                          >
                            <MaterialToggleButton
                              labelEnableText=""
                              labelDisableText=""
                              id="event_handle"
                              v-on:change="triggerEvent"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="isOptions">
                        <div
                          class="
                            col-lg-4 col-md-4 col-12
                            label-form
                            d-flex
                            align-items-center
                          "
                        >
                          {{ $t('Allows_displaying_membership_spending') }}
                        </div>
                        <div
                          class="
                            col-lg-8 col-md-8 col-12
                            d-flex
                            align-items-center
                          "
                        >
                          <div
                            :class="{ active: isActiveShowSpending }"
                            class="toggle_container mt-3"
                          >
                            <MaterialToggleButton
                              labelEnableText=""
                              labelDisableText=""
                              id="event_handle_show_spending"
                              v-on:change="triggerEventShowSpending"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="isOptions">
                        <div
                          class="
                            col-lg-4 col-md-4 col-12
                            label-form
                            d-flex
                            align-items-center
                          "
                        >
                          {{ $t('Allows_displaying_lifetime_earn_cashback') }}
                        </div>
                        <div
                          class="
                            col-lg-8 col-md-8 col-12
                            d-flex
                            align-items-center
                          "
                        >
                          <div
                            :class="{ active: isActiveShowLifetimeEarnCashback }"
                            class="toggle_container mt-3"
                          >
                            <MaterialToggleButton
                              labelEnableText=""
                              labelDisableText=""
                              id="event_handle_show_lifetime_earn_cashback"
                              v-on:change="triggerEventShowLifetimeEarnCashback"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="isOptions">
                        <div
                          class="
                            col-lg-4 col-md-4 col-12
                            label-form
                            d-flex
                            align-items-center
                          "
                        >
                          {{ $t('Allows_displaying_redeemed_cashback') }}
                        </div>
                        <div
                          class="
                            col-lg-8 col-md-8 col-12
                            d-flex
                            align-items-center
                          "
                        >
                          <div
                            :class="{ active: isActiveShowRedeemedCashback }"
                            class="toggle_container mt-3"
                          >
                            <MaterialToggleButton
                              labelEnableText=""
                              labelDisableText=""
                              id="event_handle_show_redeemed_cashback"
                              v-on:change="triggerEventShowRedeemedCashback"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="isOptions">
                        <div
                          class="
                            col-lg-4 col-md-4 col-12
                            label-form
                            d-flex
                            align-items-center
                          "
                        >
                          {{ $t('Otp_test_mode') }}
                        </div>
                        <div
                          class="
                            col-lg-8 col-md-8 col-12
                            d-flex
                            align-items-center
                          "
                        >
                          <div
                            :class="{ active: isActiveOtpTestMode }"
                            class="toggle_container mt-3"
                          >
                            <MaterialToggleButton
                              labelEnableText=""
                              labelDisableText=""
                              id="event_handle_otp_test_mode"
                              v-on:change="triggerEventOtpTestMode"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="isOptions">
                        <div
                          class="
                            col-lg-4 col-md-4 col-12
                            label-form
                            d-flex
                            align-items-center
                          "
                        >
                          {{ $t('Cashback_by_subtotal') }}
                        </div>
                        <div
                          class="
                            col-lg-8 col-md-8 col-12
                            d-flex
                            align-items-center
                          "
                        >
                          <div
                            :class="{ active: isActiveCashbackBySubtotal }"
                            class="toggle_container mt-3"
                          >
                            <MaterialToggleButton
                              labelEnableText=""
                              labelDisableText=""
                              id="event_handle_cashback_by_subtotal"
                              v-on:change="triggerEventCashbackBySubtotal"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="isOptions">
                        <div class="row">
                          <div
                            class="
                              col-lg-4 col-md-4 col-12
                              label-form
                              d-flex
                              align-items-center
                            "
                          >
                            Api Key
                          </div>
                          <div class="col-lg-8 col-md-8 col-12">
                            <material-input v-model="form.api_key" />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-12 col-md-12 col-12 py-1"
                        v-if="isMemberSpecialRewards"
                      >
                        <div class="row">
                          <div class="col-lg-4 col-md-6 col-12 label-form">
                            {{ $t('Reward_per_transaction') }}
                          </div>
                          <div class="col-lg-6 col-md-6 col-12 d-flex justify-content-between">
                            <MaterialRadio
                              id="reward_cashback"
                              class="ps-0"
                              value="1"
                              v-model="form.reward_per_transaction"
                            >
                              Cashback
                            </MaterialRadio>
                            <MaterialRadio
                              id="reward_point"
                              value="2"
                              v-model="form.reward_per_transaction"
                            >
                              Point
                            </MaterialRadio>
                            <MaterialRadio
                              id="reward_none"
                              value="0"
                              v-model="form.reward_per_transaction"
                            >
                              None
                            </MaterialRadio>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-12 col-md-12 col-12 py-1"
                        v-if="isMemberSpecialRewards"
                      >
                        <div class="row">
                          <div class="col-lg-4 col-md-6 col-12 label-form">
                            Profile sharing reward(Voucher sent when user inputs
                            or shares profile to merchant)
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <Multiselect
                              v-model="form.profile_sharing_reward"
                              :options="rewards"
                              :canClear="false"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-12 col-md-12 col-12 py-1"
                        v-if="isMemberSpecialRewards"
                      >
                        <div class="row">
                          <div class="col-lg-4 col-md-6 col-12 label-form">
                            Friend inviting reward(Voucher sent when user's
                            friend sign up)
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <Multiselect
                              v-model="form.friend_inviting_reward"
                              :options="rewards"
                              :canClear="false"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-12 col-md-12 col-12 py-1"
                        v-if="isMemberSpecialRewards"
                      >
                        <div class="row">
                          <div class="col-lg-4 col-md-6 col-12 label-form">
                            Paid Membership Reward
                          </div>
                          <div class="col-lg-6 col-md-6 col-12" style="display: inline-flex;">
                            <Multiselect
                              v-model="idRewardAddPaidMembershipReward"
                              :options="rewards"
                              :canClear="false"
                            />
                          </div>
                          <div class="col-lg-2 col-md-6 col-12" style="display: inline-flex;">
                            <material-button
                              @click="addPaidMembershipReward()"
                              class="mx-2 btn-background btn-sm"
                            >
                              Add
                            </material-button>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-12 col-md-12 col-12 py-1"
                        v-if="isMemberSpecialRewards"
                      >
                        <div class="row">
                          <div class="col-lg-4 col-md-6 col-12 label-form">
                            
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div v-for="(value, key) in listPaidMembershipRewardByMerchant" :key="key">
                              <div style="display: inline-flex;align-items: center;gap: 10px;">
                                <div style="color: blue">{{ value.reward_name }}</div>
                                <i
                                  class="fa fa-times icon-close-sidenav-main danger"
                                  style="
                                    color: #f44335;
                                    font-size: 20px;
                                    cursor: pointer;
                                  "
                                  aria-hidden="true"
                                  @click="removePaidMembershipRewardByMerchant(value.id)"
                                ></i>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-2 col-md-6 col-12" style="display: inline-flex;">
                            
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-12 col-md-12 col-12 py-1"
                        v-if="isSmsSetup"
                      >
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12 label-form" style="display: inline-flex;align-items: center;">
                            1/ ALLOW RESET PASSCODE TO BIRTHDAY (DDMM)
                            <material-checkbox
                                @change="allowResetPasscodeToBirthday(form.allow_reset_passcode_to_birthday)"
                                v-model="form.allow_reset_passcode_to_birthday"
                                :checked="form.allow_reset_passcode_to_birthday"
                                name="sms"
                                class="align-items-center px-3"
                              ></material-checkbox>
                          </div>
                          <span v-if="form.allow_reset_passcode_to_birthday">Turn on Allow reset passcode to member birthday DDMM will also make Birthday field in Profile displays and be compulsory. Please check General > Profile tab</span>
                        </div>
                        <br />
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12 label-form">
                            2/ ONE-TIME PASSCODE MESSAGE
                          </div>
                          <span>{{ $t('Character_count') }}: {{ form.contentSmsSetupForgetPasscode.length }}/{{ characterSmsForgotPasswordLimit }}</span>
                          <material-textarea @input="limitChar" v-model="form.contentSmsSetupForgetPasscode" placeholder="Enter SMS Foget Passcode here" />
                        </div>
                      </div>
                      <div
                        class="col-lg-12 col-md-12 col-12 py-1"
                        v-if="isNotificationSetup"
                      >
                        <div class="row">
                          <table class="table align-items-center mb-0">
                            <thead>
                              <tr>
                                <th class="text-center font-weight-bolder w-20">
                                  {{ $t('Notifications') }}
                                </th>
                                <th class="text-center font-weight-bolder w-20">
                                  {{ $t('In_App') }}
                                </th>
                                <th class="text-center font-weight-bolder w-20">
                                  {{ $t('Email') }}
                                </th>
                                <th class="text-center font-weight-bolder w-20">
                                  {{ $t('SMS') }}
                                </th>
                                <th class="text-center font-weight-bolder w-20">
                                  {{ $t('Notification_Content') }}
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="dataSettingNofifications && dataSettingNofifications.length">
                              <tr v-for="(value, key) in dataSettingNofifications" :key="key">
                                <td class="text-left text-xss">
                                  {{ value.type }}
                                </td>
                                <td class="text-center text-xss">
                                  <material-checkbox
                                    @change="addSettingMerchantNotification(value, 1, value.is_enable_in_app)"
                                    v-model="value.is_enable_in_app"
                                    :checked="value.is_enable_in_app"
                                    name="in-app"
                                    class="align-items-center px-3"
                                  ></material-checkbox>
                                </td>
                                <td class="text-center text-xss">
                                  <material-checkbox
                                    @change="addSettingMerchantNotification(value, 2, value.is_enable_email)"
                                    v-model="value.is_enable_email"
                                    :checked="value.is_enable_email"
                                    name="email"
                                    class="align-items-center px-3"
                                  ></material-checkbox>
                                </td>
                                <td class="text-center text-xss">
                                  <material-checkbox
                                    @change="addSettingMerchantNotification(value, 3, value.is_enable_sms)"
                                    v-model="value.is_enable_sms"
                                    :checked="value.is_enable_sms"
                                    name="sms"
                                    class="align-items-center px-3"
                                  ></material-checkbox>
                                </td>
                                <td class="text-center text-xss" style="display: flex;">
                                  <a v-if="value.is_enable_in_app" class="nav-link" @click="addSettingMerchantNotification(value, 1, value.is_enable_in_app)" style="cursor: pointer;">In-App</a>
                                  <!-- <a v-if="value.is_enable_email" class="nav-link" @click="addSettingMerchantNotification(value, 2, value.type == 'Member Get Birthday Reward' || value.type == 'Member Get Profile Update Reward' ? value.is_enable_email : !value.is_enable_email)" style="cursor: pointer;">Email</a> -->
                                  <a v-if="value.is_enable_email" class="nav-link" @click="addSettingMerchantNotification(value, 2, !value.is_enable_email)" style="cursor: pointer;">Email</a>
                                  <a v-if="value.is_enable_sms" class="nav-link" @click="addSettingMerchantNotification(value, 3, value.is_enable_sms)" style="cursor: pointer;">SMS</a>
                                  <a v-if="!value.is_enable_in_app && !value.is_enable_email && !value.is_enable_sms" class="nav-link">No Content</a>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td colspan="5" class="text-center">
                                  No item available
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div style="margin-top: 30px; color: red;" v-if="getSmsBalanceByAdminMerchant < 100">
                            You SMS quota has reached/is reaching the limit, please click below link to purchase credits.
                          </div>
                          <a id="nav_item_2" class="nav-link" href="/sms-setup" style="margin-top: 15px; color: blue; text-decoration: underline;">
                              SMS Credit - Current Balance: {{ getSmsBalanceByAdminMerchant }} msg
                          </a>
                        </div>
                      </div>
                      <div
                        class="col-lg-12 col-md-12 col-12 py-1"
                        v-if="isPaymentMethod"
                      >
                        <div class="row mb-2 pe-4">
                          <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
                            <div class="color-common">
                              <h3 class="title-page mb-0 ps-3">PAYMENT METHODS</h3>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-5 col-5 d-flex justify-content-end">
                            <material-button
                              @click="addPaymentMethod()"
                              :disabled="disableBtnSubmit"
                              class="mx-2 btn-background btn-sm"
                            >
                              Add payment method
                            </material-button>
                          </div>
                        </div>
                        <div class="page-header align-items-start min-vh-100 my-transacions">
                          <span class="mask bg-white"></span>
                          <div class="container">
                            <div class="row">
                              <div class="notifications">
                                <material-alert
                                  v-if="error"
                                  color="danger"
                                  icon="ni ni-like-2 ni-lg"
                                  dismissible
                                >
                                  {{ error }}
                                </material-alert>
                              </div>
                              <div class="col-lg-12 col-md-12 col-12 mx-auto" v-if="isLoadData">
                                <div class="row mt-4">
                                  <div class="col-12">
                                    <div class="card-custom">
                                      <div class="px-0 pb-2">
                                        <div class="table-responsive p-0 custom-table">
                                          <table class="table align-items-center mb-0">
                                            <thead>
                                              <tr>
                                                <th class="text-center font-weight-bolder w-5">
                                                  {{ $t('ID') }}
                                                </th>
                                                <th class="text-center font-weight-bolder">{{ $t('Name') }}</th>
                                                <th class="text-center font-weight-bolder w-20">
                                                  Display Order
                                                </th>
                                                <th class="text-center font-weight-bolder w-20">
                                                  {{ $t('status') }}
                                                </th>
                                                <th class="text-center font-weight-bolder w-15">
                                                  {{ $t('action') }}
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody v-if="paymentMethods.length">
                                              <tr v-for="(value, key) in paymentMethods" :key="key">
                                                <td class="text-center text-xss">
                                                  {{ value.id }}
                                                </td>
                                                <td class="text-left text-xss">
                                                  {{ value.name }}
                                                </td>
                                                <td class="text-center text-xss">
                                                  {{ value.display_order }}
                                                </td>
                                                <td class="align-middle text-center w-10">
                                                  <span
                                                    class="badge badge-sm"
                                                    v-bind:class="
                                                      value.status == 1
                                                        ? 'bg-gradient-success'
                                                        : 'bg-danger'
                                                    "
                                                  >
                                                    {{
                                                      value.status == 1 ? "Active" : "Inactive"
                                                    }}</span
                                                  >
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                  <a
                                                    class="btn btn-link text-dark px-3 mb-0"
                                                    @click="editPaymentMethod(value.id)"
                                                  >
                                                    <i
                                                      class="fas fa-pencil-alt text-dark me-2"
                                                      aria-hidden="true"
                                                    ></i
                                                    >Edit
                                                  </a>
                                                </td>
                                              </tr>
                                            </tbody>
                                            <tbody v-else>
                                              <tr>
                                                <td colspan="5" class="text-center">
                                                  No item available
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <pagination
                                        v-if="pagination.last_page > 1"
                                        :pagination="pagination"
                                        :current_page="pagination.current_page"
                                        :last_page="pagination.last_page"
                                      />
                                      <div class="text-center mt-2 d-flex justify-content-end">
                                        <div class="dropdown">
                                          <button
                                            class="btn btn-secondary dropdown-toggle btn-sm"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            style="margin-top: 13px"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            aria-haspopup="true"
                                          >
                                            {{ $t('download') }}
                                          </button>
                                          <ul
                                            class="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton1"
                                          >
                                            <li>
                                              <a
                                                class="dropdown-item"
                                                @click="exportFile('csv')"
                                                href="#"
                                                >Export CSV</a
                                              >
                                            </li>
                                            <li>
                                              <a
                                                class="dropdown-item"
                                                @click="exportFile('xlsx')"
                                                href="#"
                                                >Export Excel</a
                                              >
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <form
                            :action="`${actionDownload}/merchant/download-payment-methods`"
                            method="POST"
                          >
                            <input type="hidden" name="merchantId" :value="merchantIdExport" />
                            <input type="hidden" name="merchant_code" :value="merchantCodeExport" />
                            <input type="hidden" name="token" :value="token" />
                            <input type="hidden" name="action_export" :value="action_export" />
                            <input type="hidden" name="action" value="export" />
                            <input id="downloadForm" type="submit" class="d-none" />
                          </form>
                        </div>
                      </div>
                      <div
                        class="col-lg-12 col-md-12 col-12 py-1"
                        v-if="isPosStaff"
                      >
                        <pos-staff></pos-staff>
                      </div>
                      <div
                        class="col-lg-12 col-md-12 col-12 py-1"
                        v-if="isMemberPreference"
                      >
                        <member-preference></member-preference>
                      </div>
                      <div
                        class="col-lg-12 col-md-12 col-12 py-1"
                        v-if="isMemberProfiles"
                      >
                        <member-profiles></member-profiles>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />

      <div>
        <button
          style="display: none"
          type="button"
          id="action-group"
          data-bs-toggle="modal"
          data-bs-target="#modalAddOrEditSettingNotifications"
        ></button>
        <div class="modal fade" id="modalAddOrEditSettingNotifications" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog" style="max-width: 800px;">
            <div class="modal-content">
              <div class="modal-header" style="display: block;">
                <h6 v-if="currentTypeAddOrUpdate && currentTypeAddOrUpdate == 1">In-App Notification Content</h6>
                <h6 v-if="currentTypeAddOrUpdate && currentTypeAddOrUpdate == 2">Email Notification Content</h6>
                <h6 v-if="currentTypeAddOrUpdate && currentTypeAddOrUpdate == 3">SMS Notification Content</h6>
                <h5 style="text-align: center;">{{ currentItemAddOrUpdate && currentItemAddOrUpdate.type }}</h5>
              </div>
              <div class="modal-body" style="display: flex;">
                <material-textarea ref="textareaSettingNotifications" v-model="currentValueSettingNotifications" style="width: 75%;" @click="getCursorPosition" @change="getCursorPosition()"/>
                <!-- <material-textarea ref="textareaSettingNotifications" v-model="currentValueSettingNotifications" style="width: 64%;" /> -->
                <div style="width: 2%;"></div>
                <div style="width: 23%;">
                  <div v-for="(value, key) in fields" :key="key">
                    <button
                      type="button"
                      class="btn btn-success btn-background btn-sm"
                      @click="applyTagsToTextArea(value.name)"
                      style="width: 100%;background: transparent;background-color: transparent !important;border: 1px solid black;color: black;font-weight: normal;"
                    >
                      {{value.name}}
                    </button>
                  </div>
                </div>
              </div>
              <!-- Modal content-->
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary btn-close-modal btn-sm"
                  data-bs-dismiss="modal"
                  @click="closeSettingNotification"
                  style="background: transparent;background-color: transparent !important;border: 1px solid black;color: black;font-weight: normal;"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-success btn-background btn-sm"
                  @click="saveSettingNotification(false)"
                  style="background: transparent;background-color: transparent !important;border: 1px solid black;color: black;font-weight: normal;"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <button
          style="display: none"
          type="button"
          id="show-payment-method-modal"
          data-bs-toggle="modal"
          data-bs-target="#modalPaymentMethod"
        ></button>
        <div class="modal fade" id="modalPaymentMethod" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{ labelGroup }}</h5>
              </div>
              <div class="modal-body">
                <div class="row d-flex bd-highlight">
                  <div class="col-lg-4 col-4 align-self-center">
                    Name<span class="text-danger ms-2">*</span>
                  </div>
                  <div
                    class="col-lg-8 col-8 d-flex bd-highlight d-flex justify-content-end"
                  >
                    <material-input
                      v-model="dataPaymentMethod.name"
                      extraClass="ml-5"
                      placeholder="Enter payment method name"
                    />
                  </div>
                </div>
                <div
                  class="row d-flex bd-highlight"
                  v-if="fieldRequired.isPaymentMethodNameRequire"
                >
                  <div class="col-lg-4 col-4 align-self-center"></div>
                  <div class="col-lg-8 col-8 d-flex bd-highlight">
                    <span class="is-invalid">{{
                      textRequirePaymentMethodNameCustom
                    }}</span>
                  </div>
                </div>
                <div class="row d-flex bd-highlight pt-2">
                  <div class="col-lg-4 col-4 align-self-center">
                    Display Order<span class="text-danger ms-2">*</span>
                  </div>
                  <div
                    class="col-lg-8 col-8 d-flex bd-highlight d-flex justify-content-end"
                  >
                    <material-input
                      v-model="dataPaymentMethod.display_order"
                      extraClass="ml-5"
                      placeholder="Enter display order"
                      type="number"
                    />
                  </div>
                </div>
                <div
                  class="row d-flex bd-highlight"
                  v-if="fieldRequired.isDisplayOrderRequire"
                >
                  <div class="col-lg-4 col-4 align-self-center"></div>
                  <div class="col-lg-8 col-8 d-flex bd-highlight">
                    <span class="is-invalid">{{
                      textRequireDisplayOrderCustom
                    }}</span>
                  </div>
                </div>
                <div class="row d-flex bd-highlight pt-2">
                  <div class="col-lg-4 col-4 align-self-center">Status</div>
                  <div class="col-lg-8 col-8 d-flex bd-highlight d-flex">
                    <MaterialRadio
                      id="status_1"
                      value="1"
                      class="ps-0"
                      v-model="dataPaymentMethod.status"
                    >
                      Active
                    </MaterialRadio>
                    <MaterialRadio
                      id="status_2"
                      value="0"
                      v-model="dataPaymentMethod.status"
                    >
                      Inactive
                    </MaterialRadio>
                  </div>
                </div>
              </div>
              <!-- Modal content-->
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary btn-close-modal btn-sm"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-success btn-background btn-sm"
                  @click="savePaymentMethod"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <button
          style="display: none"
          type="button"
          id="show-sms-error-method-modal"
          data-bs-toggle="modal"
          data-bs-target="#modalSmsError"
        ></button>
        <div class="modal fade" id="modalSmsError" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Error Message</h5>
              </div>
              <div class="modal-body">
                <div style="margin-top: 0px;color: red;margin-bottom: 30px;">
                  You SMS quota has reached/is reaching the limit, please click below link to purchase credits.
                </div>
                <a id="nav_item_2" class="" href="/sms-setup" style="margin-top: 30px; color: blue; text-decoration: underline;">
                    SMS Credit - Current Balance: {{ getSmsBalanceByAdminMerchant }} msg
                </a>
              </div>
              <!-- Modal content-->
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary btn-close-modal btn-sm"
                  data-bs-dismiss="modal"
                  @click="closeSettingNotification"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirm
        message="Are you sure you want to delete this record?"
        :process-yes="handleRemovePaidMembershipRewardByMerchant"
      />
    </div>
  </div>
</template>
<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import "bootstrap/js/dist/modal";
import SettingGeneralService from "../../services/settingGeneral.service";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import Multiselect from "@vueform/multiselect";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import "@vueform/multiselect/themes/default.css";
import ColorInput from "vue-color-input";
import MaterialToggleButton from "@/components/MaterialToggleButton.vue";
import $ from "jquery";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import PaymentMethodService from "../../services/paymentMethod.service";
import TokenService from "../../services/token.service";
import Pagination from "@/components/Pagination.vue";
import PosStaff from "../setting/staff/index.vue";
import MemberPreference from "../setting/memberpreference/index.vue";
import MemberProfiles from "../setting/memberprofiles/index.vue";
import ModalConfirm from "@/components/ModalConfirm.vue";
import EventBus from "vue3-eventbus";

export default {
  name: "Voucher",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    Multiselect,
    MaterialTextarea,
    ColorInput,
    MaterialToggleButton,
    MaterialInput,
    MaterialCheckbox,
    MaterialRadio,
    Pagination,
    PosStaff,
    MemberPreference,
    MemberProfiles,
    ModalConfirm
  },
  data() {
    return {
      isMerchantInfo: false,
      isMerchantAppearance: false,
      isOptions: false,
      isMemberSpecialRewards: false,
      isSmsSetup: false,
      isNotificationSetup: false,
      isPaymentMethod: false,
      isPosStaff: false,
      isMemberPreference: false,
      isMemberProfiles: false,
      type: 1,
      form: {
        contact_info: "",
        contact_email: "",
        free_text: "",
        bg_color: "#194D33A8",
        label_color: "#194D33A8",
        button_color: "#194D33A8",
        button_text_color: "#194D33A8",
        allow_setup_membership_tiering: true,
        allow_show_membership_spending: true,
        allow_show_lifetime_earn_cashback: true,
        allow_show_redeemed_cashback: true,
        otp_test_mode: true,
        cashback_by_subtotal: true,
        api_key: '',
        reward_per_transaction: 0,
        profile_sharing_reward: 0,
        friend_inviting_reward: 0,
        merchant_logo: "",
        merchant_old_logo: "",
        contentSmsSetupForgetPasscode: '',
        allow_reset_passcode_to_birthday: 0
      },
      message: "",
      snackbar: null,
      loaderActive: false,
      fieldRequired: {},
      urlImagePreview: null,
      image: null,
      messageImageError: "",
      isErrorUploadImage: false,
      isImageTooSmall: false,
      isLoadDoneData: false,
      merchantCode: "",
      isActive: false,
      isActiveShowSpending: false,
      isActiveShowLifetimeEarnCashback: false,
      isActiveShowRedeemedCashback: false,
      isActiveOtpTestMode: false,
      isActiveCashbackBySubtotal: false,
      characterSmsForgotPasswordLimit: 160,
      dataSettingNofifications: null,
      currentItemAddOrUpdate: null,
      currentTypeAddOrUpdate: null,
      currentValueSettingNotifications: '',
      fields: [
        {
          "name": "Member Name",
          "id": 1
        },
        {
          "name": "Brand",
          "id": 2
        },
        {
          "name": "Outlet",
          "id": 3
        },
        {
          "name": "Topup Amt",
          "id": 4
        },
        {
          "name": "Deducted Amt",
          "id": 5
        },
        {
          "name": "Wallet Bal",
          "id": 6
        },
        {
          "name": "Cashback Earned",
          "id": 7
        },
        {
          "name": "Bill Total",
          "id": 8
        }
      ],
      currently_position_textarea: 0,
      //payment method
      page: 1,
      paymentMethods: [],
      dataPaymentMethod: {
        id: 0,
        name: "",
        display_order: 0,
        status: 0,
      },
      pagination: {},
      methodId: null,
      isLoadData: false,
      labelGroup: "ADD NEW PAYMENT METHOD",
      textRequirePaymentMethodNameCustom: "",
      textRequireDisplayOrderCustom: "",
      action_export: "csv",
      actionDownload: process.env.VUE_APP_BASE_URL,
      token: TokenService.getLocalAccessToken(),
      merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
      merchantCodeExport: localStorage.getItem("MERCHANT_CODE", ""),
      getSmsBalanceByAdminMerchant: 0,
      idRewardAddPaidMembershipReward: 0,
      listPaidMembershipRewardByMerchant: [],
      idToDeletePaidMembershipRewardByMerchant: 0,
      reward_per_transaction_before: 0,
    };
  },
  created() {
    this.changeTab(this.type);
    this.getGeneralSettings();
    this.getListPaidMembershipRewardByMerchant();
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
  },
  methods: {
    exportFile(type = "csv") {
      this.action_export = type;
      setTimeout(() => {
        $("#downloadForm").click();
      }, 150);
    },
    triggerEvent(value) {
      if (typeof value == "boolean") {
        this.isActive = !this.isActive;
        this.form.allow_setup_membership_tiering = this.isActive ? 1 : 0;
      }
    },
    triggerEventShowSpending(value) {
      if (typeof value == "boolean") {
        this.isActiveShowSpending = !this.isActiveShowSpending;
        this.form.allow_show_membership_spending = this.isActiveShowSpending ? 1 : 0;
      }
    },
    triggerEventShowLifetimeEarnCashback(value) {
      if (typeof value == "boolean") {
        this.isActiveShowLifetimeEarnCashback = !this.isActiveShowLifetimeEarnCashback;
        this.form.allow_show_lifetime_earn_cashback = this.isActiveShowLifetimeEarnCashback ? 1 : 0;
      }
    },
    triggerEventShowRedeemedCashback(value) {
      if (typeof value == "boolean") {
        this.isActiveShowRedeemedCashback = !this.isActiveShowRedeemedCashback;
        this.form.allow_show_redeemed_cashback = this.isActiveShowRedeemedCashback ? 1 : 0;
      }
    },
    triggerEventOtpTestMode(value) {
      if (typeof value == "boolean") {
        this.isActiveOtpTestMode = !this.isActiveOtpTestMode;
        this.form.otp_test_mode = this.isActiveOtpTestMode ? 1 : 0;
      }
    },
    triggerEventCashbackBySubtotal(value) {
      if (typeof value == "boolean") {
        this.isActiveCashbackBySubtotal = !this.isActiveCashbackBySubtotal;
        this.form.cashback_by_subtotal = this.isActiveCashbackBySubtotal ? 1 : 0;
      }
    },
    handleFileUpload(event) {
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        if (
          !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
            file.type
          )
        ) {
          alert('File must be an image.');
        } else {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (evt) => {
            let img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              if (width != 480 || height != 480) {
                alert('Please upload and image with size 480x480');
              } else if (file.size / 1024 / 1024 > 5) {
                alert('Uploaded image must be under 5M.');
              } else {
                this.form.merchant_logo = file;
                this.urlImagePreview = URL.createObjectURL(this.form.merchant_logo);
                this.isErrorUploadImage = false;
              }
            };
            img.src = evt.target.result;
          };
        }
      } else {
        alert('File must be an image.');
      }
      $("#image1")[0].value = '';
    },

    clearImage() {
      this.form.merchant_logo = "";
      this.urlImagePreview = null;
      this.fieldRequired.isImageRequire = false;
      this.isErrorUploadImage = false;
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    resetTabs() {
      this.isMerchantInfo = false;
      this.isMerchantAppearance = false;
      this.isOptions = false;
      this.isMemberSpecialRewards = false;
      this.isSmsSetup = false;
      this.isNotificationSetup = false;
      this.isPaymentMethod = false;
      this.isPosStaff = false;
      this.isMemberPreference = false;
      this.isMemberProfiles = false;
    },

    changeTab(type) {
      this.resetTabs();
      $("#general-tab .nav-link").removeClass("active");
      $("#nav_item_" + type).addClass("active");
      if (type == 1) {
        this.isMerchantInfo = true;
      } else if (type == 2) {
        this.isMerchantAppearance = true;
      } else if (type == 3) {
        this.isOptions = true;
      } else if (type == 4) {
        this.isMemberSpecialRewards = true;
      } else if (type == 5) {
        this.isSmsSetup = true;
      } else if (type == 6) {
        this.isNotificationSetup = true;
      } else if (type == 7) {
        this.isPaymentMethod = true;
        if (this.$route.query.page) {
          this.page = this.$route.query.page;
        }
        this.getList(this.page);
      } else if (type == 8) {
        this.isPosStaff = true
      } else if (type == 9) {
        this.isMemberPreference = true
      } else if (type == 10) {
        this.isMemberProfiles = true
      }
    },

    getGeneralSettings(isLoadDoneData=false) {
      this.form = {};
      if(isLoadDoneData === false) {
        this.isLoadDoneData = false;
      }
      this.showLoader();
      SettingGeneralService.getDataConfigs().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.rewards = response.data.data.rewards;
            var dataConfig = response.data.data.data_merchant_setting;
            this.form.contact_info = dataConfig.contact_info ? dataConfig.contact_info : ""
            this.form.contact_email = dataConfig.contact_email ? dataConfig.contact_email : ""
            this.form.free_text = dataConfig.free_text ? dataConfig.free_text : ""
            this.form.bg_color = dataConfig.bg_color ? dataConfig.bg_color : ""
            this.form.label_color = dataConfig.label_color ? dataConfig.label_color : ""
            this.form.button_color = dataConfig.button_color ? dataConfig.button_color : ""
            this.form.button_text_color = dataConfig.button_text_color ? dataConfig.button_text_color : ""
            this.form.allow_setup_membership_tiering = dataConfig.allow_setup_membership_tiering
            this.isActive = dataConfig.allow_setup_membership_tiering == 1 ? true : false
            this.form.allow_show_membership_spending = dataConfig.allow_show_membership_spending
            this.isActiveShowSpending = dataConfig.allow_show_membership_spending == 1 ? true : false
            this.form.allow_show_lifetime_earn_cashback = dataConfig.allow_show_lifetime_earn_cashback
            this.isActiveShowLifetimeEarnCashback = dataConfig.allow_show_lifetime_earn_cashback == 1 ? true : false
            this.form.allow_show_redeemed_cashback = dataConfig.allow_show_redeemed_cashback
            this.isActiveShowRedeemedCashback = dataConfig.allow_show_redeemed_cashback == 1 ? true : false
            this.form.otp_test_mode = (dataConfig.otp_test_mode == undefined) ? 1 : dataConfig.otp_test_mode
            this.isActiveOtpTestMode = dataConfig.otp_test_mode == 1 || dataConfig.otp_test_mode == undefined ? true : false
            this.form.cashback_by_subtotal = (dataConfig.cashback_by_subtotal == undefined) ? 1 : dataConfig.cashback_by_subtotal
            this.isActiveCashbackBySubtotal = dataConfig.cashback_by_subtotal == 1 || dataConfig.cashback_by_subtotal == undefined ? true : false
            this.form.api_key = dataConfig.api_key ? dataConfig.api_key : ""
            this.form.reward_per_transaction = dataConfig.reward_per_transaction ? dataConfig.reward_per_transaction : 0
            this.reward_per_transaction_before = dataConfig.reward_per_transaction ? dataConfig.reward_per_transaction : 0
            this.form.profile_sharing_reward = dataConfig.profile_sharing_reward ? dataConfig.profile_sharing_reward : 0
            this.form.friend_inviting_reward = dataConfig.friend_inviting_reward ? dataConfig.friend_inviting_reward : 0
            this.form.merchant_logo = dataConfig.merchant_logo ? dataConfig.merchant_logo : ""
            this.form.merchant_old_logo = dataConfig.merchant_old_logo ? dataConfig.merchant_old_logo : ""
            this.form.contentSmsSetupForgetPasscode = dataConfig.contentSmsSetupForgetPasscode ? dataConfig.contentSmsSetupForgetPasscode : ""
            this.dataSettingNofifications = response.data.data.data_setting_notifications;
            this.getSmsBalanceByAdminMerchant = response.data.data.getSmsBalanceByAdminMerchant;
            this.form.allow_reset_passcode_to_birthday = dataConfig.allow_reset_passcode_to_birthday == 1 ? true : false;
            this.isLoadDoneData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    saveDataConfigs() {
      if (this.reward_per_transaction_before != this.form.reward_per_transaction) {
        if(!confirm("Do you really want to change setting reward per transaction?")){
          return;
        }
      }

      this.showLoader();
      if (!this.isErrorUploadImage) {
        SettingGeneralService.addDataConfigs(this.form).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message = "Save config for merchant success";
              this.setTimeOutNotification();

              if (this.reward_per_transaction_before != this.form.reward_per_transaction) {
                EventBus.emit("ReloadSidenav");
              }
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      } else {
        this.hideLoader();
      }
    },

    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    uploadFile() {
      $("#image1").click();
    },
    limitChar() {
      this.form.contentSmsSetupForgetPasscode = this.form.contentSmsSetupForgetPasscode.slice(0, 160);
    },
    showActionGroup() {
      const editGroup = document.getElementById("action-group");
      editGroup.click();
    },
    addSettingMerchantNotification(item, type, isChecked) {
      console.log(item);
      this.currentItemAddOrUpdate = item;
      this.currentTypeAddOrUpdate = type;
      var valueDefaultTopup = '$<Topup Amt> added to your <Brand> account. New Bal: $<Wallet Bal>';
      var valueDefaultDeduction = '$<Deducted Amt> deducted from <Brand> account. New Bal: $<Wallet Bal>';
      var valueDefaultEarn = 'You earned $<Cashback Earned> at <Brand> <Outlet>';
      if(isChecked) {
        if(this.getSmsBalanceByAdminMerchant > 0) {
          if(item.type == 'Topup') { // topup
            if(type == 1) { // in-app
              if(item.content_in_app === null) {
                this.currentValueSettingNotifications = valueDefaultTopup;
              } else {
                this.currentValueSettingNotifications = item.content_in_app;
              }
              this.showActionGroup();
            }
            if(type == 2) { // email
              if(item.content_email === null) {
                this.currentValueSettingNotifications = valueDefaultTopup;
              } else {
                this.currentValueSettingNotifications = item.content_email;
              }
              this.saveSettingNotification(false);
            }
            if(type == 3) { // sms
              if(item.content_sms === null) {
                this.currentValueSettingNotifications = valueDefaultTopup;
              } else {
                this.currentValueSettingNotifications = item.content_sms;
              }
              this.showActionGroup();
            }
          } else if(item.type == 'Deduction From Wallet') { // Deduction From Wallet
            if(type == 1) { // in-app
              if(item.content_in_app === null) {
                this.currentValueSettingNotifications = valueDefaultDeduction;
              } else {
                this.currentValueSettingNotifications = item.content_in_app;
              }
              this.showActionGroup();
            }
            if(type == 2) { // email
              if(item.content_email === null) {
                this.currentValueSettingNotifications = valueDefaultDeduction;
              } else {
                this.currentValueSettingNotifications = item.content_email;
              }
              this.saveSettingNotification(false);
            }
            if(type == 3) { // sms
              if(item.content_sms === null) {
                this.currentValueSettingNotifications = valueDefaultDeduction;
              } else {
                this.currentValueSettingNotifications = item.content_sms;
              }
              this.showActionGroup();
            }
          } else if(item.type == 'Earn Cashback'){ // Earn Cashback
            if(type == 1) { // in-app
              if(item.content_in_app === null) {
                this.currentValueSettingNotifications = valueDefaultEarn;
              } else {
                this.currentValueSettingNotifications = item.content_in_app;
              }
              this.showActionGroup();
            }
            if(type == 2) { // email
              if(item.content_email === null) {
                this.currentValueSettingNotifications = valueDefaultEarn;
              } else {
                this.currentValueSettingNotifications = item.content_email;
              }
              this.saveSettingNotification(false);
            }
            if(type == 3) { // sms
              if(item.content_sms === null) {
                this.currentValueSettingNotifications = valueDefaultEarn;
              } else {
                this.currentValueSettingNotifications = item.content_sms;
              }
              this.showActionGroup();
            }
          } else if(item.type == 'Member Get Birthday Reward'){ // Member Get Birthday Reward
            if(type == 1) { // in-app
              if(item.content_in_app === null) {
                this.currentValueSettingNotifications = "";
              } else {
                this.currentValueSettingNotifications = item.content_in_app;
              }
              this.showActionGroup();
            }
            if(type == 2) { // email
              if(item.content_email === null) {
                this.currentValueSettingNotifications = "HAPPY BIRTHDAY!\nIt’s your day and we’d love to celebrate with you! \nEnjoy <Voucher or Discount Name> for your next purchase!\n<REDEEM NOW>";
              } else {
                this.currentValueSettingNotifications = item.content_email;
              }
              // this.showActionGroup();
              this.saveSettingNotification(false);
            }
            if(type == 3) { // sms
              if(item.content_sms === null) {
                this.currentValueSettingNotifications = "HAPPY BIRTHDAY, <Member Name>! Enjoy your big day with <Merchant Name>'s <Voucher or Discount Name> for your next purchase! Click here <Link> to redeem.";
              } else {
                this.currentValueSettingNotifications = item.content_sms;
              }
              this.showActionGroup();
            }
          } else if(item.type == 'Member Get Profile Update Reward'){ // Member Get Birthday Reward && Member Get Profile Update Reward
            if(type == 1) { // in-app
              if(item.content_in_app === null) {
                this.currentValueSettingNotifications = "";
              } else {
                this.currentValueSettingNotifications = item.content_in_app;
              }
              this.showActionGroup();
            }
            if(type == 2) { // email
              if(item.content_email === null) {
                this.currentValueSettingNotifications = "CONGRATULATION,  <Member Name>!\nYou’ve earned a <Merchant Name>'s <Voucher or Discount Name> for profile completed  successfully.\n<REDEEM NOW>";
              } else {
                this.currentValueSettingNotifications = item.content_email;
              }
              // this.showActionGroup();
              this.saveSettingNotification(false);
            }
            if(type == 3) { // sms
              if(item.content_sms === null) {
                this.currentValueSettingNotifications = "CONGRATULATION, <Member Name>! You’ve earned a <Merchant Name>'s <Voucher or Discount Name> for successfully complete your profile. Click here <Link> to redeem.";
              } else {
                this.currentValueSettingNotifications = item.content_sms;
              }
              this.showActionGroup();
            }
          }
          // const editGroup = document.getElementById("action-group");
          // editGroup.click();
        } else {
          const modalSmsError = document.getElementById("show-sms-error-method-modal");
          modalSmsError.click();
        }
      } else {
        this.saveSettingNotification(true);
      }
    },
    saveSettingNotification(isEmpty=false) {
      this.showLoader();

      var data = {
          "type": this.currentItemAddOrUpdate.type.toLowerCase().replace(/\s+/g, '_')
      }

      if(this.currentTypeAddOrUpdate == 1) { // is_enable_in_app
        data.is_enable_in_app = (isEmpty === false ? true : false);
        data.content_in_app = (isEmpty === false ? this.currentValueSettingNotifications : "");
      }

      if(this.currentTypeAddOrUpdate == 2) { // is_enable_email
        data.is_enable_email = (isEmpty === false ? true : false);
        data.content_email = (isEmpty === false ? this.currentValueSettingNotifications : "");
      }

      if(this.currentTypeAddOrUpdate == 3) { // is_enable_sms
        data.is_enable_sms = (isEmpty === false ? true : false);
        data.content_sms = (isEmpty === false ? this.currentValueSettingNotifications : "");
      }

      SettingGeneralService.addSettingMerchantNotifications(data).then(
        (response) => {
          if (response.data.result.isSuccess) {
            $(".btn-close-modal").click();
            this.getGeneralSettings(true);
            this.snackbar = "success";
            this.message = "Save config for merchant success";
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    closeSettingNotification() {
      this.getGeneralSettings(true);
      $(".btn-close-modal").click();
    },
    applyTagsToTextArea(name) {
      const insertText = "<"+name+">";
      if (!insertText.length) return

      const textarea = this.$refs.textareaSettingNotifications.$el.querySelector('textarea')
      const sentence = textarea.value
      const len = sentence.length
      let pos = this.currently_position_textarea
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.currentValueSettingNotifications = before + insertText + after

      this.$nextTick().then(() => {
        textarea.selectionStart = pos + insertText.length
      })
    },
    getCursorPosition() {
      const textarea = this.$refs.textareaSettingNotifications.$el.querySelector('textarea')
      let pos = textarea.selectionStart
      this.currently_position_textarea = pos;
    },

    //payment method
    getList(page) {
      this.isLoadData = false;
      this.topUps = [];
      var dataForm = {
        page: page,
      };
      PaymentMethodService.getList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.paymentMethods = response.data.data.items;
            this.pagination = response.data.data;
            this.page = this.pagination.current_page;
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    addPaymentMethod() {
      this.labelGroup = "ADD NEW PAYMENT METHOD";
      this.dataPaymentMethod.id = 0;
      this.dataPaymentMethod.name = "";
      this.dataPaymentMethod.display_order = 0;
      this.dataPaymentMethod.status = 0;
      const modalAdd = document.getElementById("show-payment-method-modal");
      modalAdd.click();
      this.resetRequired();
    },

    resetRequired() {
      this.fieldRequired.isImageRequire = false;
      this.fieldRequired.isPaymentMethodNameRequire = false;
      this.fieldRequired.isDisplayOrderRequire = false;
    },

    processValidatePaymentMethod() {
      this.resetRequired();
      var isValid = true;
      if (
        this.dataPaymentMethod.name == "" ||
        this.dataPaymentMethod.name == null
      ) {
        this.fieldRequired.isPaymentMethodNameRequire = true;
        this.textRequirePaymentMethodNameCustom = "The field is required.";
        isValid = false;
      }

      if (
        this.dataPaymentMethod.display_order == "" ||
        this.dataPaymentMethod.display_order == null
      ) {
        this.fieldRequired.isDisplayOrderRequire = true;
        this.textRequireDisplayOrderCustom = "The field is required.";
        isValid = false;
      } else if (
        this.dataPaymentMethod.display_order &&
        !this.isPositiveInteger(this.dataPaymentMethod.display_order)
      ) {
        this.fieldRequired.isDisplayOrderRequire = true;
        this.textRequireDisplayOrderCustom = "You must enter a positive.";
        isValid = false;
      } else if (
        this.dataPaymentMethod.display_order &&
        this.dataPaymentMethod.display_order.length > 7
      ) {
        this.fieldRequired.isDisplayOrderRequire = true;
        this.textRequireDisplayOrderCustom =
          "You can only enter up to 7 numeric characters.";
        isValid = false;
      }

      return isValid;
    },

    isPositiveInteger(str) {
      return parseFloat(str) > 0;
    },
    
    confirmDelete(methodId) {
      this.methodId = methodId;
      $(".btn-confirm-modal").click();
    },

    savePaymentMethod() {
      if (this.processValidatePaymentMethod()) {
        if (!this.dataPaymentMethod.id) {
          this.showLoader();
          PaymentMethodService.store(this.dataPaymentMethod).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Save Payment Method Success";
                this.setTimeOutNotification();
                $(".btn-close-modal").click();
                this.page = 1
                this.getList(this.page);
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        } else {
          PaymentMethodService.update(this.dataPaymentMethod).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Update Top Up Success";
                this.setTimeOutNotification();
                $(".btn-close-modal").click();
                this.getList(this.page);
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        }
      }
    },

    async editPaymentMethod(methodId) {
      this.resetRequired()
      this.showLoader();
      await PaymentMethodService.detail(methodId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            const paymentMethod = response.data.data;
            this.dataPaymentMethod.id = paymentMethod.id ?? 0;
            this.dataPaymentMethod.name = paymentMethod.name ?? '';
            this.dataPaymentMethod.display_order = paymentMethod.display_order ?? '';
            this.dataPaymentMethod.status = paymentMethod.status ?? 0;
            this.labelGroup = `EDIT PAYMENT METHOD`;
            const editPaymentMethod = document.getElementById("show-payment-method-modal");
            editPaymentMethod.click();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    addPaidMembershipReward() {
      if(this.idRewardAddPaidMembershipReward > 0) {
        var dataForm = {
          reward_id: this.idRewardAddPaidMembershipReward,
        };
        SettingGeneralService.addPaidMembershipRewardsByMerchant(dataForm).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Added Successfully";
                this.setTimeOutNotification();
                this.getListPaidMembershipRewardByMerchant();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
      } else {
        this.snackbar = "danger";
        this.message = "Please select Paid Membership Reward";
        this.setTimeOutNotification();
      }
    },

    getListPaidMembershipRewardByMerchant() {
      this.isLoadData = false;
      this.listPaidMembershipRewardByMerchant = [];
      SettingGeneralService.listPaidMembershipRewardsByMerchant().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.listPaidMembershipRewardByMerchant = response.data.data;
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    removePaidMembershipRewardByMerchant(id) {
      this.idToDeletePaidMembershipRewardByMerchant = id;
      $(".btn-confirm-modal").click();
    },

    handleRemovePaidMembershipRewardByMerchant() {
      if(this.idToDeletePaidMembershipRewardByMerchant > 0) {
        SettingGeneralService.deletePaidMembershipRewardsByMerchant(this.idToDeletePaidMembershipRewardByMerchant).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Deleted Successfully";
                this.setTimeOutNotification();
                this.getListPaidMembershipRewardByMerchant();
                $(".btn-close-modal").click();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
      } else {
        this.snackbar = "danger";
        this.message = "Not found!";
        this.setTimeOutNotification();
      }
    },
    allowResetPasscodeToBirthday(allow_reset_passcode_to_birthday) {
      if (typeof allow_reset_passcode_to_birthday == "boolean") {
        this.form.allow_reset_passcode_to_birthday = allow_reset_passcode_to_birthday ? 1 : 0;
      }
    }
  },
};
</script>
<style scoped>
.c-container {
  width: 100%;
  max-width: 100%;
  padding-left: 25px;
  padding-right: 25px;
}
.text-back {
  color: black !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.tab-list {
  overflow-x: auto;
  padding-bottom: 5px !important;
}

.tab-list::-webkit-scrollbar {
  height: 7px;
}
.tab-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.tab-list::-webkit-scrollbar-thumb {
  background: #888;
}
.tab-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tab-list #general-tab {
  flex-wrap: unset !important;
}

.tab-list #general-tab li.nav-item  {
  min-width: fit-content;
}

#preview img {
  max-width: 80px;
  max-height: 80px;
}
@media only screen and (max-width: 600px) {
  .wrap-card .card-item:last-child {
    margin-bottom: 80px !important;
  }

  .toggle-switch-custom {
    padding-top: 5px;
  }
}
</style>