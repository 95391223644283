import api from './api';

class SettingTopUpService {
  getList(dataForm) {
    return api.get('/merchant/top-up', {params: dataForm});
  }

  detail(topUpId) {
    return api.get('/merchant/top-up/' + topUpId);
  }

  store(dataForm) {
    return api.post('/merchant/top-up', dataForm);
  }

  update(dataForm) {
    return api.put('/merchant/top-up/' + dataForm.id, dataForm);
  }

  delete(topUpId) {
    return api.delete('merchant/top-up/' + topUpId);
  }

  saveTermsConditions(dataForm) {
    return api.post('merchant/top-up/add-terms-conditions', dataForm);
  }
}

export default new SettingTopUpService();
