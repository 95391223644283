<template>
    <div>
        <div class="row mb-2 pe-4">
            <div class="col-lg-12 col-md-12 col-12 d-flex align-items-center">
            <div class="color-common">
                <h3 class="title-page mb-0 ps-3">DASHBOARD AND REPORTS</h3>
            </div>
            </div>
        </div>
        <div class="page-header align-items-start min-vh-100 my-transacions">
            <span class="mask bg-white"></span>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12 mx-auto mt-4">
                    <div class="row">
                        <strong>
                        ORDER ITEM REPORT
                        </strong>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-4 col-md-12 col-12">
                            <div class="row">
                                <div
                                class="col-lg-3 col-md-3 col-3 d-flex justify-content-end align-items-center"
                                >
                                    Time
                                </div>
                                <div class="col-lg-8 col-md-6 col-9">
                                <Multiselect
                                    v-model="time_type"
                                    :options="times"
                                    :canClear="false"
                                    @change="timeChange($event)"
                                />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 col-12">
                            <div class="row">
                                <div
                                class="col-lg-3 col-md-3 col-3 d-flex justify-content-end align-items-center"
                                >
                                    Branch
                                </div>
                                <div class="col-lg-8 col-md-6 col-9">
                                    <Multiselect
                                        v-model="branch_id"
                                        :options="branchs"
                                        :canClear="false"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3" v-if="isShowTimeCustom">
                        <div class="col-lg-4 col-md-12 col-12">
                            <div class="row">
                            <div
                                class="col-lg-3 col-md-3 col-3 d-flex justify-content-end align-items-center"
                            >
                                From
                            </div>
                            <div class="col-lg-8 col-md-6 col-9">
                                <Datepicker
                                :enableTimePicker="false"
                                class="date-time-dashboad"
                                v-model="from_date"
                                />
                            </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 col-12 pt-custtom">
                            <div class="row">
                            <div
                                class="col-lg-3 col-md-3 col-3 d-flex justify-content-end align-items-center"
                            >
                                To
                            </div>
                            <div class="col-lg-8 col-md-6 col-9">
                                <Datepicker
                                :enableTimePicker="false"
                                class="date-time-dashboad"
                                v-model="to_date"
                                />
                            </div>
                            </div>
                        </div>
                        </div>
                    <div class="row mt-3">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="row">
                                <div
                                class="col-lg-1 col-md-3 col-3 d-flex justify-content-end align-items-center"
                                >
                                Search
                                </div>
                                <div class="col-lg-8 col-md-6 col-9">
                                <input
                                    class="nosubmit"
                                    type="search"
                                    id="keyword_search"
                                    v-model="searchKeyword"
                                    extraClass
                                    name="keyword_search"
                                    placeholder="Search by item name, group, receipt no"
                                    @keydown.enter="handleSearchEnter"
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4 ps-3">
                        <div class="col-lg-9 col-md-9 col-3 d-flex">
                            <div class="dropdown">
                                <button
                                class="btn btn-secondary dropdown-toggle btn-sm"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                aria-haspopup="true"
                                :disabled="disabled"
                                >
                                Download
                                </button>
                                <ul
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                                >
                                <li>
                                    <a
                                    class="dropdown-item"
                                    @click="exportFile('csv')"
                                    href="#"
                                    >
                                    Export CSV
                                    </a>
                                </li>
                                <li>
                                    <a
                                    class="dropdown-item"
                                    @click="exportFile('xlsx')"
                                    href="#"
                                    >
                                    Export Excel
                                    </a>
                                </li>
                                <li>
                                    <a
                                    class="dropdown-item"
                                    @click="exportFile('pdf')"
                                    href="#"
                                    >Export PDF</a
                                    >
                                </li>
                                </ul>
                            </div>
                            <div style="margin-left: 3vh;">
                                <material-button
                                class="btn-background btn-sm"
                                @click="filterData()"
                                style="width: 162.52px"
                                >
                                Run
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1 ps-3" v-if="isLoadData">
                        <div class="col-lg-9 col-md-12 col-12 d-flex justify-content-lg-start align-items-end">
                            Count: {{ totalRecords }}
                        </div>
                        <div class="col-lg-3 col-md-3 col-3 d-flex justify-content-end">
                        <ShowHideColumn
                            :columns="columns"
                            :changeCheckbox="changeCheckbox"
                            :columnCheckedCache="columnChecked"
                            :key="key"
                        />
                        </div>
                    </div>
                    <div class="row mt-4" v-if="isLoadData" style="min-height: 1120px;">
                        <div class="col-12">
                        <div class="card-custom">
                            <div class="px-0 pb-2">
                            <div class="table-responsive p-0 custom-table">
                                <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                    <th
                                        class="text-center font-weight-bolder w-10"
                                        v-if="columnShowed.includes('branch')"
                                    >
                                        Branch
                                    </th>
                                    <th
                                        class="text-center font-weight-bolder w-5"
                                        v-if="columnShowed.includes('receipt_no')"
                                    >
                                        Receipt No
                                    </th>
                                    <!-- <th
                                        class="text-center font-weight-bolder w-5"
                                        v-if="columnShowed.includes('date')"
                                    >
                                        Date
                                    </th> -->
                                    <th
                                        class="text-center font-weight-bolder w-10"
                                        v-if="columnShowed.includes('item')"
                                    >
                                        Item
                                    </th>
                                    <th
                                        class="text-center font-weight-bolder w-5"
                                        v-if="columnShowed.includes('type')"
                                    >
                                        Type
                                    </th>
                                    <th
                                        class="text-center font-weight-bolder w-5"
                                        v-if="columnShowed.includes('group')"
                                    >
                                        Group
                                    </th>
                                    <th
                                        class="text-center font-weight-bolder w-5"
                                        v-if="columnShowed.includes('quantity')"
                                    >
                                        Quantity
                                    </th>
                                    <th
                                        class="text-center font-weight-bolder w-5"
                                        v-if="columnShowed.includes('selling_price')"
                                    >
                                        Selling Price
                                    </th>
                                    <!-- <th
                                        class="text-center font-weight-bolder w-10"
                                        v-if="columnShowed.includes('price')"
                                    >
                                        Price
                                    </th> -->
                                    <!-- <th
                                        class="text-center font-weight-bolder w-5"
                                        v-if="columnShowed.includes('pkg_rdp')"
                                    >
                                        PKG RDP
                                    </th> -->
                                    <!-- <th
                                        class="text-center font-weight-bolder w-5"
                                        v-if="columnShowed.includes('subtotal')"
                                    >
                                        Subtotal
                                    </th> -->
                                    <!-- <th
                                        class="text-center font-weight-bolder w-5"
                                        v-if="columnShowed.includes('bill_discount')"
                                    >
                                        Bill Discount
                                    </th> -->
                                    <!-- <th
                                        class="text-center font-weight-bolder w-5"
                                        v-if="columnShowed.includes('gst')"
                                    >
                                        GST
                                    </th> -->
                                    <!-- <th
                                        class="text-center font-weight-bolder w-5"
                                        v-if="columnShowed.includes('total')"
                                    >
                                        Total
                                    </th> -->
                                    <!-- <th
                                        class="text-center font-weight-bolder w-5"
                                        v-if="columnShowed.includes('subtotal_w_o_gst')"
                                    >
                                        Subtotal w/o GST
                                    </th> -->
                                    </tr>
                                </thead>
                                <tbody v-if="dataResult.length">
                                    <tr
                                    v-for="(value, key) in dataResult"
                                    :key="key"
                                    >
                                    <td
                                        class="text-center text-xss w-10"
                                        v-if="columnShowed.includes('branch')"
                                    >
                                        {{ value.branch_name }}
                                    </td>
                                    <td
                                        class="text-center text-xss w-5"
                                        v-if="columnShowed.includes('receipt_no')"
                                    >
                                        {{ value.receipt_no }}
                                    </td>
                                    <!-- <td
                                        class="text-center text-xss w-5"
                                        v-if="columnShowed.includes('date')"
                                    >
                                        
                                    </td> -->
                                    <td
                                        class="text-left text-xss w-10"
                                        v-if="columnShowed.includes('item')"
                                    >
                                        {{ value.item_name }}
                                    </td>
                                    <td
                                        class="text-center text-xss w-5"
                                        v-if="columnShowed.includes('type')"
                                    >
                                        {{ findLabelById(value.item_type) }}
                                    </td>
                                    <td
                                        class="text-center text-xss w-5"
                                        v-if="columnShowed.includes('group')"
                                    >
                                        {{ value.group_name }}
                                    </td>
                                    <td
                                        class="text-center text-xss w-5"
                                        v-if="columnShowed.includes('quantity')"
                                    >
                                        {{ value.quantity }}
                                    </td>
                                    <td
                                        class="text-center text-xss w-5"
                                        v-if="columnShowed.includes('selling_price')"
                                    >
                                        {{ value.price_format }}
                                    </td>
                                    <!-- <td
                                        class="text-center text-xss w-10"
                                        v-if="columnShowed.includes('price')"
                                    >
                                        
                                    </td>
                                    <td
                                        class="text-center text-xss w-10"
                                        v-if="columnShowed.includes('pkg_rdp')"
                                    >
                                        
                                    </td>
                                    <td
                                        class="text-center text-xss w-10"
                                        v-if="columnShowed.includes('subtotal')"
                                    >
                                        
                                    </td>
                                    <td
                                        class="text-center text-xss w-10"
                                        v-if="columnShowed.includes('bill_discount')"
                                    >
                                        {{ value.discount_format }}
                                    </td>
                                    <td
                                        class="text-center text-xss w-10"
                                        v-if="columnShowed.includes('gst')"
                                    >
                                        
                                    </td>
                                    <td
                                        class="text-center text-xss w-10"
                                        v-if="columnShowed.includes('total')"
                                    >
                                        
                                    </td>
                                    <td
                                        class="text-center text-xss w-10"
                                        v-if="columnShowed.includes('subtotal_w_o_gst')"
                                    >
                                        
                                    </td> -->
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                    <td colspan="29" class="text-center">
                                        No item available
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            </div>
                            <pagination
                                v-if="pagination.last_page > 1"
                                :pagination="pagination"
                                :current_page="pagination.current_page"
                                :last_page="pagination.last_page"
                            />
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div class="position-fixed top-1 end-1 z-index-2 message-success">
                <material-snackbar
                    v-if="snackbar === 'danger' || snackbar === 'success'"
                    title=""
                    date=""
                    :description="message"
                    :icon="{ component: 'campaign', color: 'white' }"
                    :color="snackbar"
                    :close-handler="closeSnackbar"
                />
            </div>
            <material-loading :active="loaderActive" />
        </div>
        <form
            :action="`${actionDownload}/merchant/download-pos-order-item-report`"
            method="POST"
        >
            <input type="hidden" name="merchantId" :value="merchantIdExport" />
            <input type="hidden" name="merchant_code" :value="merchantCode" />
            <input type="hidden" name="token" :value="token" />
            <input type="hidden" name="list_field_show" :value="columnShowed" />
            <input type="hidden" name="search_keyword" :value="searchKeyword" />
            <input type="hidden" name="time_type" :value="time_type" />
            <input type="hidden" name="from_date" :value="dateFormat(from_date)" />
            <input type="hidden" name="to_date" :value="dateFormat(to_date)" />
            <input type="hidden" name="branch_id" :value="branch_id" />
            <input type="hidden" name="checkExport" :value="checkExport" />
            <input type="hidden" name="action_export" :value="action_export" />
            <input type="hidden" name="action" value="export" />
            <input id="downloadForm" type="submit" class="d-none" />
        </form>
    </div>
</template>
  
<script>
    import MaterialSnackbar from '@/components/MaterialSnackbar.vue'
    import 'bootstrap/dist/js/bootstrap.bundle'
    import MaterialLoading from '@/components/MaterialLoading.vue'
    import Pagination from '@/components/Pagination.vue'
    import 'bootstrap/js/dist/modal'
    import '@vuepic/vue-datepicker/dist/main.css'
    import Multiselect from '@vueform/multiselect'
    import '@vueform/multiselect/themes/default.css'
    import MaterialButton from '@/components/MaterialButton.vue'
    import ShowHideColumn from '@/components/ShowHideColumn.vue'
    import '@vuepic/vue-datepicker/dist/main.css'
    import moment from 'moment'
    import DashboardReportService from '../../services/dashboardReport.service'
    import $ from 'jquery'
    import TokenService from '../../services/token.service'
    import Datepicker from '@vuepic/vue-datepicker'
  
    export default {
        name: 'campaign-list',
        components: {
        MaterialSnackbar,
        MaterialLoading,
        MaterialButton,
        Pagination,
        Multiselect,
        ShowHideColumn,
        Datepicker
        },
        data() {
            return {
                token: TokenService.getLocalAccessToken(),
                actionDownload: process.env.VUE_APP_BASE_URL,
                merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
                merchantCode: localStorage.getItem('MERCHANT_CODE', ''),
                message: '',
                snackbar: null,
                loaderActive: false,
                searchKeyword: '',
                pagination: {},
                page: 1,
                isLoadData: false,
                columns: [
                    { value: 'all', label: 'All' },
                    { value: 'branch', label: 'Branch' },
                    { value: 'receipt_no', label: 'Receipt No' },
                    // { value: 'date', label: 'Date' },
                    { value: 'item', label: 'Item' },
                    { value: 'type', label: 'Type' },
                    { value: 'group', label: 'Group' },
                    { value: 'quantity', label: 'Quantity' },
                    { value: 'selling_price', label: 'Selling Price' },
                    // { value: 'price', label: 'Price' },
                    // { value: 'pkg_rdp', label: 'PKG RDP' },
                    // { value: 'subtotal', label: 'Subtotal' },
                    // { value: 'bill_discount', label: 'Bill Discount' },
                    // { value: 'gst', label: 'GST' },
                    // { value: 'total', label: 'Total' },
                    // { value: 'subtotal_w_o_gst', label: 'Subtotal w/o GST' }
                ],
                time_type: 0,
                times: [
                    { value: 0, label: 'Select Time' },
                    { value: 1, label: 'Today' },
                    { value: 2, label: 'Yesterday' },
                    { value: 3, label: 'Last 7 days' },
                    { value: 4, label: 'Last 30 days' },
                    { value: 5, label: 'This month' },
                    { value: 6, label: 'Last 3 months' },
                    { value: 7, label: 'This year' },
                    { value: 8, label: 'Last year' },
                    { value: 9, label: 'Custom' },
                ],
                isShowTimeCustom: false,
                action: 'list',
                action_export: 'csv',
                totalRecords: 0,
                columnShowed: [],
                branchs: [],
                branch_id: 0,
                columnChecked: [],
                checkExport: false,
                dataResult: [],
                itemTypes: [
                    {
                    label: "Fixed price",
                    value: 1,
                    },
                    {
                    label: "Bundle",
                    value: 2,
                    },
                    {
                    label: "Open Price",
                    value: 3,
                    },
                    {
                    label: "By Weight (per KG)",
                    value: 4,
                    },
                    {
                    label: "Sub Category (Only POS)",
                    value: 5,
                    },
                    {
                    label: "Charge %",
                    value: 6,
                    },
                    {
                    label: "Discount $",
                    value: 7,
                    },
                    {
                    label: "Discount %",
                    value: 8,
                    },
                    {
                    label: "Modifier",
                    value: 9,
                    },
                ],
                from_date: null,
                to_date: null,
            }
        },
        created() {
            this.getDataFilter();
            if (this.$route.query.page) {
                this.page = this.$route.query.page
            }
            if(window.localStorage.getItem("OrderItemReportColumn")){
                this.columnChecked = window.localStorage.getItem("OrderItemReportColumn").split(",");
                this.key = Math.random();
            }
        },
        methods: {
            exportFile(type = 'csv') {
                if (this.columnShowed.length == 0) {
                    this.snackbar = 'danger'
                    this.message = 'You must select least 1 column to export file.'
                    this.setTimeOutNotification();
                } else {
                    this.action_export = type
                    setTimeout(() => {
                        $('#downloadForm').click()
                    }, 150);
                }
            },
            changeCheckbox(columnShowed) {
                this.columnShowed = columnShowed
                window.localStorage.setItem("OrderItemReportColumn", columnShowed)
            },
            timeChange($event) {
                if ($event == 9) {
                this.isShowTimeCustom = true
                } else {
                this.isShowTimeCustom = false
                }
            },
        
            filterData() {
                this.getList(this.page)
            },
        
            dateFormat(date) {
                if (date == null) {
                    return null
                } else {
                    return moment(date).format('YYYY-MM-DD')
                }
            },
        
            closeSnackbar() {
                this.snackbar = null
            },
        
            setTimeOutNotification() {
                setTimeout(() => {
                    this.snackbar = null
                }, 3000)
            },
        
            getList(page) {
                this.isLoadData = false
                this.dataResult = [];
                this.totalRecords = 0
                this.showLoader()
                var dataForm = {
                    action: this.action,
                    branch_id: this.branch_id,
                    search_keyword: this.searchKeyword,
                    time_type: this.time_type,
                    from_date: this.dateFormat(this.from_date),
                    to_date: this.dateFormat(this.to_date),
                    action_export: this.action_export,
                    checkExport: this.checkExport,
                    page: page
                }
                DashboardReportService.getDataPosOrderItemReport(dataForm).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                    this.dataResult = response.data.data.items
                    this.pagination = response.data.data
                    this.page = this.pagination.current_page
                    this.totalRecords = this.pagination.total
                    if (this.pagination.current_page > this.pagination.last_page) {
                        this.page = 1
                        this.getList(this.page)
                    }
                    this.$router.push({
                        query: Object.assign({}, this.$route.query, { page: this.page }),
                    })
                    this.isLoadData = true;
                    this.checkExport = true;
                    } else {
                    this.snackbar = 'danger'
                    this.message = response.data.result.message
                    this.setTimeOutNotification()
                    }
                    this.hideLoader()
                },
                (error) => {
                    this.snackbar = 'danger'
                    this.message = error
                    this.hideLoader()
                    this.setTimeOutNotification()
                },
                )
            },
            showLoader() {
                this.loaderActive = true
            },
            hideLoader() {
                this.loaderActive = false
            },
            getDataFilter() {
                this.isLoadData = false
                this.showLoader()
                DashboardReportService.getFilterDataTopupReport().then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        var DataFilter = response.data.data
                        this.branchs = DataFilter.branches;
                    } else {
                        this.snackbar = 'danger'
                        this.message = response.data.result.message
                        this.setTimeOutNotification()
                    }
                    this.isLoadData = true;
                    this.hideLoader()
                },
                (error) => {
                    this.snackbar = 'danger'
                    this.message = error
                    this.hideLoader()
                    this.setTimeOutNotification()
                },
                )
            },
            handleSearchEnter(){
                this.getList(this.page);
            },
            findLabelById(id) {
                const itemType = this.itemTypes.find((item) => item.value === id);

                return itemType ? itemType.label : '';
            },
        },
  }
  </script>
  <style scoped>
  input[type='search'] {
    border: none;
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
  }
  
  input[type='search']::placeholder {
    color: #bbb;
    text-align: left;
    font-weight: normal;
  }
  
  input[type='search']:focus {
    border-color: #72959d;
    outline: none;
    border: none;
    border-bottom: 2px solid #72959d;
  }
  
  .nosubmit-parent {
    border: none;
    padding: 0;
  }
  
  input.nosubmit {
    border-bottom: 1px solid #d2d2d2;
    width: 100%;
    padding: 9px 4px 9px 40px;
    background: transparent
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat 13px center;
  }
  
  .date-time-dashboad .dp__input_wrap {
    min-width: 200px !important;
    width: 250px;
  }
  
  .custom-control-label label {
    padding-top: 14px !important;
  }
  
  .pt-custtom {
      padding-top: 7px;
  }
  </style>
  