class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("mer_admin"));
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("mer_admin"));
    return user?.data.authorisation.token;
  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("mer_admin"));
    user.authorisation.token = token;
    localStorage.setItem("mer_admin", JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem("mer_admin");
  }

  setMerAdmin(merAdmin) {
    localStorage.setItem("mer_admin", JSON.stringify(merAdmin));
  }

  getMember() {
    return JSON.parse(localStorage.getItem("mer_admin"));
  }
}

export default new TokenService();
