import api from "../api";

class ModifierGroupService {
  createGroup(dataForm) {
    return api.post("/merchant/modifier-group-and-sub-group/create", dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  updateGroup(id, dataForm) {
    return api.post("/merchant/modifier-group-and-sub-group/" + id + "/update", dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  deleteGroup(id) {
    return api.post("/merchant/modifier-group-and-sub-group/" + id + "/delete");
  }

  getDetailGroup(id) {
    return api.post("/merchant/modifier-group-and-sub-group/" + id + "/detail");
  }
}

export default new ModifierGroupService();
