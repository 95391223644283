<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">{{ $t('Slider_Images') }}</h3>
        </div>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions pb-5">
      <!-- <span class="mask bg-white"></span> -->
      <div class="container">
        <div class="row">
          <div class="notifications">
            <material-alert
              v-if="error"
              color="danger"
              icon="ni ni-like-2 ni-lg"
              dismissible
            >
              {{ error }}
            </material-alert>
          </div>
          <div class="col-lg-12 col-md-12 col-12 mx-auto mt-3">
            <div class="tab-list p-0">
              <ul id="general-tab" class="nav nav-tabs">
                <li class="nav-item">
                  <a
                    id="nav_item_1"
                    class="nav-link active"
                    @click="changeTab(1)"
                  >
                    Minty Member
                  </a>
                </li>
                <li class="nav-item">
                  <a id="nav_item_2" class="nav-link" @click="changeTab(2)">
                    Online Ordering
                  </a>
                </li>
              </ul>
            </div>
            <div id="slide_list" v-if="type == 1">
              <div class="row mt-3">
                <div class="col-lg-9 col-12 color-common d-flex align-items-center">
                  <h3 class="title-page mb-0 pe-2">Homepage Slider</h3>
                  <div>(Maximum 9 slides display on page at a time)</div>
                </div>
                <div class="col-lg-3 col-12 d-flex justify-content-end">
                  <material-button @click="saveSlide()" class="btn-background btn-sm">
                    Save
                  </material-button>
                </div>
              </div>
              <draggable 
                  v-model="slides" 
                  group="slides" 
                  @start="drag=true" 
                  @end="onEndDragSlide"
                  item-key="id"
                  class="row row-flex"
                >
                <template #item="{element, index}">
                  <div class="col-xl-4 col-lg-6 col-12">
                  <div class="slide-box" :class="{'slide-scheduled': !element.active}">
                    <div class="d-flex justify-content-between">
                      <div class="ps-0 text-title">
                        {{ $t('Image_Sequence') }}
                      </div>
                      <i
                        style="color: #f44335; font-size: 20px; cursor: pointer;"
                        class="fa fa-times icon-close-sidenav-main danger"
                        aria-hidden="true"
                        @click="removeRowSlide(index)"
                      ></i>
                    </div>
                    <div class="w-100 p-3">
                      <div class="dowload-img" v-show="urlImagePreview[index] || element.file_name">
                        <div id="preview" class="w-100" style="position: relative">
                          <img
                            class="image-url"
                            v-if="urlImagePreview[index] || element.file_name"
                            :src="urlImagePreview[index] ? urlImagePreview[index] : element.file_name"
                          />
                          <i
                            class="fa fa-times icon-close-sidenav-main danger"
                            v-if="urlImagePreview[index] || element.file_name"
                            style="
                              position: absolute;
                              color: #f44335;
                              font-size: 20px;
                              right: 5px;
                              top: 8px;
                            "
                            aria-hidden="true"
                            @click="clearImage(index)"
                          ></i>
                          <div class="btn-edit-img">
                            <i
                              class="fas fa-pencil-alt text-dark"
                              v-if="urlImagePreview[index] || element.file_name"
                              aria-hidden="true"
                              @click="uploadFile(index)"
                            ></i>
                          </div>
                          <div class="schedule-tag" v-if="!element.active">SCHEDULED</div>
                        </div>
                      </div>
                      <div
                        class="dowload-img"
                        @click="uploadFile(index)"
                        v-show="!urlImagePreview[index] && !element.file_name"
                      >
                        <material-input
                          style="display: none"
                          type="file"
                          :id="`image_${index}`"
                          @change="handleFileUpload($event, index)"
                          accept="image/*"
                        />
                        <i class="material-icons css-icon-upload mt-0">backup</i>
                        <h6>Click here to upload an image.</h6>
                      </div>
                      <div
                        class="is-invalid d-flex align-items-center pe-2"
                        v-if="fieldRequired.isImageRequire"
                      >
                        {{ messageImageError }}
                      </div>
                    </div>
                    <div class="slide-config">
                      <div>
                        <div class="ps-0 label-form mb-2">
                          {{ $t('Who_can_see_this_slide') }}
                        </div>
                        <Multiselect
                          v-model="element.slide_tiers"
                          mode="tags"
                          :close-on-select="false"
                          class="multiselect-slide"
                          :searchable="true"
                          :options="tiers"
                          placeholder="Select tiers can see"
                          :canClear="false"
                        />  
                      </div>
                      <div class="mt-3">
                        <div class="ps-0 label-form mb-2">
                          {{ $t('Display_Period') }}
                        </div>
                        <div class="d-flex align-items-center">
                          <div class="label-form pe-3 min-w-50px">{{ $t('From') }}</div>
                            <Datepicker
                              v-model="element.start_date"
                              :enableTimePicker="false"
                              class="date-time pe-3"
                              :disabled="alwaysDisplayChecked[index] == 'true' ? true : false"
                              :format="format"
                            />
                            <Datepicker
                              v-model="element.start_time"
                              timePicker
                              class="date-time"
                              :disabled="alwaysDisplayChecked[index] == 'true' ? true : false"
                            />
                        </div>
                        <div class="d-flex mt-2 align-items-center">
                          <div class="label-form pe-3 min-w-50px">{{ $t('To') }}</div>
                            <Datepicker
                              v-model="element.end_date"
                              :enableTimePicker="false"
                              class="date-time pe-3"
                              :disabled="alwaysDisplayChecked[index] == 'true' ? true : false"
                              :format="format"
                            />
                            <Datepicker
                              v-model="element.end_time"
                              timePicker
                              class="date-time"
                              :disabled="alwaysDisplayChecked[index] == 'true' ? true : false"
                            />
                        </div>
                        <div class="d-flex mt-2" v-if="fieldRequired[index].isDateRequire">
                          <div class="min-w-50px"></div>
                          <span
                            class="is-invalid"
                            >{{ textRequiredDateCustom[index] }}</span
                          >
                        </div>
                        <div class="d-flex mt-2">
                          <div class="min-w-50px"></div>
                          <material-checkbox
                            v-model="element.always_display"
                            :checked="element.always_display ? true : false"
                            :id="`always_display_${index}`"
                            class="align-items-center checkbox-custom"
                            @change="check($event, index)"
                          >
                            <template v-slot:label>
                              {{ $t('Always_Display') }}
                            </template>
                          </material-checkbox>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="ps-0 label-form mb-2">
                          {{ $t('Destination_link') }}
                        </div>
                        <material-input
                          class="textarea-slide"
                          v-model="element.destination_link"
                          placeholder="Enter destination link"
                        /> 
                      </div>
                    </div>
                  </div>
                </div>
                </template>
              </draggable>
              <div class="d-flex justify-content-end mt-3" v-if="this.slides.length < 9">
                <a
                  @click="addRowSlide()"
                  class="text-decoration-underline tag-a-custom"
                  >Add Image</a
                >
              </div>
            </div>
            
            <div id="slide_list" v-if="type == 2">
              <div class="row mt-3">
                <div class="col-lg-12 col-12 color-common d-flex align-items-center">
                  <div class="col-2">
                    <h3 class="title-page mb-0 pe-2">Select branch</h3>
                  </div>
                  <div class="col-3">
                    <Multiselect
                      :options="branches"
                      :canClear="false"
                      v-model="branchIdSelect"
                      @change="changeBranches"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div class="row mt-3">
                  <div class="col-lg-9 col-12 color-common d-flex align-items-center">
                    <h3 class="title-page mb-0 pe-2">Homepage Slider</h3>
                    <div>(Maximum 9 slides display on page at a time)</div>
                  </div>
                  <div class="col-lg-3 col-12 d-flex justify-content-end">
                    <div style="margin-right: 10px;" v-if="this.slidesOrderingHomepage.length < 9">
                      <a
                        @click="addRowSlideOrderingHomePage()"
                        class="text-decoration-underline tag-a-custom"
                        >Add Image</a
                      >
                    </div>
                    <material-button @click="saveSlideOrderingHomePage()" class="btn-background btn-sm">
                      Save
                    </material-button>
                  </div>
                </div>
                <draggable 
                    v-model="slidesOrderingHomepage" 
                    group="slides" 
                    @start="drag=true" 
                    @end="onEndDragSlideOrderingHomepage"
                    item-key="id"
                    class="row row-flex"
                  >
                  <template #item="{element, index}">
                    <div class="col-xl-4 col-lg-6 col-12">
                    <div class="slide-box" :class="{'slide-scheduled': !element.active}">
                      <div class="d-flex justify-content-between">
                        <div class="ps-0 text-title">
                          {{ $t('Image_Sequence') }}
                        </div>
                        <i
                          style="color: #f44335; font-size: 20px; cursor: pointer;"
                          class="fa fa-times icon-close-sidenav-main danger"
                          aria-hidden="true"
                          @click="removeRowSlideOrderingHomepage(index)"
                        ></i>
                      </div>
                      <div class="w-100 p-3">
                        <div class="dowload-img" v-show="urlImagePreviewOrderingHomepage[index] || element.file_name">
                          <div id="preview" class="w-100" style="position: relative">
                            <img
                              class="image-url"
                              v-if="urlImagePreviewOrderingHomepage[index] || element.file_name"
                              :src="urlImagePreviewOrderingHomepage[index] ? urlImagePreviewOrderingHomepage[index] : element.file_name"
                            />
                            <i
                              class="fa fa-times icon-close-sidenav-main danger"
                              v-if="urlImagePreviewOrderingHomepage[index] || element.file_name"
                              style="
                                position: absolute;
                                color: #f44335;
                                font-size: 20px;
                                right: 5px;
                                top: 8px;
                              "
                              aria-hidden="true"
                              @click="clearImageOrderingHomepage(index)"
                            ></i>
                            <div class="btn-edit-img">
                              <i
                                class="fas fa-pencil-alt text-dark"
                                v-if="urlImagePreviewOrderingHomepage[index] || element.file_name"
                                aria-hidden="true"
                                @click="uploadFileOrderingHomepage(index)"
                              ></i>
                            </div>
                            <div class="schedule-tag" v-if="!element.active">SCHEDULED</div>
                          </div>
                        </div>
                        <div
                          class="dowload-img"
                          @click="uploadFileOrderingHomepage(index)"
                          v-show="!urlImagePreviewOrderingHomepage[index] && !element.file_name"
                        >
                          <material-input
                            style="display: none"
                            type="file"
                            :id="`image_ordering_homepage_${index}`"
                            @change="handleFileUploadOrderingHomepage($event, index)"
                            accept="image/*"
                          />
                          <i class="material-icons css-icon-upload mt-0">backup</i>
                          <h6>Click here to upload an image.</h6>
                        </div>
                        <div
                          class="is-invalid d-flex align-items-center pe-2"
                          v-if="fieldRequired.isImageRequire"
                        >
                          {{ messageImageErrorOrderingHomepage }}
                        </div>
                      </div>
                      <div class="slide-config">
                        <div>
                          <div class="ps-0 label-form mb-2">
                            {{ $t('Who_can_see_this_slide') }}
                          </div>
                          <Multiselect
                            v-model="element.slide_tiers"
                            mode="tags"
                            :close-on-select="false"
                            class="multiselect-slide"
                            :searchable="true"
                            :options="tiers"
                            placeholder="Select tiers can see"
                            :canClear="false"
                          />  
                        </div>
                        <div class="mt-3">
                          <div class="ps-0 label-form mb-2">
                            {{ $t('Display_Period') }}
                          </div>
                          <div class="d-flex align-items-center">
                            <div class="label-form pe-3 min-w-50px">{{ $t('From') }}</div>
                              <Datepicker
                                v-model="element.start_date"
                                :enableTimePicker="false"
                                class="date-time pe-3"
                                :disabled="alwaysDisplayCheckedOrderingHomepage[index] == 'true' ? true : false"
                                :format="format"
                              />
                              <Datepicker
                                v-model="element.start_time"
                                timePicker
                                class="date-time"
                                :disabled="alwaysDisplayCheckedOrderingHomepage[index] == 'true' ? true : false"
                              />
                          </div>
                          <div class="d-flex mt-2 align-items-center">
                            <div class="label-form pe-3 min-w-50px">{{ $t('To') }}</div>
                              <Datepicker
                                v-model="element.end_date"
                                :enableTimePicker="false"
                                class="date-time pe-3"
                                :disabled="alwaysDisplayCheckedOrderingHomepage[index] == 'true' ? true : false"
                                :format="format"
                              />
                              <Datepicker
                                v-model="element.end_time"
                                timePicker
                                class="date-time"
                                :disabled="alwaysDisplayCheckedOrderingHomepage[index] == 'true' ? true : false"
                              />
                          </div>
                          <div class="d-flex mt-2" v-if="fieldRequiredOrderingHomepage[index].isDateRequire">
                            <div class="min-w-50px"></div>
                            <span
                              class="is-invalid"
                              >{{ textRequiredDateCustomOrderingHomepage[index] }}</span
                            >
                          </div>
                          <div class="d-flex mt-2">
                            <div class="min-w-50px"></div>
                            <material-checkbox
                              v-model="element.always_display"
                              :checked="element.always_display ? true : false"
                              :id="`always_display_${index}`"
                              class="align-items-center checkbox-custom"
                              @change="checkOrderingHomepage($event, index)"
                            >
                              <template v-slot:label>
                                {{ $t('Always_Display') }}
                              </template>
                            </material-checkbox>
                          </div>
                        </div>
                        <div class="mt-3">
                          <div class="ps-0 label-form mb-2">
                            {{ $t('Destination_link') }}
                          </div>
                          <material-input
                            class="textarea-slide"
                            v-model="element.destination_link"
                            placeholder="Enter destination link"
                          /> 
                        </div>
                      </div>
                    </div>
                  </div>
                  </template>
                </draggable>
              </div>
              <div style="text-align: center;font-style: italic;">Drag to change image position</div>
              <hr />
              <div>
                <div class="row mt-3">
                  <div class="col-lg-9 col-12 color-common d-flex align-items-center">
                    <h3 class="title-page mb-0 pe-2">Customization Page Header</h3>
                    <div>(Maximum 9 slides display on page at a time)</div>
                  </div>
                  <div class="col-lg-3 col-12 d-flex justify-content-end">
                    <div style="margin-right: 10px;" v-if="this.slidesOrderingCustompage.length < 9">
                      <a
                        @click="addRowSlideOrderingCustomPage()"
                        class="text-decoration-underline tag-a-custom"
                        >Add Image</a
                      >
                    </div>
                    <material-button @click="saveSlideOrderingCustomPage()" class="btn-background btn-sm">
                      Save
                    </material-button>
                  </div>
                </div>
                <draggable 
                    v-model="slidesOrderingCustompage" 
                    group="slides" 
                    @start="drag=true" 
                    @end="onEndDragSlideOrderingCustompage"
                    item-key="id"
                    class="row row-flex"
                  >
                  <template #item="{element, index}">
                    <div class="col-xl-4 col-lg-6 col-12">
                    <div class="slide-box" :class="{'slide-scheduled': !element.active}">
                      <div class="d-flex justify-content-between">
                        <div class="ps-0 text-title">
                          {{ $t('Image_Sequence') }}
                        </div>
                        <i
                          style="color: #f44335; font-size: 20px; cursor: pointer;"
                          class="fa fa-times icon-close-sidenav-main danger"
                          aria-hidden="true"
                          @click="removeRowSlideOrderingCustompage(index)"
                        ></i>
                      </div>
                      <div class="w-100 p-3">
                        <div class="dowload-img" v-show="urlImagePreviewOrderingCustompage[index] || element.file_name">
                          <div id="preview" class="w-100" style="position: relative">
                            <img
                              class="image-url"
                              v-if="urlImagePreviewOrderingCustompage[index] || element.file_name"
                              :src="urlImagePreviewOrderingCustompage[index] ? urlImagePreviewOrderingCustompage[index] : element.file_name"
                            />
                            <i
                              class="fa fa-times icon-close-sidenav-main danger"
                              v-if="urlImagePreviewOrderingCustompage[index] || element.file_name"
                              style="
                                position: absolute;
                                color: #f44335;
                                font-size: 20px;
                                right: 5px;
                                top: 8px;
                              "
                              aria-hidden="true"
                              @click="clearImageOrderingCustompage(index)"
                            ></i>
                            <div class="btn-edit-img">
                              <i
                                class="fas fa-pencil-alt text-dark"
                                v-if="urlImagePreviewOrderingCustompage[index] || element.file_name"
                                aria-hidden="true"
                                @click="uploadFileOrderingCustompage(index)"
                              ></i>
                            </div>
                            <div class="schedule-tag" v-if="!element.active">SCHEDULED</div>
                          </div>
                        </div>
                        <div
                          class="dowload-img"
                          @click="uploadFileOrderingCustompage(index)"
                          v-show="!urlImagePreviewOrderingCustompage[index] && !element.file_name"
                        >
                          <material-input
                            style="display: none"
                            type="file"
                            :id="`image_ordering_custompage_${index}`"
                            @change="handleFileUploadOrderingCustompage($event, index)"
                            accept="image/*"
                          />
                          <i class="material-icons css-icon-upload mt-0">backup</i>
                          <h6>Click here to upload an image.</h6>
                        </div>
                        <div
                          class="is-invalid d-flex align-items-center pe-2"
                          v-if="fieldRequiredOrderingCustompage.isImageRequire"
                        >
                          {{ messageImageErrorOrderingCustompage }}
                        </div>
                      </div>
                      <div class="slide-config">
                        <div>
                          <div class="ps-0 label-form mb-2">
                            {{ $t('Who_can_see_this_slide') }}
                          </div>
                          <Multiselect
                            v-model="element.slide_tiers"
                            mode="tags"
                            :close-on-select="false"
                            class="multiselect-slide"
                            :searchable="true"
                            :options="tiers"
                            placeholder="Select tiers can see"
                            :canClear="false"
                          />  
                        </div>
                        <div class="mt-3">
                          <div class="ps-0 label-form mb-2">
                            Display Condition
                          </div>
                          <div style="display: inline-flex;align-items: center;width: 100%;">
                            <div style="display: inline-flex;gap: 5px;width: 50%;">
                              <input type="radio" value="1" v-model="element.display_condition" />
                              <div class="label-form">If items have tag</div>
                            </div>
                            <material-input
                              v-model="element.tags"
                              style="width: 50%;"
                              class="textarea-slide"
                              placeholder="Enter tag"
                            /> 
                          </div>
                          <div style="display: inline-flex;justify-content: space-between;align-items: center;gap: 10px">
                            <div style="display: inline-flex;gap: 5px">
                              <input type="radio" value="2" v-model="element.display_condition" />
                              <div class="label-form">Suggested Adds On</div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-3">
                          <div class="ps-0 label-form mb-2">
                            {{ $t('Display_Period') }}
                          </div>
                          <div class="d-flex align-items-center">
                            <div class="label-form pe-3 min-w-50px">{{ $t('From') }}</div>
                              <Datepicker
                                v-model="element.start_date"
                                :enableTimePicker="false"
                                class="date-time pe-3"
                                :disabled="alwaysDisplayCheckedOrderingCustompage[index] == 'true' ? true : false"
                                :format="format"
                              />
                              <Datepicker
                                v-model="element.start_time"
                                timePicker
                                class="date-time"
                                :disabled="alwaysDisplayCheckedOrderingCustompage[index] == 'true' ? true : false"
                              />
                          </div>
                          <div class="d-flex mt-2 align-items-center">
                            <div class="label-form pe-3 min-w-50px">{{ $t('To') }}</div>
                              <Datepicker
                                v-model="element.end_date"
                                :enableTimePicker="false"
                                class="date-time pe-3"
                                :disabled="alwaysDisplayCheckedOrderingCustompage[index] == 'true' ? true : false"
                                :format="format"
                              />
                              <Datepicker
                                v-model="element.end_time"
                                timePicker
                                class="date-time"
                                :disabled="alwaysDisplayCheckedOrderingCustompage[index] == 'true' ? true : false"
                              />
                          </div>
                          <div class="d-flex mt-2" v-if="fieldRequiredOrderingCustompage[index].isDateRequire">
                            <div class="min-w-50px"></div>
                            <span
                              class="is-invalid"
                              >{{ textRequiredDateCustomOrderingCustompage[index] }}</span
                            >
                          </div>
                          <div class="d-flex mt-2">
                            <div class="min-w-50px"></div>
                            <material-checkbox
                              v-model="element.always_display"
                              :checked="element.always_display ? true : false"
                              :id="`always_display_${index}`"
                              class="align-items-center checkbox-custom"
                              @change="checkOrderingCustompage($event, index)"
                            >
                              <template v-slot:label>
                                {{ $t('Always_Display') }}
                              </template>
                            </material-checkbox>
                          </div>
                        </div>
                        <div class="mt-3">
                          <div class="ps-0 label-form mb-2">
                            Clickable function
                          </div>
                          <div style="display: inline-flex;align-items: center;width: 100%;">
                            <div style="display: inline-flex;gap: 5px;width: 50%;">
                              <input type="radio" value="1" v-model="element.clickablefunction" :checked="element.destination_link != '' && element.direct_to_menu_item == null && element.direct_to_suggested_adds_on == null" />
                              <div class="label-form">Destination link</div>
                            </div>
                            <material-input
                              v-model="element.destination_link"
                              style="
                                  width: 50%;
                              "
                              class="textarea-slide"
                              placeholder="Enter link"
                            /> 
                          </div>
                          <div style="display: inline-flex;align-items: center;width: 100%;">
                            <div style="display: inline-flex;gap: 5px;width: 50%;">
                              <input type="radio" value="2" v-model="element.clickablefunction" :checked="element.direct_to_menu_item != null" />
                              <div class="label-form">Direct to a certain Menu Item</div>
                            </div>
                            <Multiselect
                              v-model="element.direct_to_menu_item"
                              style="
                                  width: 50%;
                              "
                              class="multiselect-slide"
                              :searchable="true"
                              :options="items"
                              placeholder="Select"
                              :canClear="false"
                            />  
                          </div>
                          <div style="display: inline-flex;justify-content: space-between;align-items: center;gap: 10px">
                            <div style="display: inline-flex;gap: 5px">
                              <input type="radio" value="3" v-model="element.clickablefunction" :disabled="element.display_condition == '1' ? true : false" :checked="element.direct_to_suggested_adds_on != null" />
                              <div class="label-form">Direct to suggested Adds On</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </template>
                </draggable>
              </div>
              <div style="text-align: center;font-style: italic;">Drag to change image position</div>
              <hr />
              <div>
                <div class="row mt-3">
                  <div class="col-lg-9 col-12 color-common d-flex align-items-center">
                    <h3 class="title-page mb-0 pe-2">Checkout Page Banner</h3>
                    <div>(Maximum 9 slides display on page at a time)</div>
                  </div>
                  <div class="col-lg-3 col-12 d-flex justify-content-end">
                    <div style="margin-right: 10px;" v-if="this.slidesOrderingCheckoutpage.length < 9">
                      <a
                        @click="addRowSlideOrderingCheckoutPage()"
                        class="text-decoration-underline tag-a-custom"
                        >Add Image</a
                      >
                    </div>
                    <material-button @click="saveSlideOrderingCheckoutPage()" class="btn-background btn-sm">
                      Save
                    </material-button>
                  </div>
                </div>
                <draggable 
                    v-model="slidesOrderingCheckoutpage" 
                    group="slides" 
                    @start="drag=true" 
                    @end="onEndDragSlideOrderingCheckoutpage"
                    item-key="id"
                    class="row row-flex"
                  >
                  <template #item="{element, index}">
                    <div class="col-xl-4 col-lg-6 col-12">
                    <div class="slide-box" :class="{'slide-scheduled': !element.active}">
                      <div class="d-flex justify-content-between">
                        <div class="ps-0 text-title">
                          {{ $t('Image_Sequence') }}
                        </div>
                        <i
                          style="color: #f44335; font-size: 20px; cursor: pointer;"
                          class="fa fa-times icon-close-sidenav-main danger"
                          aria-hidden="true"
                          @click="removeRowSlideOrderingCheckoutpage(index)"
                        ></i>
                      </div>
                      <div class="w-100 p-3">
                        <div class="dowload-img" v-show="urlImagePreviewOrderingCheckoutpage[index] || element.file_name">
                          <div id="preview" class="w-100" style="position: relative">
                            <img
                              class="image-url"
                              v-if="urlImagePreviewOrderingCheckoutpage[index] || element.file_name"
                              :src="urlImagePreviewOrderingCheckoutpage[index] ? urlImagePreviewOrderingCheckoutpage[index] : element.file_name"
                            />
                            <i
                              class="fa fa-times icon-close-sidenav-main danger"
                              v-if="urlImagePreviewOrderingCheckoutpage[index] || element.file_name"
                              style="
                                position: absolute;
                                color: #f44335;
                                font-size: 20px;
                                right: 5px;
                                top: 8px;
                              "
                              aria-hidden="true"
                              @click="clearImageOrderingCheckoutpage(index)"
                            ></i>
                            <div class="btn-edit-img">
                              <i
                                class="fas fa-pencil-alt text-dark"
                                v-if="urlImagePreviewOrderingCheckoutpage[index] || element.file_name"
                                aria-hidden="true"
                                @click="uploadFileOrderingCheckoutpage(index)"
                              ></i>
                            </div>
                            <div class="schedule-tag" v-if="!element.active">SCHEDULED</div>
                          </div>
                        </div>
                        <div
                          class="dowload-img"
                          @click="uploadFileOrderingCheckoutpage(index)"
                          v-show="!urlImagePreviewOrderingCheckoutpage[index] && !element.file_name"
                        >
                          <material-input
                            style="display: none"
                            type="file"
                            :id="`image_ordering_checkoutpage_${index}`"
                            @change="handleFileUploadOrderingCheckoutpage($event, index)"
                            accept="image/*"
                          />
                          <i class="material-icons css-icon-upload mt-0">backup</i>
                          <h6>Click here to upload an image.</h6>
                        </div>
                        <div
                          class="is-invalid d-flex align-items-center pe-2"
                          v-if="fieldRequiredOrderingCheckoutpage.isImageRequire"
                        >
                          {{ messageImageErrorOrderingCheckoutpage }}
                        </div>
                      </div>
                      <div class="slide-config">
                        <div>
                          <div class="ps-0 label-form mb-2">
                            {{ $t('Who_can_see_this_slide') }}
                          </div>
                          <Multiselect
                            v-model="element.slide_tiers"
                            mode="tags"
                            :close-on-select="false"
                            class="multiselect-slide"
                            :searchable="true"
                            :options="tiers"
                            placeholder="Select tiers can see"
                            :canClear="false"
                          />  
                        </div>
                        <div class="mt-3">
                          <div class="ps-0 label-form mb-2">
                            Display Condition
                          </div>
                          <div style="display: inline-flex;align-items: center;width: 100%;">
                            <div style="display: inline-flex;gap: 5px;width: 50%;">
                              <input type="radio" value="1" v-model="element.display_condition" />
                              <div class="label-form">If items have tag</div>
                            </div>
                            <material-input
                              v-model="element.tags"
                              style="width: 50%;"
                              class="textarea-slide"
                              placeholder="Enter tag"
                            /> 
                          </div>
                          <div style="display: inline-flex;justify-content: space-between;align-items: center;gap: 10px">
                            <div style="display: inline-flex;gap: 5px">
                              <input type="radio" value="2" v-model="element.display_condition" />
                              <div class="label-form">Suggested Adds On</div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-3">
                          <div class="ps-0 label-form mb-2">
                            {{ $t('Display_Period') }}
                          </div>
                          <div class="d-flex align-items-center">
                            <div class="label-form pe-3 min-w-50px">{{ $t('From') }}</div>
                              <Datepicker
                                v-model="element.start_date"
                                :enableTimePicker="false"
                                class="date-time pe-3"
                                :disabled="alwaysDisplayCheckedOrderingCheckoutpage[index] == 'true' ? true : false"
                                :format="format"
                              />
                              <Datepicker
                                v-model="element.start_time"
                                timePicker
                                class="date-time"
                                :disabled="alwaysDisplayCheckedOrderingCheckoutpage[index] == 'true' ? true : false"
                              />
                          </div>
                          <div class="d-flex mt-2 align-items-center">
                            <div class="label-form pe-3 min-w-50px">{{ $t('To') }}</div>
                              <Datepicker
                                v-model="element.end_date"
                                :enableTimePicker="false"
                                class="date-time pe-3"
                                :disabled="alwaysDisplayCheckedOrderingCheckoutpage[index] == 'true' ? true : false"
                                :format="format"
                              />
                              <Datepicker
                                v-model="element.end_time"
                                timePicker
                                class="date-time"
                                :disabled="alwaysDisplayCheckedOrderingCheckoutpage[index] == 'true' ? true : false"
                              />
                          </div>
                          <div class="d-flex mt-2" v-if="fieldRequiredOrderingCheckoutpage[index].isDateRequire">
                            <div class="min-w-50px"></div>
                            <span
                              class="is-invalid"
                              >{{ textRequiredDateCustomOrderingCheckoutpage[index] }}</span
                            >
                          </div>
                          <div class="d-flex mt-2">
                            <div class="min-w-50px"></div>
                            <material-checkbox
                              v-model="element.always_display"
                              :checked="element.always_display ? true : false"
                              :id="`always_display_${index}`"
                              class="align-items-center checkbox-custom"
                              @change="checkOrderingCheckoutpage($event, index)"
                            >
                              <template v-slot:label>
                                {{ $t('Always_Display') }}
                              </template>
                            </material-checkbox>
                          </div>
                        </div>
                        <div class="mt-3">
                          <div class="ps-0 label-form mb-2">
                            Clickable function
                          </div>
                          <div style="display: inline-flex;align-items: center;width: 100%;">
                            <div style="display: inline-flex;gap: 5px;width: 50%;">
                              <input type="radio" value="1" v-model="element.clickablefunction" :checked="element.destination_link != '' && element.direct_to_menu_item == null && element.direct_to_suggested_adds_on == null" />
                              <div class="label-form">Destination link</div>
                            </div>
                            <material-input
                              v-model="element.destination_link"
                              style="
                                  width: 50%;
                              "
                              class="textarea-slide"
                              placeholder="Enter link"
                            /> 
                          </div>
                          <div style="display: inline-flex;align-items: center;width: 100%;">
                            <div style="display: inline-flex;gap: 5px;width: 50%;">
                              <input type="radio" value="2" v-model="element.clickablefunction" :checked="element.direct_to_menu_item != null" />
                              <div class="label-form">Direct to a certain Menu Item</div>
                            </div>
                            <Multiselect
                              v-model="element.direct_to_menu_item"
                              style="
                                  width: 50%;
                              "
                              class="multiselect-slide"
                              :searchable="true"
                              :options="items"
                              placeholder="Select"
                              :canClear="false"
                            />  
                          </div>
                          <div style="display: inline-flex;justify-content: space-between;align-items: center;gap: 10px">
                            <div style="display: inline-flex;gap: 5px">
                              <input type="radio" value="3" v-model="element.clickablefunction" :disabled="element.display_condition == '1' ? true : false" :checked="element.direct_to_suggested_adds_on != null" />
                              <div class="label-form">Direct to suggested Adds On</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </template>
                </draggable>
              </div>
            </div>
            <div style="text-align: center;font-style: italic;">Drag to change image position</div>
            <hr />
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
    </div>
  </div>
</template>
<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import SettingSlideService from "../../services/settingSlide.service";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import CommonFunctionService from "../../services/commonFunction.service";
import TestService from "../../services/test.service";
import moment from "moment";
import $ from "jquery";
import draggable from 'vuedraggable'

export default {
  name: "slides",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    MaterialInput,
    Multiselect,
    Datepicker,
    MaterialCheckbox,
    draggable
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      fieldRequired: [],
      merchantCode: "",
      isLoadDoneData: false,
      urlImagePreview: [],
      messageImageError: [],
      isErrorUploadImage: [],
      isImageTooSmall: [],
      slides: [],
      tiers: [],
      disableBtnSubmit: false,
      textRequiredDateCustom: [],
      alwaysDisplayChecked: [],
      type: 1,
      branches: [],
      branchIdSelect: 0,

      items: [],

      slidesOrderingHomepage: [],
      fieldRequiredOrderingHomepage: [],
      alwaysDisplayCheckedOrderingHomepage: [],
      textRequiredDateCustomOrderingHomepage: [],
      urlImagePreviewOrderingHomepage: [],
      messageImageErrorOrderingHomepage: [],
      isErrorUploadImageOrderingHomepage: [],
      isImageTooSmallOrderingHomepage: [],

      slidesOrderingCustompage: [],
      fieldRequiredOrderingCustompage: [],
      alwaysDisplayCheckedOrderingCustompage: [],
      textRequiredDateCustomOrderingCustompage: [],
      urlImagePreviewOrderingCustompage: [],
      messageImageErrorOrderingCustompage: [],
      isErrorUploadImageOrderingCustompage: [],
      isImageTooSmallOrderingCustompage: [],

      slidesOrderingCheckoutpage: [],
      fieldRequiredOrderingCheckoutpage: [],
      alwaysDisplayCheckedOrderingCheckoutpage: [],
      textRequiredDateCustomOrderingCheckoutpage: [],
      urlImagePreviewOrderingCheckoutpage: [],
      messageImageErrorOrderingCheckoutpage: [],
      isErrorUploadImageOrderingCheckoutpage: [],
      isImageTooSmallOrderingCheckoutpage: [],
    };
  },
  created() {
    this.getSlide();
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
  },
  methods: {
    changeTab(type) {
      $("#general-tab .nav-link").removeClass("active");
      $("#nav_item_" + type).addClass("active");
      this.type = type;
      if(type == 1) {
        this.branchIdSelect = 0;
        this.getSlide();
      }
      if (type == 2) {
        this.getBranches();
      }
    },
    format(date) {
      return CommonFunctionService.formatDateShow(date)
    },
    dateTimeFormat(startDate, startTime) {
      if (startDate == null) {
        return null;
      } else {
        if (startTime == null) {
          return moment(startDate).format("YYYY-MM-DD") + " " + "00:00:00";
        } else {
          return (
            moment(startDate).format("YYYY-MM-DD") +
            " " +
            moment(startTime).format("H:m")
          );
        }
      }
    },
    handleFileUpload(event, index) {
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        if (
          !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
            file.type
          )
        ) {
          alert('File must be an image.');
        } else {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (evt) => {
            let img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              let ratio = width/height;

              if (ratio < 1.5 || ratio > 2.5) {
                alert('Please upload and image with 2:1 aspect ratio (Width/Height)');
              } else if (file.size / 1024 / 1024 > 5) {
                alert('Uploaded image must be under 5M.');
              } else {
                this.slides[index].file_name = file;
                this.urlImagePreview[index] = URL.createObjectURL(file);
                this.isErrorUploadImage[index] = false;
              }
            };
            img.src = evt.target.result;
          };
        }
      } else {
        alert('File must be an image.');
      }
      $("#image_" + index)[0].value = '';
    },

    clearImage(index) {
      this.slides[index].file_name = null;
      this.urlImagePreview[index] = null;
      this.fieldRequired[index].isImageRequire = false;
      this.isErrorUploadImage[index] = false;
      this.isImageTooSmall[index] = false;
    },

    getBranches() {
      this.showLoader();
      TestService.getBranches().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.branches = response.data.data;
            this.branchIdSelect = this.branches && this.branches[0] ? this.branches[0].value : 0;
            this.getSlide();
          } else {
            this.message = response.data.result.message;
          }
          this.hideLoader();
        },
        (error) => {
          this.message = error;
          this.hideLoader();
        }
      );
    },

    getSlide() {
      this.isLoadDoneData = false;
      this.alwaysDisplayChecked = []
      this.showLoader();
      let dataForm = {
        type: this.type,
        branch_id: this.branchIdSelect
      }

      SettingSlideService.getDataSlide(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.tiers = response.data.data.tiers;
            this.items = response.data.data.items;
            if(this.type == 1) {
              this.slides = response.data.data.data_slide_setting;
              this.slides.forEach((item) => {
                this.fieldRequired.push({
                  isImageRequire: false,
                  isDateRequire: false,
                });
                item.always_display == 1 ? this.alwaysDisplayChecked.push("true") : this.alwaysDisplayChecked.push("false")
              });
            } else {
              this.slidesOrderingHomepage = response.data.data.data_slide_setting;
              this.slidesOrderingHomepage.forEach((item) => {
                this.fieldRequiredOrderingHomepage.push({
                  isImageRequire: false,
                  isDateRequire: false,
                });
                item.always_display == 1 ? this.alwaysDisplayCheckedOrderingHomepage.push("true") : this.alwaysDisplayCheckedOrderingHomepage.push("false")
              });
              this.slidesOrderingCustompage = response.data.data.data_slide_setting_custom;
              this.slidesOrderingCustompage.forEach((item) => {
                this.fieldRequiredOrderingCustompage.push({
                  isImageRequire: false,
                  isDateRequire: false,
                });
                item.always_display == 1 ? this.alwaysDisplayCheckedOrderingCustompage.push("true") : this.alwaysDisplayCheckedOrderingCustompage.push("false")
              });
              this.slidesOrderingCheckoutpage = response.data.data.data_slide_setting_checkout;
              this.slidesOrderingCheckoutpage.forEach((item) => {
                this.fieldRequiredOrderingCheckoutpage.push({
                  isImageRequire: false,
                  isDateRequire: false,
                });
                item.always_display == 1 ? this.alwaysDisplayCheckedOrderingCheckoutpage.push("true") : this.alwaysDisplayCheckedOrderingCheckoutpage.push("false")
              });
            }
            this.isLoadDoneData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    async saveSlide() {
      this.showLoader();
      var checkImageTooSmall = false;
      var checkisFileButNotImage = false;
      this.isImageTooSmall.forEach((item) => {
        if (item == true) {
          checkImageTooSmall = true;
        }
      });

      this.isImageTooSmall.forEach((item) => {
        if (item == true) {
          checkisFileButNotImage = true;
        }
      });
      if (
        !checkImageTooSmall &&
        !checkisFileButNotImage &&
        this.processValidate()
      ) {
        this.fieldRequired.forEach((slide) => {
          slide.isImageRequire = false;
        });
        this.objDataSave = {};
        this.objDataSave = this.slides;
        this.objDataSave.forEach((item) => {
          item.start_date = this.dateTimeFormat(
            item.start_date,
            item.start_time
          );
          item.end_date = this.dateTimeFormat(item.end_date, item.end_time);
          item.always_display = item.always_display ? 1 : 0;
        });
        await SettingSlideService.addOrUpdateSlide(this.objDataSave).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message = "Save Slide Success";
              this.getSlide();
              this.setTimeOutNotification();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification()
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification()
          }
        );
      } else {
        this.hideLoader();
      }
    },

    async onEndDragSlide() {
      if(this.slides && this.slides.length > 1) {
        this.showLoader();
        var dataForm = {
          orderSlides: this.slides
        };
        await SettingSlideService.updateSlidesOrder(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.getSlide();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    resetRequired() {
      this.slides.forEach((item, index) => {
        this.fieldRequired[index].isDateRequire = false;
      });
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      this.slides.forEach((item, index) => {
        const dateFrom = this.dateTimeFormat(
          this.slides[index].start_date,
          this.slides[index].start_time
        );
        const dateTo = this.dateTimeFormat(
          this.slides[index].end_date,
          this.slides[index].end_time
        );
        if (dateFrom == null && dateTo != null) {
          this.fieldRequired[index].isDateRequire = true;
          this.textRequiredDateCustom[index] =
            "The start date field is required.";
          isValid = false;
        } else if (dateFrom != null && dateTo != null && dateFrom >= dateTo) {
          this.fieldRequired[index].isDateRequire = true;
          this.textRequiredDateCustom[index] =
            "The end date must be a date after start date.";
          isValid = false;
        }
      });
      return isValid;
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    addRowSlide() {
      this.slides.push({
        id: null,
        index: this.slides.length,
        description: "1",
        file_name: null,
        ordering: 0,
        slide_tiers: ['all'],
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        always_display: 0,
        destination_link: "",
        status: 1,
      });

      this.fieldRequired.push({
        isImageRequire: false,
        isDateRequire: false,
      });

      this.alwaysDisplayChecked.push(true)
    },

    removeRowSlide(index) {
      if (this.slides.length > 0) {
        this.slides.splice(index, 1);
        this.alwaysDisplayChecked.splice(index, 1);
      }
    },

    check($event, index) {
      this.alwaysDisplayChecked[index] = $event.target.value
    },

    uploadFile(index) {
      console.log(index);
      $("#image_" + index).click();
    },

    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },

    addRowSlideOrderingHomePage() {
      this.slidesOrderingHomepage.push({
        id: null,
        index: this.slidesOrderingHomepage.length,
        description: "1",
        file_name: null,
        ordering: 0,
        slide_tiers: ['all'],
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        always_display: 0,
        destination_link: "",
        status: 1,
        type: 2,
        branch_id: this.branchIdSelect,
        where_showing: 1
      });

      this.fieldRequiredOrderingHomepage.push({
        isImageRequire: false,
        isDateRequire: false,
      });

      this.alwaysDisplayCheckedOrderingHomepage.push(true)
    },

    resetRequiredOrderingHomePage() {
      this.slidesOrderingHomepage.forEach((item, index) => {
        this.fieldRequiredOrderingHomepage[index].isDateRequire = false;
      });
    },

    processValidateOrderingHomePage() {
      this.resetRequiredOrderingHomePage();
      var isValid = true;
      this.slidesOrderingHomepage.forEach((item, index) => {
        const dateFrom = this.dateTimeFormat(
          this.slidesOrderingHomepage[index].start_date,
          this.slidesOrderingHomepage[index].start_time
        );
        const dateTo = this.dateTimeFormat(
          this.slidesOrderingHomepage[index].end_date,
          this.slidesOrderingHomepage[index].end_time
        );
        if (dateFrom == null && dateTo != null) {
          this.fieldRequiredOrderingHomepage[index].isDateRequire = true;
          this.textRequiredDateCustomOrderingHomepage[index] =
            "The start date field is required.";
          isValid = false;
        } else if (dateFrom != null && dateTo != null && dateFrom >= dateTo) {
          this.fieldRequiredOrderingHomepage[index].isDateRequire = true;
          this.textRequiredDateCustomOrderingHomepage[index] =
            "The end date must be a date after start date.";
          isValid = false;
        }
      });
      return isValid;
    },

    async saveSlideOrderingHomePage() {
      this.showLoader();
      var checkImageTooSmall = false;
      var checkisFileButNotImage = false;
      this.isImageTooSmallOrderingHomepage.forEach((item) => {
        if (item == true) {
          checkImageTooSmall = true;
        }
      });

      this.isImageTooSmallOrderingHomepage.forEach((item) => {
        if (item == true) {
          checkisFileButNotImage = true;
        }
      });
      if (
        !checkImageTooSmall &&
        !checkisFileButNotImage &&
        this.processValidateOrderingHomePage()
      ) {
        this.fieldRequiredOrderingHomepage.forEach((slide) => {
          slide.isImageRequire = false;
        });
        this.objDataSave = {};
        this.objDataSave = this.slidesOrderingHomepage;
        this.objDataSave.forEach((item) => {
          item.start_date = this.dateTimeFormat(
            item.start_date,
            item.start_time
          );
          item.end_date = this.dateTimeFormat(item.end_date, item.end_time);
          item.always_display = item.always_display ? 1 : 0;
        });
        await SettingSlideService.addOrUpdateSlide(this.objDataSave).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message = "Save Slide Success";
              this.getSlide();
              this.setTimeOutNotification();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification()
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification()
          }
        );
      } else {
        this.hideLoader();
      }
    },

    async onEndDragSlideOrderingHomepage() {
      if(this.slidesOrderingHomepage && this.slidesOrderingHomepage.length > 1) {
        this.showLoader();
        var dataForm = {
          orderSlides: this.slidesOrderingHomepage
        };
        await SettingSlideService.updateSlidesOrder(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.getSlide();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    removeRowSlideOrderingHomepage(index) {
      if (this.slidesOrderingHomepage.length > 0) {
        this.slidesOrderingHomepage.splice(index, 1);
        this.alwaysDisplayCheckedOrderingHomepage.splice(index, 1);
      }
    },

    clearImageOrderingHomepage(index) {
      this.slidesOrderingHomepage[index].file_name = null;
      this.urlImagePreviewOrderingHomepage[index] = null;
      this.fieldRequiredOrderingHomepage[index].isImageRequire = false;
      this.isErrorUploadImageOrderingHomepage[index] = false;
      this.isImageTooSmallOrderingHomepage[index] = false;
    },

    uploadFileOrderingHomepage(index) {
      console.log(index);
      $("#image_ordering_homepage_" + index).click();
    },

    handleFileUploadOrderingHomepage(event, index) {
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        if (
          !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
            file.type
          )
        ) {
          alert('File must be an image.');
        } else {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (evt) => {
            let img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              let ratio = width/height;

              if (ratio < 1.5 || ratio > 2.5) {
                alert('Please upload and image with 2:1 aspect ratio (Width/Height)');
              } else if (file.size / 1024 / 1024 > 5) {
                alert('Uploaded image must be under 5M.');
              } else {
                this.slidesOrderingHomepage[index].file_name = file;
                this.urlImagePreviewOrderingHomepage[index] = URL.createObjectURL(file);
                this.isErrorUploadImageOrderingHomepage[index] = false;
              }
            };
            img.src = evt.target.result;
          };
        }
      } else {
        alert('File must be an image.');
      }
      $("#image_ordering_homepage_" + index)[0].value = '';
    },

    checkOrderingHomepage($event, index) {
      this.alwaysDisplayCheckedOrderingHomepage[index] = $event.target.value
    },

    changeBranches(branch_id) {
      this.slidesOrderingHomepage = [];
      this.fieldRequiredOrderingHomepage = [];
      this.alwaysDisplayCheckedOrderingHomepage = [];
      this.textRequiredDateCustomOrderingHomepage = [];
      this.urlImagePreviewOrderingHomepage = [];
      this.isErrorUploadImageOrderingHomepage = [];
      this.isImageTooSmallOrderingHomepage = [];

      this.branchIdSelect = branch_id;
      this.getSlide();
    },

    // custom
    addRowSlideOrderingCustomPage() {
      this.slidesOrderingCustompage.push({
        id: null,
        index: this.slidesOrderingCustompage.length,
        description: "1",
        file_name: null,
        ordering: 0,
        slide_tiers: ['all'],
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        always_display: 0,
        destination_link: "",
        status: 1,
        type: 2,
        branch_id: this.branchIdSelect,
        where_showing: 2,
        display_condition: 2,
        tags: '',
        clickablefunction: 3,
        direct_to_menu_item: 0
      });

      this.fieldRequiredOrderingCustompage.push({
        isImageRequire: false,
        isDateRequire: false,
      });

      this.alwaysDisplayCheckedOrderingCustompage.push(true)
    },

    resetRequiredOrderingCustomPage() {
      this.slidesOrderingCustompage.forEach((item, index) => {
        this.fieldRequiredOrderingCustompage[index].isDateRequire = false;
      });
    },

    processValidateOrderingCustomPage() {
      this.resetRequiredOrderingCustomPage();
      var isValid = true;
      this.slidesOrderingCustompage.forEach((item, index) => {
        const dateFrom = this.dateTimeFormat(
          this.slidesOrderingCustompage[index].start_date,
          this.slidesOrderingCustompage[index].start_time
        );
        const dateTo = this.dateTimeFormat(
          this.slidesOrderingCustompage[index].end_date,
          this.slidesOrderingCustompage[index].end_time
        );
        if (dateFrom == null && dateTo != null) {
          this.fieldRequiredOrderingCustompage[index].isDateRequire = true;
          this.textRequiredDateCustomOrderingCustompage[index] =
            "The start date field is required.";
          isValid = false;
        } else if (dateFrom != null && dateTo != null && dateFrom >= dateTo) {
          this.fieldRequiredOrderingCustompage[index].isDateRequire = true;
          this.textRequiredDateCustomOrderingCustompage[index] =
            "The end date must be a date after start date.";
          isValid = false;
        }
      });
      return isValid;
    },

    async saveSlideOrderingCustomPage() {
      this.showLoader();
      var checkImageTooSmall = false;
      var checkisFileButNotImage = false;
      this.isImageTooSmallOrderingCustompage.forEach((item) => {
        if (item == true) {
          checkImageTooSmall = true;
        }
      });

      this.isImageTooSmallOrderingCustompage.forEach((item) => {
        if (item == true) {
          checkisFileButNotImage = true;
        }
      });
      if (
        !checkImageTooSmall &&
        !checkisFileButNotImage &&
        this.processValidateOrderingCustomPage()
      ) {
        this.fieldRequiredOrderingCustompage.forEach((slide) => {
          slide.isImageRequire = false;
        });
        this.objDataSave = {};
        this.objDataSave = this.slidesOrderingCustompage;
        this.objDataSave.forEach((item) => {
          item.start_date = this.dateTimeFormat(
            item.start_date,
            item.start_time
          );
          item.end_date = this.dateTimeFormat(item.end_date, item.end_time);
          item.always_display = item.always_display ? 1 : 0;
        });
        await SettingSlideService.addOrUpdateSlide(this.objDataSave).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message = "Save Slide Success";
              this.getSlide();
              this.setTimeOutNotification();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification()
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification()
          }
        );
      } else {
        this.hideLoader();
      }
    },

    async onEndDragSlideOrderingCustompage() {
      if(this.slidesOrderingCustompage && this.slidesOrderingCustompage.length > 1) {
        this.showLoader();
        var dataForm = {
          orderSlides: this.slidesOrderingCustompage
        };
        await SettingSlideService.updateSlidesOrder(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.getSlide();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    removeRowSlideOrderingCustompage(index) {
      if (this.slidesOrderingCustompage.length > 0) {
        this.slidesOrderingCustompage.splice(index, 1);
        this.alwaysDisplayCheckedOrderingCustompage.splice(index, 1);
      }
    },

    clearImageOrderingCustompage(index) {
      this.slidesOrderingCustompage[index].file_name = null;
      this.urlImagePreviewOrderingCustompage[index] = null;
      this.fieldRequiredOrderingCustompage[index].isImageRequire = false;
      this.isErrorUploadImageOrderingCustompage[index] = false;
      this.isImageTooSmallOrderingCustompage[index] = false;
    },

    uploadFileOrderingCustompage(index) {
      console.log(index);
      $("#image_ordering_custompage_" + index).click();
    },

    handleFileUploadOrderingCustompage(event, index) {
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        if (
          !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
            file.type
          )
        ) {
          alert('File must be an image.');
        } else {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (evt) => {
            let img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              let ratio = width/height;

              if (ratio < 1.5 || ratio > 2.5) {
                alert('Please upload and image with 2:1 aspect ratio (Width/Height)');
              } else if (file.size / 1024 / 1024 > 5) {
                alert('Uploaded image must be under 5M.');
              } else {
                this.slidesOrderingCustompage[index].file_name = file;
                this.urlImagePreviewOrderingCustompage[index] = URL.createObjectURL(file);
                this.isErrorUploadImageOrderingCustompage[index] = false;
              }
            };
            img.src = evt.target.result;
          };
        }
      } else {
        alert('File must be an image.');
      }
      $("#image_ordering_custompage_" + index)[0].value = '';
    },

    checkOrderingCustompage($event, index) {
      this.alwaysDisplayCheckedOrderingCustompage[index] = $event.target.value
    },

    // checkout
    addRowSlideOrderingCheckoutPage() {
      this.slidesOrderingCheckoutpage.push({
        id: null,
        index: this.slidesOrderingCheckoutpage.length,
        description: "1",
        file_name: null,
        ordering: 0,
        slide_tiers: ['all'],
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        always_display: 0,
        destination_link: "",
        status: 1,
        type: 2,
        branch_id: this.branchIdSelect,
        where_showing: 3,
        display_condition: 2,
        tags: '',
        clickablefunction: 3,
        direct_to_menu_item: 0
      });

      this.fieldRequiredOrderingCheckoutpage.push({
        isImageRequire: false,
        isDateRequire: false,
      });

      this.alwaysDisplayCheckedOrderingCheckoutpage.push(true)
    },

    resetRequiredOrderingCheckoutPage() {
      this.slidesOrderingCheckoutpage.forEach((item, index) => {
        this.fieldRequiredOrderingCheckoutpage[index].isDateRequire = false;
      });
    },

    processValidateOrderingCheckoutPage() {
      this.resetRequiredOrderingCheckoutPage();
      var isValid = true;
      this.slidesOrderingCheckoutpage.forEach((item, index) => {
        const dateFrom = this.dateTimeFormat(
          this.slidesOrderingCheckoutpage[index].start_date,
          this.slidesOrderingCheckoutpage[index].start_time
        );
        const dateTo = this.dateTimeFormat(
          this.slidesOrderingCheckoutpage[index].end_date,
          this.slidesOrderingCheckoutpage[index].end_time
        );
        if (dateFrom == null && dateTo != null) {
          this.fieldRequiredOrderingCheckoutpage[index].isDateRequire = true;
          this.textRequiredDateCheckoutOrderingCheckoutpage[index] =
            "The start date field is required.";
          isValid = false;
        } else if (dateFrom != null && dateTo != null && dateFrom >= dateTo) {
          this.fieldRequiredOrderingCheckoutpage[index].isDateRequire = true;
          this.textRequiredDateCheckoutOrderingCheckoutpage[index] =
            "The end date must be a date after start date.";
          isValid = false;
        }
      });
      return isValid;
    },

    async saveSlideOrderingCheckoutPage() {
      this.showLoader();
      var checkImageTooSmall = false;
      var checkisFileButNotImage = false;
      this.isImageTooSmallOrderingCheckoutpage.forEach((item) => {
        if (item == true) {
          checkImageTooSmall = true;
        }
      });

      this.isImageTooSmallOrderingCheckoutpage.forEach((item) => {
        if (item == true) {
          checkisFileButNotImage = true;
        }
      });
      if (
        !checkImageTooSmall &&
        !checkisFileButNotImage &&
        this.processValidateOrderingCheckoutPage()
      ) {
        this.fieldRequiredOrderingCheckoutpage.forEach((slide) => {
          slide.isImageRequire = false;
        });
        this.objDataSave = {};
        this.objDataSave = this.slidesOrderingCheckoutpage;
        this.objDataSave.forEach((item) => {
          item.start_date = this.dateTimeFormat(
            item.start_date,
            item.start_time
          );
          item.end_date = this.dateTimeFormat(item.end_date, item.end_time);
          item.always_display = item.always_display ? 1 : 0;
        });
        await SettingSlideService.addOrUpdateSlide(this.objDataSave).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message = "Save Slide Success";
              this.getSlide();
              this.setTimeOutNotification();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification()
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification()
          }
        );
      } else {
        this.hideLoader();
      }
    },

    async onEndDragSlideOrderingCheckoutpage() {
      if(this.slidesOrderingCheckoutpage && this.slidesOrderingCheckoutpage.length > 1) {
        this.showLoader();
        var dataForm = {
          orderSlides: this.slidesOrderingCheckoutpage
        };
        await SettingSlideService.updateSlidesOrder(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.getSlide();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    removeRowSlideOrderingCheckoutpage(index) {
      if (this.slidesOrderingCheckoutpage.length > 0) {
        this.slidesOrderingCheckoutpage.splice(index, 1);
        this.alwaysDisplayCheckedOrderingCheckoutpage.splice(index, 1);
      }
    },

    clearImageOrderingCheckoutpage(index) {
      this.slidesOrderingCheckoutpage[index].file_name = null;
      this.urlImagePreviewOrderingCheckoutpage[index] = null;
      this.fieldRequiredOrderingCheckoutpage[index].isImageRequire = false;
      this.isErrorUploadImageOrderingCheckoutpage[index] = false;
      this.isImageTooSmallOrderingCheckoutpage[index] = false;
    },

    uploadFileOrderingCheckoutpage(index) {
      console.log(index);
      $("#image_ordering_checkoutpage_" + index).click();
    },

    handleFileUploadOrderingCheckoutpage(event, index) {
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        if (
          !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
            file.type
          )
        ) {
          alert('File must be an image.');
        } else {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (evt) => {
            let img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              let ratio = width/height;

              if (ratio < 1.5 || ratio > 2.5) {
                alert('Please upload and image with 2:1 aspect ratio (Width/Height)');
              } else if (file.size / 1024 / 1024 > 5) {
                alert('Uploaded image must be under 5M.');
              } else {
                this.slidesOrderingCheckoutpage[index].file_name = file;
                this.urlImagePreviewOrderingCheckoutpage[index] = URL.createObjectURL(file);
                this.isErrorUploadImageOrderingCheckoutpage[index] = false;
              }
            };
            img.src = evt.target.result;
          };
        }
      } else {
        alert('File must be an image.');
      }
      $("#image_ordering_checkoutpage_" + index)[0].value = '';
    },

    checkOrderingCheckoutpage($event, index) {
      this.alwaysDisplayCheckedOrderingCheckoutpage[index] = $event.target.value
    }
  },
};
</script>
<style scoped>
.card-body .label-form {
  color: black !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.tag-a-custom {
  color: #007bff !important;
  text-decoration: underline !important;
  cursor: pointer;
}

#preview img {
  max-width: 150px;
  max-height: 100px;
}

#slide_list .multiselect-date input {
  max-width: 200px !important;
}

#slide_list .multiselect-time input {
  max-width: 100px !important;
}

.textarea-slide {
  max-width: 600px;
}

.custom-label {
  max-width: 200px !important;
  white-space: normal;
}

/* new css */
.slide-box {
  background: hsla(0, 0%, 100%, .5);
  border: 1px solid #d6d6d6;
  padding: 15px;
  border-radius: 5px;
  margin-top: 15px;
  cursor: move;
}

.slide-box .dowload-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f0f2f5;
  width: 100%;
  aspect-ratio: 2 / 1;
  cursor: pointer;
}

.slide-box .css-icon-upload {
  font-size: 45px !important;
  margin-top: 6rem;
}

.slide-box .image-url {
  width: 100%;
  aspect-ratio: 2 / 1;
  max-width: 100% !important;
  max-height: 100% !important;
}

.slide-box.slide-scheduled .image-url {
  opacity: 0.5;
}

.schedule-tag {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #82001e  !important;
  color: #ffffff;
  padding: 3px 10px;
  font-weight: bold;
}

.slide-box .btn-edit-img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #f44335;
  font-size: 14px;
  right: 30px;
  top: 4px;
  background: #ffffff;
  border-radius: 50%;
}

.min-w-50px {
  min-width: 50px !important;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}

.row-flex > [class*="col-"] {
  margin-bottom: 15px;
}

.row-flex > [class*="col-"] > .slide-box {
  height: calc(100% - 15px);
}
</style>