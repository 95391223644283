<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">MARKETING CAMPAIGN LIST</h3>
        </div>
      </div>
      <div class="col-lg-4 col-md-5 col-5 d-flex justify-content-end">
        <material-button
          @click="createCampaign()"
          :disabled="disableBtnSubmit"
          class="mx-2 btn btn-sm btn-background"
        >
          Add Campaign
        </material-button>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto">
            <div class="row mt-3">
              <div class="col-lg-9 col-md-9 col-6">
                {{ $t('OVERALL_PERFORMANCE') }}
              </div>
              <div class="col-lg-3 col-md-3 col-6 d-flex justify-content-end">
                  <Multiselect v-model="filter_time" :options="times" @change="changeTime()" :canClear="false"/>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-4 col-md-4 col-12 text-center">
                <strong class="text-size-35">{{
                  this.pagination.total_rewards_used
                }}</strong
                ><br />
                <span> {{ $t('Total_Rewards_Used') }}</span>
              </div>
              <div class="col-lg-4 col-md-4 col-12 text-center">
                <strong class="text-size-35">{{
                  this.pagination.average_spending_per_rewards
                }}</strong
                ><br />
                {{ $t('Average_Spending_per_Rewards') }}
              </div>
              <div class="col-lg-4 col-md-4 col-12 text-center">
                <strong class="text-size-35">{{
                  this.pagination.total_spending_used
                }}</strong
                ><br />
                {{ $t('Total_Revenue_from_Rewards') }}
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-lg-2 col-md-2 col-2 text-center">
                {{ $t('REWARDS_CAMPAIGN') }}
              </div>
              <div class="col-lg-2 col-md-2 col-2 text-center">
                {{ $t('Count') }}: {{ campaigns.length }}
              </div>
              <div class="col-lg-8 col-md-8 col-8 text-center d-flex justify-content-end">
                {{ $t('Show') }}
                <MaterialRadio v-model="filter_type" @change="changeType()" id="all" value="0">
                  {{ $t('All') }}
                </MaterialRadio>
                <MaterialRadio v-model="filter_type" @change="changeType()" id="active" value="1">
                  {{ $t('Active') }}
                </MaterialRadio>
                <MaterialRadio v-model="filter_type" @change="changeType()" id="past" value="2">
                  {{ $t('Past') }}
                </MaterialRadio>
                <MaterialRadio v-model="filter_type" @change="changeType()" id="deactive" value="3">
                  {{ $t('Deactive') }}
                </MaterialRadio>
              </div>
            </div>
            <div class="row my-2 d-flex align-items-left">
              <div class="col-lg-6 col-md-6 col-12">
                <input
                  class="nosubmit"
                  type="search"
                  id="keyword_search"
                  v-model="searchKeyword"
                  extraClass
                  name="keyword_search"
                  placeholder="Search by campaign title"
                  @input="onChangesearchKeyword"
                />
              </div>
              <div class="col-lg-6 col-md-6 col-12 d-flex justify-content-end">
                <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle btn-sm" type="button" id="dropdownMenuButton1" style="margin-top: 13px;"
                    data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
                    {{ $t('download') }}
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <a class="dropdown-item" @click="exportFile('csv')" href="#">Export CSV</a>
                    </li>
                    <li>
                      <a class="dropdown-item" @click="exportFile('xlsx')" href="#">Export Excel</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12">
                <div class="card-custom">
                  <div class="px-0 pb-2">
                    <div class="table-responsive p-0 custom-table">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th class="text-center font-weight-bolder w-5">
                              {{ $t('Campain_Title') }}
                            </th>
                            <th class="text-center font-weight-bolder">
                              {{ $t('Rewards') }}
                            </th>
                            <th class="text-center font-weight-bolder w-10">
                              {{ $t('Type') }}
                            </th>
                            <th class="text-center font-weight-bolder w-10">
                              {{ $t('Duration') }}
                            </th>
                            <th class="text-center font-weight-bolder w-10">
                              {{ $t('Email_Sent') }}
                            </th>
                            <th class="text-center font-weight-bolder w-5">
                             {{ $t('SMS_Sent') }}
                            </th>
                            <th class="text-center font-weight-bolder w-5">
                              {{ $t('Used') }}
                            </th>
                            <th class="text-center font-weight-bolder w-5">
                              {{ $t('Revenue') }}
                            </th>
                            <th class="text-center font-weight-bolder w-5">
                              {{ $t('status') }}
                            </th>
                            <th class="text-center font-weight-bolder w-5">
                              {{ $t('action') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="campaigns.length">
                          <tr v-for="(value, key) in campaigns" :key="key">
                            <td class="text-center text-xss w-5 link" @click="editCampaign(value.id)">
                              {{ value.campaign_tile }}
                            </td>
                            <td
                              class="text-left text-xss w-25"
                            >
                              {{ value.reward }}
                            </td>
                            <td class="text-center text-xss w-10">
                              {{ value.campaign_type_text }}
                            </td>
                            <td class="text-center text-xss w-10">
                              {{ value.duration }}
                            </td>
                            <td class="text-center text-xss w-10">
                              {{ value.total_mail_sent ?? 0 }}
                            </td>
                            <td class="text-center text-xss w-10">
                              {{ value.total_sms_sent ?? 0 }}
                            </td>
                            <td class="text-center text-xss w-10">
                              {{ value.number_used ?? 0 }}
                            </td>
                            <td class="text-center text-xss w-10">
                              {{ value.total_rev ?? 0 }}
                            </td>
                            <td class="text-center text-xss w-10">
                              {{ value.status_text ?? '' }}
                            </td>
                            <td class="w-5 align-middle text-center text-sm">
                              <a
                                class="btn btn-link text-dark px-2 mb-0"
                                @click="editCampaign(value.id)"
                              >
                                <i
                                  class="fas fa-pencil-alt text-dark me-2"
                                  aria-hidden="true"
                                ></i
                                >Details
                              </a>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="10" class="text-center">
                              No item available
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <pagination
                    v-if="pagination.last_page > 1"
                    :pagination="pagination"
                    :current_page="pagination.current_page"
                    :last_page="pagination.last_page"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
    </div>
    <form :action="`${actionDownload}/merchant/download-campaigns`" method="POST">
      <input type="hidden" name="merchantId" :value="merchantIdExport" />
      <input type="hidden" name="merchant_code" :value="merchantCodeExport" />
      <input type="hidden" name="token" :value="token" />
      <input type="hidden" name="search_keyword" :value="searchKeyword" />
      <input type="hidden" name="filter_time" :value="filter_time" />
      <input type="hidden" name="filter_type" :value="filter_type" />
      <input type="hidden" name="action_export" :value="action_export" />
      <input type="hidden" name="action" value="export" />
      <input id="downloadForm" type="submit" class="d-none" />
    </form>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Pagination from "@/components/Pagination.vue";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import MaterialRadio from "@/components/MaterialRadio.vue";
import SettingCampaignService from "../../services/settingCampaign.service";
import TokenService from "../../services/token.service";
import $ from "jquery";

export default {
  name: "campaign-list",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    Pagination,
    Multiselect,
    MaterialRadio
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      campaigns: [],
      searchKeyword: "",
      searchTimerId: null,
      pagination: {},
      page: 1,
      isLoadData: false,
      merchantCode: "",
      filter_time: 0,
      times: [
        { value: 0, label: 'All' },
        { value: 1, label: 'Today' },
        { value: 2, label: 'Yesterday' },
        { value: 3, label: 'Last 7 days' },
        { value: 4, label: 'Last 30 days' },
        { value: 5, label: 'This month' },
        { value: 6, label: 'Last 3 months' },
        { value: 7, label: 'This year' },
        { value: 8, label: 'Last year' },
      ],
      filter_type: 0,
      action_export: 'csv',
      actionDownload: process.env.VUE_APP_BASE_URL,
      token: TokenService.getLocalAccessToken(),
      merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
      merchantCodeExport: localStorage.getItem("MERCHANT_CODE", ""),
    };
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    this.getList(this.page);
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
  },
  methods: {
    exportFile(type = 'csv') {
      this.action_export = type;
      setTimeout(() => {
        $('#downloadForm').click();
      }, 150);
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    changeTime() {
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getList(this.page);
      }, 1000);
    },

    changeType() {
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getList(this.page);
      }, 1000);
    },

    onChangesearchKeyword(event) {
      this.searchKeyword = event.target.value;
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getList(this.page);
      }, 1000);
    },

    getList(page) {
      this.isLoadData = false;
      this.campaigns = [];
      this.showLoader();
      var dataForm = {
        filter_time: this.filter_time,
        search_keyword: this.searchKeyword,
        filter_type: this.filter_type,
        page: page,
      };
      SettingCampaignService.getDataCampaigns(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.campaigns = response.data.data.items;
            this.pagination = response.data.data;
            this.page = this.pagination.current_page;
            this.$router.push({
              query: Object.assign({}, this.$route.query, { page: this.page }),
            });
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    createCampaign() {
      this.$router.push("/campaign");
    },

    editCampaign(id) {
      this.$router.push(
        "/campaign/" + id + "?page=" + this.page
      );
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
<style scoped>
.text-title {
  color: #82001e !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.text-label {
  color: #5b848d !important;
  font-weight: 500 !important;
  font-size: 17px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.checkbox-auto-save label {
  padding-top: 11px !important;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
}

input[type="search"]:focus {
  border-color: #72959d;
  outline: none;
  border: none;
  border-bottom: 2px solid #72959d;
}

.nosubmit-parent {
  border: none;
  padding: 0;
}

input.nosubmit {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.custom-title {
  word-break: break-all;
  max-width: 200px;
  white-space: normal;
}
.link {
  color: rgb(7, 7, 95);
}
.link:hover {
  cursor: pointer;
}
</style>