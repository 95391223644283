<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">MEMBER DISCOUNT</h3>
        </div>
      </div>
      <div class="col-lg-4 col-md-5 col-5 d-flex justify-content-end">
        <material-button @click="addRow()" class="btn-background btn-sm">
          Add Discount Rule
        </material-button>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <!-- <span class="mask bg-white"></span> -->
      <div class="container">
        <div class="row">
          <div class="notifications">
            <material-alert
              v-if="error"
              color="danger"
              icon="ni ni-like-2 ni-lg"
              dismissible
            >
              {{ error }}
            </material-alert>
          </div>
          <div class="col-12 mx-auto" v-if="isLoadDoneData">
            <div class="mt-3">
              <DiscountRule
                :save-discount="saveDiscount"
                :remove-discount="confirmDelete"
                :field-required="fieldRequired"
                :discount-amuont-type="discountAmuontType"
                :discount-apply-for-store="discountApplyForStore"
                :discount-apply-for-products="discountApplyForProducts"
                :time-units="timeUnits"
                :form="form"
                :on-change-renew-type="onChangeRenewType"
                :text-required-date-custom="textRequiredDateCustom"
                :text-required-order-minimum-custom="
                  textRequiredOrderMinimumCustom
                "
                :text-required-renew-time-custom="textRequiredRenewTimeCustom"
              />
              <div v-if="form.length == 0" class="text-center p-5">
                No Discount Rules Yet!
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirm
        message="Are you sure you want to delete ?"
        :process-yes="processYes"
      />
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import DiscountRule from "@/components/DiscountRule.vue";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import SettingDiscountService from "../../services/settingDiscount.service";
import ModalConfirm from "@/components/ModalConfirm.vue";
import { reactive } from "vue";
import moment from "moment";
import $ from "jquery";
export default {
  name: "find-store",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    DiscountRule,
    ModalConfirm,
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      discounts: [],
      form: [],
      fieldRequired: [],
      indexItem: 0,
      textRequiredDateCustom: "",
      textRequiredOrderMinimumCustom: [],
      textRequiredRenewTimeCustom: [],
      discountAmuontType: [],
      discountApplyForStore: [],
      discountApplyForProducts: [],
      timeUnits: [],
      isLoadDoneData: false,
    };
  },
  created() {
    this.getDiscountList();
  },
  methods: {
    onChangeRenewType(index, event) {
      if (event.target.value == 0) {
        this.form[index].renew_time = null;
        this.form[index].renew_unit = 1;
      }
    },
    dateTimeFormat(startDate, startTime) {
      if (startDate == null) {
        return null;
      } else {
        if (startTime == null) {
          return moment(startDate).format("YYYY-MM-DD") + " " + "00:00:00";
        } else {
          return (
            moment(startDate).format("YYYY-MM-DD") +
            " " +
            moment(startTime).format("H:m")
          );
        }
      }
    },
    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    addRow() {
      this.form.push({
        id: null,
        merchant_id: 1,
        rule_name: "",
        rule_code: "",
        discount_amount_type: "",
        discount_amount_value: null,
        apply_for_store: ['all'],
        apply_for_product: ['all'],
        order_minimum: 1,
        number_item: null,
        order_total: null,
        start_date: null,
        end_date: null,
        no_end_date: 0,
        start_time: null,
        end_time: null,
        renew_type: 0,
        renew_time: null,
        renew_unit: 1,
        terms_conditions: "",
      });

      this.fieldRequired.push({
        isRuleNameRequire: false,
        isRuleCodeRequire: false,
        isDiscountAmountTypeRequire: false,
        isDiscountAmountValueRequire: false,
        isOrderMinimumRequire: false,
        isRenewTimeRequire: false,
        isDateRequire: false,
      });
    },

    getDiscountList() {
      this.discounts = [];
      this.showLoader();
      this.isLoadDoneData = false;
      SettingDiscountService.getDataDiscount().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.discounts = response.data.data.discount_settings ?? [];
            this.discountAmuontType =
              response.data.data.dropdown_discount_amount_type;
            this.timeUnits = response.data.data.dropdown_time_unit;
            this.discountApplyForStore = response.data.data.dropdown_branches;
            this.discountApplyForProducts = response.data.data.dropdown_items;
            this.form = reactive(this.discounts);
            this.form.forEach(() => {
              this.fieldRequired.push({
                isRuleNameRequire: false,
                isRuleCodeRequire: false,
                isDiscountAmountTypeRequire: false,
                isDiscountAmountValueRequire: false,
                isOrderMinimumRequire: false,
                isRenewTimeRequire: false,
                isDateRequire: false,
              });
            });
            this.isLoadDoneData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    saveDiscount(index) {
      this.form[index].start_date = this.dateTimeFormat(
        this.form[index].start_date,
        this.form[index].start_time
      );
      this.form[index].end_date = this.dateTimeFormat(
        this.form[index].end_date,
        this.form[index].end_time
      );
      this.showLoader();
      if (this.processValidate(index)) {
        SettingDiscountService.addOrUpdateDiscount(this.form[index]).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message = "Save Rule Success";
              this.form[index].id = response.data.data.id;
              this.form[index].apply_for_store = response.data.data.apply_for_store;
              this.form[index].apply_for_product = response.data.data.apply_for_product;
              this.setTimeOutNotification();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      } else {
        this.hideLoader();
      }
    },
    processYes() {
      $(".btn-close-modal").click();
      const index = this.indexItem;
      this.fieldRequired.forEach((item, index) => {
        this.resetRequired(index);
      });

      this.showLoader();
      if (this.form[index].id == null) {
        this.removeRow(index);
        this.hideLoader();
      } else {
        if (this.form[index].id) {
          SettingDiscountService.deleteDiscount(this.form[index].id).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.removeRow(index);
                this.snackbar = "success";
                this.message = "Delete Rule Success";
                this.setTimeOutNotification();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        }
      }
    },
    confirmDelete(index) {
      this.indexItem = index;
      $(".btn-confirm-modal").click();
    },

    removeRow(index) {
      if (this.form.length > 0) {
        this.form.splice(index, 1);
      }
    },

    resetRequired(index) {
      this.fieldRequired[index].isRuleNameRequire = false;
      this.fieldRequired[index].isRuleCodeRequire = false;
      this.fieldRequired[index].isDiscountAmountTypeRequire = false;
      this.fieldRequired[index].isDiscountAmountValueRequire = false;
      (this.fieldRequired[index].isOrderMinimumRequire = false),
        (this.fieldRequired[index].isRenewTimeRequire = false),
        (this.fieldRequired[index].isDateRequire = false);
    },

    processValidate(index) {
      this.resetRequired(index);
      var isValid = true;
      if (
        this.form[index].rule_name == "" ||
        this.form[index].rule_name == null
      ) {
        this.fieldRequired[index].isRuleNameRequire = true;
        isValid = false;
      }
      if (
        this.form[index].rule_code == "" ||
        this.form[index].rule_code == null
      ) {
        this.fieldRequired[index].isRuleCodeRequire = true;
        isValid = false;
      }
      if (
        this.form[index].discount_amount_type == "" ||
        this.form[index].discount_amount_type == null
      ) {
        this.fieldRequired[index].isDiscountAmountTypeRequire = true;
        isValid = false;
      }
      if (
        this.form[index].discount_amount_value == "" ||
        this.form[index].discount_amount_value == null
      ) {
        this.fieldRequired[index].isDiscountAmountValueRequire = true;
        isValid = false;
      }
      if (
        this.form[index].order_minimum == 2 &&
        (this.form[index].number_item == "" ||
          this.form[index].number_item == null)
      ) {
        this.fieldRequired[index].isOrderMinimumRequire = true;
        this.textRequiredOrderMinimumCustom[index] =
          "Please enter number of item!";
        isValid = false;
      } else if (
        this.form[index].order_minimum == 3 &&
        (this.form[index].order_total == "" ||
          this.form[index].order_total == null)
      ) {
        this.fieldRequired[index].isOrderMinimumRequire = true;
        this.textRequiredOrderMinimumCustom[index] =
          "Please enter order total!";
        isValid = false;
      }

      if (
        this.form[index].renew_type == 1 &&
        (this.form[index].renew_time == "" ||
          this.form[index].renew_time == null)
      ) {
        this.fieldRequired[index].isRenewTimeRequire = true;
        this.textRequiredRenewTimeCustom[index] =
          "Please enter renew time detail!";
        isValid = false;
      }

      const dateFrom = this.dateTimeFormat(
        this.form[index].start_date,
        this.form[index].start_time
      );
      const dateTo = this.dateTimeFormat(
        this.form[index].end_date,
        this.form[index].end_time
      );
      if (dateFrom == null && dateTo != null) {
        this.fieldRequired[index].isDateRequire = true;
        this.textRequiredDateCustom = "The start date field is required.";
        isValid = false;
      } else if (dateFrom != null && dateTo != null && dateFrom >= dateTo) {
        this.fieldRequired[index].isDateRequire = true;
        this.textRequiredDateCustom =
          "The end date must be a date after start date.";
        isValid = false;
      }
      return isValid;
    },

    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>