<template>
  <li class="nav-item">
    <a
      class="nav-link"
      data-bs-toggle="collapse"
      aria-expanded="false"
      :href="`#${refer}`"
      @click="showHide()"
    >
      <i class="material-icons-round opacity-10 fs-5">{{miniIcon}}</i>
      <span class="sidenav-normal me-3 ms-2 ps-1">
        {{ text }} <b class="caret"></b
      ></span>
    </a>
    <div class="sidenav-collapse-item collapse" :class="'sidenav-collapse-item-' + sidenavKey">
      <ul class="nav nav-sm flex-column">
        <slot name="nav-child-item" />
      </ul>
    </div>
  </li>
</template>

<script>
import $ from "jquery";

export default {
  name: "SidenavCollapseItem",
  props: {
    refer: {
      type: String,
      required: true,
    },
    miniIcon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    sidenavKey: {
      type: String,
      required: true,
    }
  },
  methods: {
    showHide() {
      var check = false;
      var element = $('.sidenav-collapse-item-' + this.sidenavKey);

      if (element.hasClass('show')) {
        check = true;
      }

      $('.sidenav-collapse-item').removeClass('show');

      if (!check) {
        element.addClass('show');
      }
    }
  }
};
</script>
<style>
#sidenav-collapse-main ul li ul {
  padding-left: 20px;
}
.navbar-vertical .navbar-nav .nav-item .collapse .nav .nav-item .nav-link,
.navbar-vertical .navbar-nav .nav-item .collapsing .nav .nav-item .nav-link {
  color: #ffffff !important;
}
.navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
  color: #ffffff !important;
}
</style>
