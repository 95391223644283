<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#modalMenuSetSchedule"
      id="show-modal-menuset-schedule"
    ></material-button>
    <div
      class="modal fade"
      id="modalMenuSetSchedule"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-width">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Add menu availability
            </h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal justify-content-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              >clear</i
            >
          </div>
          <div class="modal-body">
            <!-- <div class="w-100">
              <h5>Branches</h5>
              <Multiselect
                mode="tags"
                v-model="branchIdSelect"
                :close-on-select="false"
                :searchable="false"
                :options="branches"
                placeholder="Branches"
                :canClear="false"
                class="mw-250px m-0"
                :disabled="true"
                :caret="false"
              />
            </div> -->
            <div class="mt-4">
              <div>
                <h5>Select opening and closing times</h5>
                <DayPilotCalendar id="dp" :config="config" ref="calendar" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="mt-5 float-right">
              <material-button
                class="mx-3 btn btn-sm btn-background"
                data-bs-dismiss="modal"
                aria-label="Close"
                >Cancel</material-button
              >
              <material-button
                @click="addSchedule"
                class="mx-3 btn btn-sm btn-background"
                >Save</material-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <material-loading :active="loaderActive" />
  </div>
</template>
<script>
import $ from "jquery";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
// import Multiselect from "@vueform/multiselect";
import ScheduleService from "../services/product/schedule.service";
// import MenusetService from "../services/product/menuset.service";
import { DayPilot, DayPilotCalendar } from "@daypilot/daypilot-lite-vue";
import moment from "moment";

export default {
  name: "schedule",
  components: {
    MaterialButton,
    MaterialLoading,
    DayPilotCalendar,
    // Multiselect,
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    setIdSelect: {
      type: Number,
      default: 0,
      required: false,
    },
    categoryIdSelect: {
      type: Number,
      default: 0,
      required: false,
    },
    itemIdSelect: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  computed: {
    calendar() {
      return this.$refs.calendar.control;
    },
  },
  data() {
    return {
      snackbar: null,
      message: "",
      loaderActive: false,
      scheduleList: [],
      scheduleIdsDelete: [],
      fieldRequired: {},
      branches: [],
      branchIdSelect: [],
      config: {
        viewType: "Week",
        headerDateFormat: "ddd",
        heightSpec: "Full",
        startDate: DayPilot.Date.today(),
        timeRangeSelectedHandling: "Enabled",
        onTimeRangeSelected: async (args) => {
          const dp = args.control;
          dp.clearSelection();
          let range =
            "From: " +
            this.formatDate(args.start) +
            " - " +
            "To: " +
            this.formatDate(args.end);

          dp.events.add({
            start: args.start,
            end: args.end,
            id: DayPilot.guid(),
            text: range,
            backColor: "#44bd32",
            borderColor: "#38761d",
            type: "create",
            day: moment(args.start, "YYYY-MM-DDTHH:mm:ss").format("dddd"),
          });

          this.scheduleList = dp.events.list;
        },
        eventDeleteHandling: "Update",
        onEventDeleted: (args) => {
          this.scheduleIdsDelete.push(args.e.id());
        },
        eventMoveHandling: "Disabled",
        onEventResized: (args) => {
          const schedule = this.scheduleList.find((e) => e.id === args.e.id());
          if (schedule) {
            let range =
              "From: " +
              this.formatDate(args.e.start()) +
              " - " +
              "To: " +
              this.formatDate(args.e.end());
            schedule.text = range;
            schedule.start = args.e.start();
            schedule.end = args.e.end();
            schedule.type = "update";
          }
          this.loadEvents();
        },
      },
    };
  },
  mounted() {
    this.loadEvents();
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    },

    loadEvents() {
      // placeholder for an HTTP call
      const events = this.scheduleList;
      this.calendar.update({ events });
    },

    // getBranches() {
    //   this.showLoader();
    //   MenusetService.getListBranch({
    //     set_id: this.setIdSelect ? this.setIdSelect : this.$route.params.id
    //   }).then(
    //     (response) => {
    //       if (response.data.result.isSuccess) {
    //         this.branches = response.data.data.branchSelect;
    //         this.branchIdSelect = response.data.data.branchIdSelect;
    //       } else {
    //         this.snackbar = "danger";
    //         this.message = response.data.result.message;
    //         this.setTimeOutNotification();
    //       }
    //       this.hideLoader();
    //     },
    //     (error) => {
    //       this.snackbar = "danger";
    //       this.message = error;
    //       this.hideLoader();
    //       this.setTimeOutNotification();
    //     }
    //   );
    // },

    getListSchedule() {
      this.showLoader();
      var dataForm = {
        set_id: this.setIdSelect ? this.setIdSelect : this.$route.params.id,
        branch_id: 0,
        category_id: this.categoryIdSelect,
        item_id: this.itemIdSelect
      };

      ScheduleService.getListSchedule(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            var schedules = response.data.data;
            var schedulesTemp = [];

            schedules.forEach((schedule) => {
              let range =
                "From: " +
                this.formatDate(schedule.time_from) +
                " - " +
                "To: " +
                this.formatDate(schedule.time_to);

              schedulesTemp.push({
                id: schedule.id,
                start: moment(schedule.time_from).format(
                  "YYYY-MM-DDTHH:mm:ss"
                ),
                end: moment(schedule.time_to).format("YYYY-MM-DDTHH:mm:ss"),
                text: range,
                backColor: "#44bd32",
                borderColor: "#38761d",
                type: "update",
              });
            });

            this.config.startDate = schedules[0]
              ? moment(schedules[0].time_from).format("YYYY-MM-DDTHH:mm:ss")
              : DayPilot.Date.today();
            this.scheduleList = schedulesTemp;
            this.loadEvents();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    addSchedule() {
      this.showLoader();
      var dataForm = {
        set_id: this.setIdSelect ? this.setIdSelect : this.$route.params.id,
        branch_id: 0,
        category_id: this.categoryIdSelect,
        item_id: this.itemIdSelect,
        start_date: DayPilot.Date.today(),
        schedule_ids_delete: this.scheduleIdsDelete,
        schedules: this.scheduleList,
      };

      ScheduleService.addSchedule(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.clearData();
            this.$emit("reload-schedule", "Add Schedule Success");
            $(".btn-close-modal").click();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    clearData() {
      this.scheduleIdsDelete = [];
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    keyReload() {
      this.scheduleList = [];
      this.scheduleIdsDelete = [];
      // this.getBranches();
      this.getListSchedule();
      this.loadEvents();
    },
  },
};
</script>

<style scoped>
.modal-content {
  max-height: 80vh;
  min-height: 80vh;
  padding-bottom: 25px;
}

.modal-body {
  max-height: 100%;
  overflow-y: auto;
}
.modal-width {
  min-width: 60%;
}
</style>
