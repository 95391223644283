import api from "../api";

class ModifierOptionGroupService {
  getList(dataForm) {
    return api.get("/merchant/modifier-option-group", { params: dataForm });
  }

  store(dataForm) {
    return api.post("/merchant/modifier-option-group", dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, dataForm) {
    return api.post("/merchant/modifier-option-group/update/"+id, dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getListByMenuSet(dataForm) {
    return api.post("/merchant/modifier-option-group/get-list-by-menuset", dataForm);
  }

  getAppliedPriceList(dataForm) {
    return api.post("/merchant/modifier-option-group/get-applied-price", dataForm);
  }

  attachedToItem(dataForm) {
    return api.post("/merchant/modifier-option-group/attached-to-item", dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getAttachedToItem(dataForm) {
    return api.post("/merchant/modifier-option-group/get-attached-to-item", dataForm);
  }

  deleteItem(itemId) {
    return api.post('/merchant/modifier-option-group/delete-item/' + itemId);
  }

  delete(itemId) {
    return api.post('/merchant/modifier-option-group/delete/' + itemId);
  }
}

export default new ModifierOptionGroupService();
