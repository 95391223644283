import api from "../api";

class ScheduleService {

  getListSchedule(dataForm) {
    return api.post("/merchant/menu-set/get-list-schedule", dataForm);
  }

  getScheduleInfo(dataForm) {
    return api.post("/merchant/menu-set/get-schedule-info", dataForm);
  }

  addSchedule(dataForm) {
    return api.post("/merchant/menu-set/add-schedule", dataForm);
  }

  removeSchedule(dataForm) {
    return api.post("/merchant/menu-set/remove-schedule", dataForm);
  }
}

export default new ScheduleService();
