<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">Menu Set</h3>
        </div>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto">
            <div class="d-flex justify-content-end mt-5">
              <!-- <material-button class="mx-2 btn btn-sm btn-background">
                <i class="material-icons css-icon mt-0">cached</i>
                Reload
              </material-button>
              <material-button class="mx-2 btn btn-sm btn-background">
                Publish
              </material-button> -->
              <material-button
                class="mx-2 btn btn-sm btn-background"
                data-bs-toggle="modal"
                data-bs-target="#modalCreateModalMenu"
              >
                <i class="material-icons css-icon">addoutlined</i>
                Create
              </material-button>
            </div>
            <div class="row mt-5">
              <div class="col-lg-6 col-12 d-flex align-items-center mb-2">
                <div class="label-header">
                  Showing {{ pagination.number_items }} of {{ pagination.total }} Menus
                </div>
              </div>
              <div
                class="col-lg-6 col-12 d-flex justify-content-end align-items-center mb-2"
              >
                <div class="label-header pe-2">Show inactive Menus</div>
                <div :class="{ active: isActive }" class="toggle_container pt-1">
                  <MaterialToggleButton
                    labelEnableText=""
                    labelDisableText=""
                    id="event_handle"
                    v-on:change="triggerEvent"
                    class="mb-0"
                  />
                </div>
              </div>
              <div class="col-12" v-if="isLoadData">
                <div class="card-custom">
                  <div class="px-0 pb-2">
                    <div class="table-responsive p-0 custom-table">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th class="text-center font-weight-bolder w-5">
                              {{ $t("Name") }}
                            </th>
                            <th class="text-center font-weight-bolder">
                              {{ $t("Branch") }}
                            </th>
                            <th class="text-center font-weight-bolder w-10">
                              {{ $t("Created at") }}
                            </th>
                            <th class="text-center font-weight-bolder w-5">
                              {{ $t("action") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="menuset.length">
                          <tr v-for="(value, key) in menuset" :key="key">
                            <td class="text-left text-xss w-25">
                              {{ value.name }}
                            </td>
                            <td class="text-left text-xss w-25">
                              {{ value.branch_name }}
                            </td>
                            <td class="text-center text-xss w-25">
                              {{ value.created_at_format }}
                            </td>
                            <td
                              class="text-xss d-flex justify-content-between align-items-center"
                              style="border-width: 0 !important"
                            >
                              <a class="btn btn-link text-dark px-2 mb-0 w-50" @click="menusetDetail(value.id)">
                                <i
                                  class="fas fa-pencil-alt text-dark me-2"
                                  aria-hidden="true"
                                ></i
                                >Edit
                              </a>
                              <a class="btn btn-link text-dark px-2 mb-0 w-25">
                                <i class="material-icons" style="font-size: 18px"
                                  >visibility</i
                                >
                              </a>
                              <!-- <a class="btn btn-link text-dark px-2 mb-0 w-25">
                                <i class="material-icons" style="font-size: 18px"
                                  >more_horiz_icon</i
                                >
                              </a> -->
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="10" class="text-center">No item available</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <pagination
                    v-if="pagination.last_page > 1"
                    :pagination="pagination"
                    :current_page="pagination.current_page"
                    :last_page="pagination.last_page"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
    </div>
  </div>
  <CreateModalMenu></CreateModalMenu>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Pagination from "@/components/Pagination.vue";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import "@vueform/multiselect/themes/default.css";
import MaterialToggleButton from "@/components/MaterialToggleButton.vue";
import MenusetService from "../../services/product/menuset.service";
import CreateModalMenu from "@/components/CreateModalMenu.vue";

export default {
  name: "campaign-list",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    Pagination,
    MaterialToggleButton,
    CreateModalMenu,
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      pagination: {},
      page: 1,
      isLoadData: false,
      isActive: true,
      menuset: [],
      isShowInactiveMenu: true,
    };
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    this.getList(this.page);
  },
  methods: {
    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    getList(page) {
      this.isLoadData = false;
      this.showLoader();
      var dataForm = {
        page: page,
      };
      MenusetService.getList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.menuset = response.data.data.items;
            this.pagination = response.data.data;
            this.page = this.pagination.current_page;
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
        }
      );
      this.hideLoader();
    },

    menusetDetail(id) {
      this.$router.push('/menu-set/detail/' + id);
    },

    triggerEvent(value) {
      if (typeof value == "boolean") {
        this.isActive = !this.isActive;
        this.isShowInactiveMenu = !value;
      }
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
<style scoped>
.css-icon {
  position: absolute;
  left: 17px !important;
  font-size: 18px !important;
  top: 7px !important;
}

.label-header {
  font-size: 16px;
  color: #222;
  font-weight: 500;
}
</style>
