import api from "./api";

class EmailSetupService {
  getList(dataForm) {
    return api.get("/merchant/email-setup", { params: dataForm });
  }

  emailDetail(id) {
    return api.get("/merchant/email-setup/" + id);
  }

  getFilterData() {
    return api.post("/merchant/email-setup/get-filter-data");
  }

  getMembers(dataForm) {
    return api.post("/merchant/email-setup/get-members", dataForm);
  }

  saveRecipientGroup(dataForm) {
    return api.post("/merchant/email-setup/add-recipient-group", dataForm);
  }

  saveEmail(dataForm) {
    return api.post("/merchant/email-setup", dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  updateEmail(dataForm) {
    return api.post("/merchant/email-setup/update/" + dataForm.id, dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getRecipientGroups() {
    return api.post("/merchant/email-setup/get-recipient-group");
  }

  deleteEmail(id) {
    return api.delete("/merchant/email-setup/" + id);
  }

  enableDisableEmail(dataForm) {
    return api.post("/merchant/email-setup/enable-disable", dataForm);
  }

  resend(id) {
    return api.post("/merchant/email-setup/resend/" + id);
  }

  makeACopy(id) {
    return api.post("/merchant/email-setup/make-a-copy/" + id);
  }
}

export default new EmailSetupService();
