import api from './api';

class ProductService {
  getDataProducts(dataForm) {
    return api.post('/merchant/products', dataForm);
  }

  createNewProduct() {
    return api.post('/merchant/product');
  }

  getDataProductDetail(productId) {
    return api.post('/merchant/product/' + productId);
  }

  addOrUpdateProduct(dataForm) {
    return api.post('/merchant/product-create-or-update', dataForm, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  deleteProduct(productId) {
    return api.post('/merchant/product/delete/' + productId);
  }

  getProductDropdown() {
    return api.post('/merchant/get-product-dropdown');
  }
}

export default new ProductService();
