<template>
    <div class="page-header align-items-start">
        <span class="mask bg-white"></span>
        <div class="container" style="padding-left: 10px; padding-right: 10px;">
            <div class="row">
                <div class="notifications">
                <material-alert
                    v-if="error"
                    color="danger"
                    icon="ni ni-like-2 ni-lg"
                    dismissible
                >
                    {{ error }}
                </material-alert>
                </div>
                <div style="display: inline-flex; margin-top: 10px; gap: 10px">
                    <div class="col-lg-3 col-md-3 col-3" style="height: 100%; border: 1px solid gray;text-align: center;padding-top: 10px; overflow-y: auto;">
                        <material-button
                            @click="handleAddModifierOptionGroup()"
                            style="width: 90%;margin-top: 5px;"
                            class="btn btn-sm btn-background"
                            >Add Option Group</material-button
                        >
                        <div v-for="(value, key) in modifierOptionGroupList" :key="key">
                            <div style="width: 100%;display: inline-flex;">
                                <div style="width: 90%;">
                                    <material-button
                                        @click="handleEditModifierOptionGroup(value)"
                                        :class="item.id == value.id ? 'btn btn-sm btn-background button-edit-modifier-group-active' : 'btn btn-sm btn-background button-edit-modifier-group'"
                                        >{{ value.name }} <i :class="item.id == value.id ? 'fas fa-pencil-alt' : 'fas fa-pencil-alt text-dark'" style="font-size: 10px;"></i></material-button
                                    >
                                </div>
                                <div style="width: 10%;;">
                                    <a
                                        class="
                                        btn btn-link
                                        text-danger text-gradient
                                        "
                                        href="javascript:;"
                                        @click="confirmDeleteModifierOptionGroup(value.id)"
                                        style="padding: 0px;margin-top: 15px"
                                    >
                                        <i
                                        class="far fa-trash-alt me-2"
                                        aria-hidden="true"
                                        ></i
                                        >
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!loaderActive" class="col-lg-9 col-md-9 col-9" style="height: 100%; border: 1px solid gray; padding: 20px; padding-top: 15px; overflow-y: auto;">
                        <h5>OPTION GROUP DETAIL</h5>
                        <div style="display: inline-flex;width: 100%;align-items: center;">
                            <div class="col-lg-3 col-md-3 col-3">Option Group Name</div>
                            <div class="col-lg-9 col-md-9 col-9">
                                <material-input
                                    v-model="item.name"
                                    type="text"
                                    id="menuset_name"
                                    name="menuset_name"
                                    placeholder="Enter name"
                                />
                            </div>
                        </div>
                        <div style="display: inline-flex;width: 100%;align-items: center; margin-top: 5px;">
                            <div class="col-lg-3 col-md-3 col-3">Option Group POS Name</div>
                            <div class="col-lg-9 col-md-9 col-9">
                                <material-input
                                    v-model="item.pos_name"
                                    type="text"
                                    id="menuset_name"
                                    name="menuset_name"
                                    placeholder="Enter name"
                                />
                            </div>
                        </div>
                        <div style="display: inline-flex;width: 100%;align-items: center; margin-top: 5px;">
                            <div class="col-lg-3 col-md-3 col-3">Button color</div>
                            <div class="col-lg-3 col-md-3 col-3">
                                <color-input v-model="item.button_color" position="right bottom"/>
                            </div>
                            <div class="col-lg-3 col-md-3 col-3">Button font color</div>
                            <div class="col-lg-3 col-md-3 col-3">
                                <color-input v-model="item.button_font_color" position="left bottom"/>
                            </div>
                        </div>
                        <div style="border-bottom: 1px solid gray; width: 100%; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div v-for="(value, key) in modifierGroupArray" :key="key" style="border: 1px solid black;padding: 5px;margin-bottom: 10px;">
                            <div style="display: inline-flex;width: 100%;align-items: center; margin-top: 5px;">
                                <div class="col-lg-3 col-md-3 col-3">Applied Modifier Group</div>
                                <div class="col-lg-5 col-md-5 col-5">
                                    <treeselect
                                        @update:model-value="onchangeGroupOrSubgroup"
                                        show-count="true"
                                        v-model="value.applied_modifier_group_id"
                                        :multiple="false"
                                        :options="appliedDropdownData"
                                        :flat="true"
                                        :auto-deselect-ancestors="false"
                                        :auto-deselect-descendants="false"
                                        :auto-select-ancestors="false"
                                        :auto-select-descendants="false"
                                        >
                                        <template v-slot:option-label="{ node, labelClassName }">
                                            <label :class="labelClassName">
                                            {{ node.label }}
                                            </label>
                                        </template>
                                        <template v-slot:value-label="{ node }">
                                            <span class="custom-value-label">{{ checkStringType(node.id)+" "+node.label }}</span>
                                        </template>
                                    </treeselect>
                                </div>
                                <div class="col-lg-4 col-md-4 col-4" style="display: inline-flex;justify-content: center;">
                                    <material-button
                                        v-if="key == 0"
                                        @click="addMoreModifierGroup()"
                                        class="btn btn-sm btn-background"
                                        >
                                            <i class="material-icons css-icon">addoutlined</i>
                                            Add Modifier Group
                                        </material-button
                                    >
                                    <material-button
                                        v-else
                                        @click="removeMoreModifierGroup(key)"
                                        class="btn btn-sm btn-background"
                                        >
                                            <i class="material-icons css-icon">removeoutlined</i>
                                            Remove Modifier Group
                                        </material-button
                                    >
                                </div>
                            </div>
                            <div style="display: inline-flex;width: 100%;align-items: center; margin-top: 15px;">
                                <div class="col-lg-3 col-md-3 col-3">Price list</div>
                                <div class="col-lg-9 col-md-9 col-9">
                                    <Multiselect
                                        @change="onchangePriceList($event)"
                                        v-model="value.price_list_id"
                                        :options="priceList"
                                        :canClear="false"
                                    />
                                </div>
                            </div>
                            <div style="display: inline-flex;width: 100%;align-items: center; margin-top: 15px;">
                                <div class="col-lg-3 col-md-3 col-3">Item Max</div>
                                <div class="col-lg-2 col-md-2 col-2">
                                    <material-input
                                        v-model="value.item_max_qty"
                                        type="text"
                                        placeholder="Enter"
                                    />
                                </div>
                                <!-- <div class="col-lg-1 col-md-1 col-1"></div> -->
                                <div class="col-lg-2 col-md-2 col-2" style="text-align: right;padding-right: 20px;">Min</div>
                                <div class="col-lg-1 col-md-1 col-1">
                                    <material-input
                                        v-model="value.min_qty"
                                        type="text"
                                        placeholder="Enter"
                                    />
                                </div>
                                <!-- <div class="col-lg-1 col-md-1 col-1"></div> -->
                                <div class="col-lg-2 col-md-2 col-2" style="text-align: right;padding-right: 20px;">Max</div>
                                <div class="col-lg-1 col-md-1 col-1">
                                    <material-input
                                        v-model="value.max_qty"
                                        type="text"
                                        placeholder="Enter"
                                    />
                                </div>
                            </div>
                            <div style="display: inline-flex;width: 100%;align-items: center; margin-top: 15px;">
                                <div class="col-lg-3 col-md-3 col-3">Dining Option</div>
                                <div class="col-lg-2 col-md-2 col-2">
                                    <Multiselect
                                        v-model="value.dining_option"
                                        :options="diningOptionDataList"
                                        :canClear="false"
                                    />
                                </div>
                                <!-- <div class="col-lg-1 col-md-1 col-1"></div> -->
                                <div class="col-lg-2 col-md-2 col-2" style="text-align: right;padding-right: 20px;">Remark</div>
                                <div class="col-lg-1 col-md-1 col-1" style="text-align: center;">
                                    <material-checkbox
                                        :checked="value.is_remark"
                                        v-model="value.is_remark"
                                        name="remark"
                                        class="align-items-center"
                                    ></material-checkbox>
                                </div>
                                <!-- <div class="col-lg-1 col-md-1 col-1"></div> -->
                                <div class="col-lg-2 col-md-2 col-2" style="text-align: right;padding-right: 20px;">Required?</div>
                                <div class="col-lg-1 col-md-1 col-1" style="text-align: center;">
                                    <material-checkbox
                                        :checked="value.is_required"
                                        v-model="value.is_required"
                                        name="required"
                                        class="align-items-center"
                                    ></material-checkbox>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 20px">
                            <h5>APPLIED PRICE</h5>

                            <div style="padding: 5px;border: 1px solid black;margin-top: 10px;" v-for="(value, key) in appliedPriceList" :key="key">
                                <div class="col-lg-12 col-md-12 col-12">{{ value.title }}</div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-left font-weight-bolder w-10" style="padding: 0.5rem 0.5rem !important;">
                                            Code
                                            </th>
                                            <th class="text-left font-weight-bolder w-10" style="padding: 0.5rem 0.5rem !important;">
                                            Name
                                            </th>
                                            <th class="text-left font-weight-bolder w-10" style="padding: 0.5rem 0.5rem !important;">
                                            Image
                                            </th>
                                            <th class="text-left font-weight-bolder w-10" style="padding: 0.5rem 0.5rem !important;">
                                            Price
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="value.items && value.items.length">
                                        <tr v-for="(value, key) in value.items" :key="key">
                                            <td
                                                class="text-left text-xss w-10"
                                                >
                                                {{ value.item_no_format }} {{ value.variation_no ? "( "+value.variation_no+" )" : "" }} 
                                            </td>
                                            <td
                                                class="text-left text-xss w-10"
                                                >
                                                {{ value.item_name_format }}
                                            </td>
                                            <td
                                                class="text-left text-xss w-10"
                                                >
                                                <img :src="value.image" alt="Image" width="70" height="70">
                                            </td>
                                            <td
                                                class="text-left text-xss w-10"
                                                >
                                                {{ value.item_price_format }}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                        <td colspan="5" class="text-center">No item available</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div style="margin-top: 20px">
                            <div style="display: inline-flex;align-items: center;">
                                <h5 style="margin-top: 11px;margin-right: 10px;">ATTACHED TO ITEM</h5>
                                <material-button
                                    @click="addItem()"
                                    class="btn btn-sm btn-background"
                                    >
                                        Add Items
                                        {{ dataPreiewItem && dataPreiewItem.itemsId && dataPreiewItem.itemsId.length > 0 ? "("+dataPreiewItem.itemsId.length+" item selected)" : "" }}
                                    </material-button
                                >
                            </div>

                            <table class="table">
                                <thead>
                                <tr>
                                    <th class="text-center font-weight-bolder w-5">
                                        <!-- variation no -->
                                    </th>
                                    <th class="text-center font-weight-bolder w-5">
                                    Item
                                    </th>
                                    <th class="text-center font-weight-bolder w-20">
                                    Price
                                    </th>
                                    <th class="text-center font-weight-bolder w-20">
                                    Group
                                    </th>
                                    <th class="text-center font-weight-bolder w-20">
                                    <!-- Item image -->
                                    </th>
                                    <th class="text-center font-weight-bolder w-20">
                                    <!-- Delete -->
                                    </th>
                                </tr>
                                </thead>
                                <tbody v-if="attachedToItem.length">
                                    <tr v-for="(value, key) in attachedToItem" :key="key">
                                        <td
                                            class="text-center text-xss w-25"
                                            >
                                            {{ value.item_no }}
                                        </td>
                                        <td
                                            class="text-center text-xss w-25"
                                            >
                                            {{ value.item_name }} {{ value.variation_name ? "("+value.variation_name+")" : "" }} 
                                        </td>
                                        <td
                                            class="text-center text-xss w-25"
                                            >
                                            {{ value.item_price }}
                                        </td>
                                        <td
                                            class="text-center text-xss w-25"
                                            >
                                            {{ value.group_name }}
                                        </td>
                                        <th class="text-center text-xss w-25">
                                            <img :src="value.item_image" alt="Image" width="70" height="70">
                                        </th>
                                        <th 
                                            class="text-center text-xss w-25"
                                            >
                                            <a
                                                class="
                                                btn btn-link
                                                text-danger text-gradient
                                                px-3
                                                mb-0
                                                "
                                                href="javascript:;"
                                                @click="confirmDelete(value.id)"
                                            >
                                                <i
                                                class="far fa-trash-alt me-2"
                                                aria-hidden="true"
                                                style="margin-top: 20px;"
                                                ></i
                                                >
                                            </a>
                                        </th>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                    <td colspan="5" class="text-center">No item available</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2 message-success">
            <material-snackbar
                v-if="snackbar === 'danger' || snackbar === 'success'"
                title=""
                date=""
                :description="message"
                :icon="{ component: 'campaign', color: 'white' }"
                :color="snackbar"
                :close-handler="closeSnackbar"
            />
        </div>
        <material-loading :active="loaderActive" />
    </div>
    <div class="container" style="margin-bottom: 100px; margin-top: 20px;text-align: center;">
        <material-button
            @click="handleSaveAll()"
            style="width: 10%;"
            class="btn btn-sm btn-background"
            >Save</material-button
        >
    </div>
    
</template>
  
<script>
    import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
    import "bootstrap/dist/js/bootstrap.bundle";
    import MaterialLoading from "@/components/MaterialLoading.vue";
    import "bootstrap/js/dist/modal";
    import MaterialButton from "@/components/MaterialButton.vue";
    import "@vuepic/vue-datepicker/dist/main.css";
    //   import ModalConfirm from "@/components/ModalConfirm.vue";
    import "@vueform/multiselect/themes/default.css";
    import MaterialInput from "@/components/MaterialInput.vue";
    //   import $ from "jquery";
    import TokenService from "../../services/token.service";
    //   import MaterialRadio from "@/components/MaterialRadio.vue";
    import ColorInput from "vue-color-input";
    import Multiselect from "@vueform/multiselect";
    import ModifierOptionGroupService from '../../services/product/modifierOptionGroup';
    import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
    import Treeselect from 'vue3-treeselect'
    import 'vue3-treeselect/dist/vue3-treeselect.css'
    import $ from "jquery";
  
  export default {
    name: "pos-staff",
    components: {
        MaterialSnackbar,
        MaterialLoading,
        MaterialButton,
        //   ModalConfirm,
        MaterialInput,
        //   MaterialRadio,
        ColorInput,
        Multiselect,
        MaterialCheckbox,
        Treeselect,
    },
    props: {
        menu_set_id: {
            type: Number,
            default: 0
        },
        reloadModifierOptionGroup: {
            type: String,
            required: false
        },
        dataPreiewItemSelected: {
            type: Array,
            required: false
        },
        date_time_now: {
            type: Object,
            required: false
        },
    },
    watch: {
        reloadModifierOptionGroup(newData, oldData) {
            console.log(oldData);
            console.log(newData);
            this.getData();
            this.getAttachedToItem();
        },
        dataPreiewItemSelected(newData, oldData) {
            console.log(oldData);
            console.log(newData);
            this.dataPreiewItem = newData;
        },
        date_time_now() {
            // this.getData();
        },
        'modifierGroupArray': {
            handler(newVal) {
                newVal.forEach((value, index) => {
                if (!Array.isArray(value.applied_modifier_group_id)) {
                    if(value.applied_modifier_group_id) {
                        this.modifierGroupArray[index].applied_modifier_group_id = [value.applied_modifier_group_id];
                    }
                }
                });
            },
            deep: true,
        },
    },
    data() {
        return {
            message: "",
            snackbar: null,
            loaderActive: false,
            modifierOptionGroupList: [],
            priceList: [],
            token: TokenService.getLocalAccessToken(),
            merchantCodeExport: localStorage.getItem("MERCHANT_CODE", ""),

            item: {
                id: 0,
                name: '',
                pos_name: '',
                button_color: '#000000',
                button_font_color: '#ff0000'
            },

            diningOptionDataList: [
                { 'label': 'All', 'value': 1 },
                { 'label': 'Dine in', 'value': 2 },
                { 'label': 'Takeaway', 'value': 3 }
            ],

            appliedDropdownData: [],
            appliedPriceList: [],
            attachedToItem: [],
            keyReload: 'key-reload',
            dataPreiewItem: [],
            modifierGroupArray: [
                {
                    applied_modifier_group_id: null,
                    price_list_id: "",
                    item_max_qty: "",
                    min_qty: "",
                    max_qty: "",
                    dining_option: "",
                    is_remark: 0,
                    is_required: 0
                }
            ]
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.showLoader();
            var data = {
                set_id: this.menu_set_id
            }
            ModifierOptionGroupService.getList(data).then(
            (response) => {
                if (response.data.result.isSuccess) {
                    this.modifierOptionGroupList = response.data.data.item;
                    console.log("response.data.data.price_list", response.data.data.price_list)
                    this.priceList = response.data.data.price_list;
                    const newArrayElement = {
                        "price_key": "price_0",
                        "name": "All Price List",
                        "description": "",
                        "is_display": 1,
                        "price": 0,
                        "label": "All Price List",
                        "value": "price_0"
                    };
                    this.priceList.unshift(newArrayElement);
                    this.appliedDropdownData = response.data.data.categories;
                    // console.log(this.appliedDropdownData);
                    // this.appliedDropdownData.unshift({ 
                    //     label: 'SELECT MODIFIER GROUP',
                    //     id: '0',
                    //     id_query: 0,
                    //     label_converted: 'SELECT MODIFIER GROUP'
                    // });

                    this.resetParam();

                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
            },
            (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.setTimeOutNotification();
            }
            );
            this.hideLoader();
        },

        handleEditModifierOptionGroup(item) {
            // console.log(item);
            this.showLoader();
            this.item.id = item.id;
            this.item.name = item.name;
            this.item.pos_name = item.pos_name;
            this.item.button_color = item.button_color;
            this.item.button_font_color = item.button_font_color;
            this.item.dataPreiewItem = [];
            this.modifierGroupArray = item.modifierGroupArray;
            setTimeout(() => {
                this.hideLoader();
                this.getAppliedPrice();
                this.getAttachedToItem();
            }, 1000);
        },

        handleAddModifierOptionGroup() {
            this.resetParam();
        },

        resetParam() {
            this.showLoader();
            this.item.id = 0,
            this.item.name = '',
            this.item.pos_name = '',
            this.item.button_color = '#000000',
            this.item.button_font_color = '#ff0000',
            this.appliedPriceList = [];
            this.attachedToItem = [];
            this.dataPreiewItem = [];
            this.modifierGroupArray = [
                {
                    applied_modifier_group_id: null,
                    price_list_id: "",
                    item_max_qty: "",
                    min_qty: "",
                    max_qty: "",
                    dining_option: "",
                    is_remark: 0,
                    is_required: 0
                }
            ];
            setTimeout(() => {
                this.hideLoader();
            }, 1000);
        },

        handleSaveAll() {
            var checkError = false;
            this.showLoader();
            var data = {
                name: this.item.name,
                pos_name: this.item.pos_name,
                button_color: this.item.button_color,
                button_font_color: this.item.button_font_color,
                set_id: this.menu_set_id,
                modifierGroupArray: this.modifierGroupArray
            };

            if (!this.item.name) {
                checkError = true;
                this.snackbar = "danger";
                this.message = "The Name is required.";
                this.setTimeOutNotification();
                this.hideLoader();
                return;
            }

            if (!this.item.pos_name) {
                checkError = true;
                this.snackbar = "danger";
                this.message = "The POS Name is required.";
                this.setTimeOutNotification();
                this.hideLoader();
                return;
            }

            if (!this.item.button_color) {
                checkError = true;
                this.snackbar = "danger";
                this.message = "The Button color is required.";
                this.setTimeOutNotification();
                this.hideLoader();
                return;
            }

            if (!this.item.button_font_color) {
                checkError = true;
                this.snackbar = "danger";
                this.message = "The Button font color is required.";
                this.setTimeOutNotification();
                this.hideLoader();
                return;
            }

            data.modifierGroupArray.forEach((modifierGroup) => {
                // if(!modifierGroup || !modifierGroup.applied_modifier_group_id || (modifierGroup && modifierGroup.applied_modifier_group_id && modifierGroup.applied_modifier_group_id.length == 0)) {
                if(!modifierGroup || !modifierGroup.applied_modifier_group_id || (modifierGroup && modifierGroup.applied_modifier_group_id == 0)) {
                    checkError = true;
                    this.snackbar = "danger";
                    this.message = "The Applied Modifier Group is required.";
                    this.setTimeOutNotification();
                    this.hideLoader();
                    return;
                }
                if (!modifierGroup.price_list_id) {
                    checkError = true;
                    this.snackbar = "danger";
                    this.message = "The Price list is required.";
                    this.setTimeOutNotification();
                    this.hideLoader();
                    return;
                }
                if (!modifierGroup.item_max_qty) {
                    checkError = true;
                    this.snackbar = "danger";
                    this.message = "The Item Max is required.";
                    this.setTimeOutNotification();
                    this.hideLoader();
                    return;
                }
                if (!modifierGroup.min_qty) {
                    checkError = true;
                    this.snackbar = "danger";
                    this.message = "The Min is required.";
                    this.setTimeOutNotification();
                    this.hideLoader();
                    return;
                }
                if (!modifierGroup.max_qty) {
                    checkError = true;
                    this.snackbar = "danger";
                    this.message = "The Max is required.";
                    this.setTimeOutNotification();
                    this.hideLoader();
                    return;
                }
                if (!modifierGroup.dining_option) {
                    checkError = true;
                    this.snackbar = "danger";
                    this.message = "The Dining Option is required.";
                    this.setTimeOutNotification();
                    this.hideLoader();
                    return;
                }
            });
            // console.log(data);
            if(checkError === false) {
                if(this.item.id && this.item.id > 0) {
                    // update
                    ModifierOptionGroupService.update(this.item.id, data).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            this.snackbar = "success";
                            this.message = "Updated Modifier Option Group successfully!";
                            this.setTimeOutNotification();
                            // this.resetParam();
                            this.getData();
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                        }
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.setTimeOutNotification();
                    }
                    );
                } else {
                    // add
                    ModifierOptionGroupService.store(data).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            this.snackbar = "success";
                            this.message = "Added Modifier Option Group successfully!";
                            this.setTimeOutNotification();
                            this.dataPreiewItem.modifier_option_group_id = response.data.data.id;
                            this.addAttachedToItem(this.dataPreiewItem);
                            this.getData();
                            this.resetParam();
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                        }
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.setTimeOutNotification();
                    }
                    );
                }
            }
            this.hideLoader();
        },

        closeSnackbar() {
            this.snackbar = null;
        },
  
        setTimeOutNotification() {
            setTimeout(() => {
            this.snackbar = null;
            }, 3000);
        },
  
        showLoader() {
            this.loaderActive = true;
        },
  
        hideLoader() {
            this.loaderActive = false;
        },

        checkStringType(str) {
            if (str.includes("sg")) {
                return "Sub Group : ";
            } else if (str.includes("g")) {
                return "Group : ";
            } else if (str.includes("c")) {
                return "Category : ";
            } else {
                return '';
            }
        },

        onchangeGroupOrSubgroup(value) {
            if(value != '0') {
                this.getAppliedPrice()
            } else {
                this.appliedPriceList = [];
            }
        },

        onchangePriceList($event) {
            console.log($event);
            this.getAppliedPrice()
        },

        getAppliedPrice() {
            this.showLoader();
            var data = {
                modifierGroupArray: this.modifierGroupArray
            }
            ModifierOptionGroupService.getAppliedPriceList(data).then(
            (response) => {
                if (response.data.result.isSuccess) {
                    console.log("response.data.data", response.data.data);
                    this.appliedPriceList = response.data.data;
                } else {
                    this.appliedPriceList = [];
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
            },
            (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.setTimeOutNotification();
            }
            );
            this.hideLoader();
        },

        closeModal() {
            $(".btn-close-modal").click();
        },

        addItem() {
            var data = {
                modifier_option_group_id: this.item.id,
                attachedToItem: this.attachedToItem
            }
            this.$emit('child-event', data);
        },

        getAttachedToItem() {
            if(this.item.id > 0) {
                this.showLoader();
                var data = {
                    modifier_option_group_id: this.item.id
                }
                ModifierOptionGroupService.getAttachedToItem(data).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.attachedToItem = response.data.data;
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.setTimeOutNotification();
                }
                );
                this.hideLoader();
            }
        },

        confirmDelete(itemId) {
            this.$emit('delete-item', itemId);
        },

        addAttachedToItem(data) {
            console.log("dataAddAttachedToItem", data)
            if(data.modifier_option_group_id > 0 && (data.itemsId && data.itemsId.length > 0)) {
                ModifierOptionGroupService.attachedToItem(data).then(
                    (response) => {
                    if (response.data.result.isSuccess) {
                        // alert("success");
                        // $(".btn-close-modal").click();
                        // this.$emit("reload-list-attached-to-item", "Add success");
                    }
                    this.hideLoader();
                },
                    (error) => {
                    this.message = error;
                    this.hideLoader();
                    }
                );
            }
        },

        confirmDeleteModifierOptionGroup(itemId) {
            this.$emit('delete-modifier-option-group', itemId);
        },

        addMoreModifierGroup() {
            const newModifierGroup = {
                applied_modifier_group_id: null,
                price_list_id: "",
                item_max_qty: "",
                min_qty: "",
                max_qty: "",
                dining_option: "",
                is_remark: 0,
                is_required: 0
            };

            this.modifierGroupArray.push(newModifierGroup);
        },

        removeMoreModifierGroup(index) {
            if (index >= 0 && index < this.modifierGroupArray.length) {
                this.modifierGroupArray.splice(index, 1);
                this.getAppliedPrice()
            }
        }
    },
  };
  </script>
  <style scoped>
    .button-edit-modifier-group {
        width: 90%;margin-top: 5px;border: 0px !important;border-bottom: 1px solid #000000 !important;border-radius: 0px;text-align: left;padding-left: 0px !important;
        text-transform: none;
    }
    .button-edit-modifier-group-active {
        width: 90%;margin-top: 5px;border: 0px !important;border-bottom: 1px solid #000000 !important;border-radius: 0px;text-align: left;padding-left: 0px !important;
        background-color: #82001e !important;
        color: white !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        text-transform: none;
    }
    .table tbody tr:last-child td {
        border-bottom-width: 1px;
    }
    .css-icon {
        position: absolute;
        left: 17px !important;
        font-size: 18px !important;
        top: 7px !important;
    }
  </style>
  