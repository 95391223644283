import api from './api';

class SmsSetupService {
    getSmsBal() {
        return api.get('/merchant/get-sms-bal');
    }
    checkoutSessionStripeForSms(dataForm) {
        return api.post('/merchant/checkout-session-stripe-for-sms', dataForm);
    }
    addSmsBal(dataForm) {
        return api.post('/merchant/add-sms-bal', dataForm);
    }
    getSmsPaymentHistory() {
        return api.get('/merchant/get-sms-payment-history');
    }
    closeWarningSms() {
        return api.post('/merchant/close-warning-sms');
    }
}

export default new SmsSetupService();
  