import api from './api';

class DashboardService {
  getData(dataForm) {
    return api.post('/merchant', dataForm);
  }

  getDataDashboardAllTime(dataForm) {
    return api.post('/merchant/data-dashboard-all-time', dataForm);
  }

  config(dataForm) {
    return api.post('/merchant/dashboard-config', dataForm);
  }
}

export default new DashboardService();