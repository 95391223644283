import api from './api';

class SettingSlideService {
  getDataSlide(dataForm) {
    return api.post('/merchant/slide-setting', dataForm);
  }

  addOrUpdateSlide(dataForm) {
    return api.post('/merchant/slide-setting/create-or-update', dataForm, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    });
  }

  updateSlidesOrder(dataForm) {
    return api.post("/merchant/slide-setting/update-slides-order", dataForm);
  }
}

export default new SettingSlideService();
