<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#modalCreateGroupAndSubGroup"
      id="show-modal-create-group-and-sub-group"
    ></material-button>
    <div
      class="modal fade"
      id="modalCreateGroupAndSubGroup"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel" v-if="!isUpdate && !isUpdateSubGroup && groupId == 0 && subGroupId == 0">
              Add Group
            </h5>
            <h5 class="modal-title" id="exampleModalLabel" v-if="isUpdate && !isUpdateSubGroup && groupId > 0 && subGroupId == 0">
              Update Group
            </h5>
            <h5 class="modal-title" id="exampleModalLabel" v-if="!isUpdate && !isUpdateSubGroup && groupId > 0 && subGroupId == 0">
              Add Sub Group
            </h5>
            <h5 class="modal-title" id="exampleModalLabel" v-if="!isUpdate && isUpdateSubGroup && groupId == 0 && subGroupId > 0">
              Update Sub Group
            </h5>
            <!-- <h5 class="modal-title" id="exampleModalLabel">
              {{ groupId ? (isUpdate && groupId > 0 ? "Edit Sub-Group" : "Add Sub-Group") : (isUpdate && groupId > 0 ? "Edit Group" : "Add Group") }}
            </h5> -->
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal justify-content-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              >clear</i
            >
          </div>
          <div class="modal-body" style="overflow-y: unset">
            <div
              class="w-100"
              :class="{ 'mb-5': !fieldRequired.isGroupNameRequire }"
            >
              <label class="fw-bold" for=""
                >Group Name
                <span class="text-danger ms-2">*</span>
              </label>
              <material-input
                v-model="group.name"
                type="text"
                id="group_name"
                name="group_name"
                placeholder="Enter name"
              />
            </div>
            <div class="w-100 mb-5">
              <span class="is-invalid" v-if="fieldRequired.isGroupNameRequire"
                >The field is required.</span
              >
            </div>
            <div class="w-100 mb-5">
              <label class="fw-bold" for="">Group image</label>
              <div class="dowload-img" v-show="urlImagePreview || group.image">
                <div id="preview" style="position: relative">
                  <img
                    class="image-url"
                    v-if="urlImagePreview || group.image"
                    :src="urlImagePreview || group.image"
                  />
                  <i
                    class="fa fa-times icon-close-sidenav-main danger"
                    v-if="urlImagePreview || group.image"
                    style="
                      position: absolute;
                      color: #f44335;
                      font-size: 20px;
                      right: 5px;
                      top: 8px;
                    "
                    aria-hidden="true"
                    @click="clearImage()"
                  ></i>
                  <div class="btn-edit-img">
                    <i
                      class="fas fa-pencil-alt text-dark"
                      v-if="urlImagePreview || group.image"
                      aria-hidden="true"
                      @click="uploadFile()"
                    ></i>
                  </div>
                </div>
              </div>
              <div
                class="dowload-img"
                @click="uploadFile()"
                v-show="!urlImagePreview && !group.image"
              >
                <material-input
                  style="display: none"
                  type="file"
                  id="imageGroup"
                  @change="handleFileUpload($event)"
                  accept="image/*"
                />
                <i class="material-icons css-icon-upload">backup</i>
                <h6>Click here to upload an image.</h6>
              </div>
              <div
                class="is-invalid d-flex align-items-center pe-2"
                v-if="fieldRequired.isImageRequire"
              >
                {{ messageImageError }}
              </div>
            </div>
            <div
              class="w-100"
            >
              <label class="fw-bold" for=""
                >Status
              </label>
              <div class="d-flex">
                <MaterialRadio
                  id="status_1"
                  value="1"
                  class="ps-0"
                  v-model="group.status"
                >
                  Active
                </MaterialRadio>
                <MaterialRadio
                  id="status_2"
                  value="0"
                  v-model="group.status"
                >
                  Inactive
                </MaterialRadio>
              </div>
            </div>
            <div class="d-flex mt-5">
              <material-button
                class="mx-2 btn btn-sm btn-background"
                @click="saveGroup()"
              >
                Save
              </material-button>
              <material-button
                class="mx-2 btn btn-sm btn-background"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <material-loading :active="loaderActive" />
  </div>
</template>
<script>
import $ from "jquery";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import ModifierGroupService from "../services/product/modifierGroup.service";

export default {
  name: "create-group-and-sub-group",
  components: {
    MaterialInput,
    MaterialButton,
    MaterialLoading,
    MaterialRadio
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    setId: {
      type: Number,
      required: true,
    },
    categoryId: {
      type: Number,
      required: true,
    },
    groupId: {
      type: Number,
      required: false,
      default: 0
    },
    subGroupId: {
      type: Number,
      required: false,
      default: 0
    },
    type: {
      type: Number,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      required: false,
      default: false
    },
    isUpdateSubGroup: {
      type: Boolean,
      required: false,
      default: false
    },
    date_time_now: {
      type: Object,
      required: false
    },
  },
  data() {
    return {
      urlImagePreview: null,
      isErrorUploadImage: false,
      isImageTooSmall: false,
      messageImageError: "",
      fieldRequired: {},
      loaderActive: false,
      group: {
        id: 0,
        name: "",
        image: null,
        set_id: this.setId,
        category_id: this.categoryId,
        parent_group_id: this.groupId,
        type: this.type,
        status: 1,
      },
    };
  },
  methods: {
    uploadFile() {
      $("#imageGroup").click();
    },

    clearImage() {
      this.group.image = null;
      this.urlImagePreview = null;
      this.fieldRequired.isImageRequire = false;
      this.isErrorUploadImage = false;
      this.isImageTooSmall = false;
    },

    handleFileUpload(event) {
      this.isImageTooSmall = false;
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        this.group.image = null;
        this.urlImagePreview = null;
        if (
          !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
            file.type
          )
        ) {
          this.isErrorUploadImage = false;
          this.fieldRequired.isImageRequire = true;
          this.messageImageError = `File must be an image.`;
        } else {
          this.group.image = file;
          this.urlImagePreview = URL.createObjectURL(file);
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (evt) => {
            let img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              if (width < 100 || height < 100) {
                this.messageImageError = `The image width ${width} x ${height} is too small.`;
                this.fieldRequired.isImageRequire = true;
                this.isErrorUploadImage = true;
                this.isImageTooSmall = true;
              } else {
                this.fieldRequired.isImageRequire = false;
                this.isErrorUploadImage = false;
                this.isImageTooSmall = false;
                this.messageImageError = "";
              }
            };
            img.src = evt.target.result;
          };
        }
      } else {
        this.group.image = null;
        this.urlImagePreview = null;
        this.isErrorUploadImage = false;
        this.messageImageError = "";
      }
    },

    saveGroup() {
      if (this.processValidate()) {
        this.showLoader();
        this.group.set_id = this.setId;
        if(!this.isUpdate && !this.isUpdateSubGroup && this.groupId == 0 && this.subGroupId == 0) {
          // Add Group
          ModifierGroupService.createGroup(this.group).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.group = {
                  id: 0,
                  name: "",
                  image: null,
                  set_id: this.setId,
                  category_id: this.categoryId,
                  parent_group_id: this.groupId,
                  type: this.type,
                  status: 1
                };
                this.urlImagePreview = null;
                $("#imageGroup").val("");
                var mess = '';
                if(this.groupId == 0 && !this.isUpdate && !this.isUpdateSubGroup) {
                  mess = 'Add Group Success';
                }
                if(this.groupId > 0 && !this.isUpdate && !this.isUpdateSubGroup) {
                  mess = 'Add Sub-group Success';
                }
                
                this.$emit("reload-list-category", mess);
                $(".btn-close-modal").click();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        } else if (this.isUpdate && !this.isUpdateSubGroup && this.groupId > 0 && this.subGroupId == 0) {
          // Update Group
          ModifierGroupService.updateGroup(this.group.id, this.group).then(
            (response) => {
              if (response.data.result.isSuccess) {
                // this.group = {
                //   id: 0,
                //   name: "",
                //   image: null,
                //   set_id: this.setId,
                //   category_id: this.categoryId,
                //   parent_group_id: this.groupId,
                //   type: this.type,
                //   status: 1
                // };
                // this.urlImagePreview = null;
                $("#imageGroup").val("");
                var mess = '';
                if(this.groupId > 0 && this.isUpdate && !this.isUpdateSubGroup) {
                  mess = 'Update Group Success';
                }
                if(this.groupId > 0 && !this.isUpdate && this.isUpdateSubGroup) {
                  mess = 'Update Sub-group Success';
                }
                this.$emit("reload-list-category", mess);
                $(".btn-close-modal").click();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        } else if (!this.isUpdate && !this.isUpdateSubGroup && this.groupId > 0 && this.subGroupId == 0) {
          // Add Sub Group
          this.group.parent_group_id = this.groupId;
          //add 
          ModifierGroupService.createGroup(this.group).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.group = {
                  id: 0,
                  name: "",
                  image: null,
                  set_id: this.setId,
                  category_id: this.categoryId,
                  parent_group_id: this.groupId,
                  type: this.type,
                  status: 1
                };
                this.urlImagePreview = null;
                $("#imageGroup").val("");
                var mess = '';
                if(this.groupId == 0 && !this.isUpdate && !this.isUpdateSubGroup) {
                  mess = 'Add Group Success';
                }
                if(this.groupId > 0 && !this.isUpdate && !this.isUpdateSubGroup) {
                  mess = 'Add Sub-group Success';
                }
                
                this.$emit("reload-list-category", mess);
                $(".btn-close-modal").click();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        } else if (!this.isUpdate && this.isUpdateSubGroup && this.groupId == 0 && this.subGroupId > 0) {
          // Update Sub Group
          ModifierGroupService.updateGroup(this.group.id, this.group).then(
            (response) => {
              if (response.data.result.isSuccess) {
                // this.group = {
                //   id: 0,
                //   name: "",
                //   image: null,
                //   set_id: this.setId,
                //   category_id: this.categoryId,
                //   parent_group_id: this.groupId,
                //   type: this.type,
                //   status: 1
                // };
                // this.urlImagePreview = null;
                $("#imageGroup").val("");
                var mess = '';
                if(this.groupId > 0 && this.isUpdate && !this.isUpdateSubGroup) {
                  mess = 'Update Group Success';
                }
                if(this.groupId > 0 && !this.isUpdate && this.isUpdateSubGroup) {
                  mess = 'Update Sub-group Success';
                }
                this.$emit("reload-list-category", mess);
                $(".btn-close-modal").click();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        }
        
      }
    },

    resetRequired() {
      this.fieldRequired.isGroupNameRequire = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.group.name == "" || this.group.name == null) {
        this.fieldRequired.isGroupNameRequire = true;
        isValid = false;
      }

      return isValid;
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    getDetailGroup(group_id) {
      ModifierGroupService.getDetailGroup(group_id).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.group = {
              id: response.data.data.id,
              name: response.data.data.name,
              image: null,
              set_id: response.data.data.set_id,
              category_id: response.data.data.category_id,
              parent_group_id: response.data.data.parent_id,
              type: response.data.data.type,
              status: response.data.data.status,
            };
            this.urlImagePreview = response.data.data.image;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
  },
  watch: {
    type(val) {
      this.group.type = val ?? 1;
    },
    categoryId(val) {
      this.group.category_id = val;
    },
    // groupId(val) {
    //   console.log("group_id", val);
    //   this.group.id = val;
    //   if(val > 0) {
    //     this.getDetailGroup(val);
    //   }
    // },
    // subGroupId(val) {
    //   console.log("sub_group_id", val);
    //   this.group.id = val;
    //   if(val > 0) {
    //     this.getDetailGroup(val);
    //   }
    // },
    date_time_now() {
      this.group = {
        id: 0,
        name: "",
        image: null,
        set_id: this.setId,
        category_id: this.categoryId,
        parent_group_id: this.groupId,
        type: this.type,
        status: 1
      };
      this.urlImagePreview = null;
      
      if(!this.isUpdate && !this.isUpdateSubGroup && this.groupId == 0 && this.subGroupId == 0) {
        // Add Group
      } else if (this.isUpdate && !this.isUpdateSubGroup && this.groupId > 0 && this.subGroupId == 0) {
        // Update Group
        this.getDetailGroup(this.groupId);
      } else if (!this.isUpdate && !this.isUpdateSubGroup && this.groupId > 0 && this.subGroupId == 0) {
        // Add Sub Group
      } else if (!this.isUpdate && this.isUpdateSubGroup && this.groupId == 0 && this.subGroupId > 0) {
        // Update Sub Group
        this.getDetailGroup(this.subGroupId);
      }
    },
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 85vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.modal-body .row {
  margin-top: 10px;
}
.content {
  cursor: pointer;
  text-align: center;
  width: 250px;
  height: 250px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
}
.font-18 {
  font-size: 18px;
}

.dowload-img {
  text-align: center;
  cursor: pointer;
  background-color: #f0f2f5;
  width: 100%;
  height: 300px;
}

.css-icon-upload {
  font-size: 45px !important;
  margin-top: 6rem;
}

.image-url {
  width: 100%;
  height: 300px;
}

.btn-edit-img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #f44335;
  font-size: 14px;
  right: 30px;
  top: 4px;
  background: #ffffff;
  border-radius: 50%;
}
</style>
