import ModuleService from '../services/module.service';

const checkModule = async (to, from, next) => {
  const { meta, name } = to;
  const isActive = meta && meta.active !== undefined ? meta.active : true;

  if (isActive) {
    try {
      const response = await ModuleService.getModule();
      if (response.data.result.isSuccess) {
        if (name === 'Home' && !response.data.data.is_dashboard) {
            next(false);
          } else if (name === 'Test' && !response.data.data.is_pos_simulator) {
            next(false);
          }
          // else if (name === '' && !response.data.data.is_setting) {
          //   next(false);
          // }
          else if (name === 'SettingGeneral' && !response.data.data.is_general) {
            next(false);
          }
          else if (name === 'SettingCashback' && !response.data.data.is_cashback) {
            next(false);
          }
          else if (name === 'DiscountList' && !response.data.data.is_discount) {
            next(false);
          }
          else if (name === 'VoucherList' && !response.data.data.is_voucher_set) {
            next(false);
          }
          else if (name === 'SettingTier' && !response.data.data.is_tier) {
            next(false);
          }
          else if (name === 'SettingSlide' && !response.data.data.is_slides) {
            next(false);
          }
          else if (name === 'StoreList' && !response.data.data.is_branches) {
            next(false);
          }
          else if (name === 'TopUpListSetting' && !response.data.data.is_top_up) {
            next(false);
          }
          else if (name === 'SmsSetup' && !response.data.data.is_sms) {
            next(false);
          }
          // else if (name === 'DashboardReportList' && !response.data.data.is_reports) {
          //   next(false);
          // }
          else if (name === 'DashboardReportList' && !response.data.data.is_voucher_redemption) {
            next(false);
          }
          else if (name === 'DashboardReportSlidesShow' && !response.data.data.is_slide_show) {
            next(false);
          }
          else if (name === 'DashboardReportTopUp' && !response.data.data.is_topup) {
            next(false);
          }
          else if (name === 'DashboardReportVoidTopup' && !response.data.data.is_void_topup) {
            next(false);
          }
          else if (name === 'DashboardReportPosTransaction' && !response.data.data.is_pos_transaction) {
            next(false);
          }
          else if (name === 'CampaignList' && !response.data.data.is_marketing) {
            next(false);
          }
          // else if (name === 'ProductList' && !response.data.data.is_products) {
          //   next(false);
          // }
          else if (name === 'ProductList' && !response.data.data.is_product_list) {
            next(false);
          }
          else if (name === 'ProductGroup' && !response.data.data.is_product_group_list) {
            next(false);
          }
          else if (name === 'MemberList' && !response.data.data.is_loyalty_members) {
            next(false);
          }
          else if (name === 'PaymentList' && !response.data.data.is_payment) {
            next(false);
          }
          else if (name === 'SubscriptionList' && !response.data.data.is_subscription) {
            next(false);
          }
          else {
            next(true);
          }
      }
    } catch (error) {
      console.log(error);
    }
  }
};

export default checkModule;
