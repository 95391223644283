import api from './api';

class PaymentService {
  connectStripeAccount(dataForm, merchant_code) {
    return api.post('/payment/connect-stripe-account', dataForm, {
        headers: {
        'MerchantCode': merchant_code
        }
      }
    );
  }

  disconnectStripeAccount(dataForm) {
    return api.post('/payment/disconnect-stripe-account', dataForm);
  }

  getLinkPayout (connect_account_id, merchant_code) {
    return api.get('/payment/get-link-payout?connect_account_id='+connect_account_id, {
        headers: {
        'MerchantCode': merchant_code
        }
      }
    );
  }
}

export default new PaymentService();
