import api from './api';

class SettingVoucherService {
  getDataVouchers(dataForm) {
    return api.post('/merchant/vouchers', dataForm);
  }

  deleteVoucher(voucherId) {
    return api.post('/merchant/voucher/delete/' + voucherId);
  }

  changeStatus(voucherId) {
    return api.post('/merchant/voucher/change-status/' + voucherId);
  }

  createNewVoucher() {
    return api.post('/merchant/voucher');
  }

  getDataVoucherDetail(voucherId) {
    return api.post('/merchant/voucher/' + voucherId);
  }

  addOrUpdateVoucher(dataForm) {
    return api.post('/merchant/voucher-create-or-update', dataForm, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  changeQuantity(dataForm) {
    return api.post('/merchant/voucher-change-quantity', dataForm);
  }

  voucherExchanged(dataForm) {
    return api.post('/merchant/voucher-exchanged', dataForm);
  }
}

export default new SettingVoucherService();
