import api from './api';

class TestService {
  getData() {
    return api.post('/merchant/data-test');
  }

  checkout(dataForm) {
    return api.post('/pos/submit-order', dataForm);
  }

  getMember(dataForm) {
    return api.get('/pos/get-member?phone_number=' + dataForm.phone_number);
  }

  addTopupAmount(dataForm) {
    return api.post('/pos/topup', dataForm);
  }

  addDirectAmount(dataForm) {
    return api.post('/pos/add-direct-amount', dataForm);
  }

  getDataTopUp() {
    return api.get('/pos/topup-list');
  }

  getBranches() {
    return api.get('/pos/branches');
  }

  getStaffs() {
    return api.get('/pos/staffs');
  }

  getPaymentMethods() {
    return api.get('/pos/payment-methods');
  }

  voidTopup(dataForm) {
    return api.post('/pos/void-topup', dataForm);
  }

  getTransaction(dataForm) {
    return api.get('/pos/detail-transaction?phone_number=' + dataForm.phone_number + '&transaction_id=' + dataForm.transaction_id);
  }

  updateOrder(dataForm) {
    return api.post('/pos/update-order', dataForm);
  }

  voidTransaction(dataForm) {
    return api.post('/pos/void-transaction', dataForm);
  }
}

export default new TestService();
