import api from './api';

class Subscriptions {
  getDataSubscriptions(dataForm) {
    return api.get('/subscriptions/subscription', dataForm);
  }
  createSubscriptions(dataForm) {
    return api.post('/subscriptions/create-subscription', dataForm);
  }
  getDataSubscriptionsHistory(dataForm) {
    return api.get('/subscriptions/list-history', dataForm);
  }
  cancelSubscription(subscription_history_id) {
    return api.post('/subscriptions/cancel-subscription', {subscription_history_id: subscription_history_id});
  }
  getDataSubscriptionsInvoiceHistory(id) {
    return api.get('/subscriptions/list-history-invoices-by-subscription/' + id);
  }
  saveSubscriptionsHistory(dataForm) {
    return api.post('/subscriptions/save-subscription-history', dataForm);
  }
}

export default new Subscriptions();
