<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <h4
          class="col-lg-10 col-md-10 col-12 text-back d-flex align-items-center"
        >
          <i
            class="fa fa-chevron-circle-left pe-2"
            aria-hidden="true"
            @click="backBanchesList()"
            style="cursor: pointer"
          ></i>
          <div @click="backBanchesList()" style="cursor: pointer">BACK</div>
        </h4>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions branch-detail">
      <!-- <span class="mask bg-white"></span> -->
      <div class="container">
        <div class="row card-custom-new pt-3">
          <div class="col-lg-12 col-md-8 col-12 mx-auto pb-5">
            <div>
              <h3 class="title-page">
                {{
                  !this.$route.params.id
                    ? "CREATE BRANCH"
                    : "BRANCH DETAILS - " +
                      branch.branch_name.toLocaleUpperCase()
                }}
                <span
                  v-if="this.$route.params.id"
                  :class="
                    branch.status
                      ? 'badge badge-sm bg-gradient-success'
                      : 'badge badge-sm bg-gradient-danger'
                  "
                  style="margin-left: 20px"
                  >{{ branch.status ? "Active" : "Deactive" }}</span
                >
              </h3>
              <div class="row">
                <div
                  class="table-responsive custom-table-noborder"
                  style="overflow-x: unset !important"
                >
                  <table class="table align-items-center mb-0">
                    <tbody>
                      <tr>
                        <td class="align-middle text-left text-sm" colspan="4">
                          <div class="ps-0 text-title">
                            {{ branch.id ? "Created on " : "Create on "
                            }}{{
                              branchCreateAt +
                              " by " +
                              branchCreateBy +
                              " admin"
                            }}
                          </div>
                        </td>
                        <td colspan="6">
                          <div class="d-flex justify-content-end">
                            <material-button
                              @click="saveBranch(index)"
                              :disabled="disableBtnSubmit"
                              class="btn-background btn-sm"
                            >
                              Save
                            </material-button>
                            <material-button
                              @click="changeStatus()"
                              v-if="this.$route.params.id"
                              class="btn-background btn-sm ms-2"
                            >
                              {{ branch.status ? "Deactive" : "Active" }}
                            </material-button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle text-left text-sm" colspan="2">
                          <div class="d-flex">
                            <div class="ps-0 label-form">Branch ID</div>
                          </div>
                        </td>
                        <td colspan="8">{{ branch.branch_id_text }}</td>
                      </tr>
                      <tr>
                        <td class="align-middle text-left text-sm" colspan="2">
                          <div class="d-flex">
                            <div class="ps-0 label-form">Branch Code</div>
                            <span class="text-danger ms-2">*</span>
                          </div>
                        </td>
                        <td colspan="8">
                          <material-input
                            maxLength="100"
                            id="branch_code"
                            v-model="branch.branch_code"
                            name="branch_code"
                            class="branch-address"
                            placeholder="Enter branch code"
                          />
                          <span
                            class="is-invalid"
                            v-if="fieldRequired.isBranchCodeRequire"
                            >The field is required.</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle text-left text-sm" colspan="2">
                          <div class="d-flex">
                            <div class="ps-0 label-form">Branch Name</div>
                            <span class="text-danger ms-2">*</span>
                          </div>
                        </td>
                        <td colspan="8">
                          <material-input
                            maxLength="100"
                            id="branch_name"
                            v-model="branch.branch_name"
                            name="branch_name"
                            class="branch-address"
                            placeholder="Enter branch name"
                          />
                          <span
                            class="is-invalid"
                            v-if="fieldRequired.isBranchNameRequire"
                            >The field is required.</span
                          >
                        </td>
                      </tr>
                      <tr class="pt-5 pb">
                        <td class="align-middle text-left text-sm" colspan="2">
                          <div class="d-flex">
                            <div class="ps-0 label-form">Branch Address</div>
                          </div>
                        </td>
                        <td colspan="8">
                          <material-input
                            maxLength="100"
                            id="branch_address"
                            v-model="branch.branch_address"
                            name="branch_address"
                            class="branch-address"
                            placeholder="Enter branch address"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle" colspan="2">
                          <div class="ps-0 label-form">Profile Picture</div>
                        </td>
                        <td>
                          <div class="d-flex align-items-center">
                            <div>
                              <material-input
                                style="display: none"
                                type="file"
                                id="image"
                                @change="handleFileUpload($event)"
                                accept="image/*"
                              />
                              <material-button
                                @click="uploadFile()"
                                :disabled="false"
                                class="btn btn-sm capitalize btn-background"
                              >
                                Upload Image
                              </material-button>
                              <div
                                class="is-invalid d-flex align-items-center pe-2"
                                v-if="fieldRequired.isImageRequire"
                              >
                                {{ messageImageError }}
                              </div>
                              <div class="text-size-13">
                                Uploaded image minimum 200 x 115.
                              </div>
                            </div>
                            <div class="ps-3">
                              <div id="preview" style="position: relative">
                                <img
                                  class="img-thumbnail"
                                  v-if="urlImagePreview || branch.icon"
                                  :src="
                                    urlImagePreview
                                      ? urlImagePreview
                                      : branch.icon
                                  "
                                />
                                <i
                                  class="fa fa-times icon-close-sidenav-main danger"
                                  v-if="urlImagePreview || branch.icon"
                                  style="
                                    position: absolute;
                                    top: -8px;
                                    left: 170px;
                                    color: #f44335;
                                    font-size: 20px;
                                  "
                                  aria-hidden="true"
                                  @click="clearImage()"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle" colspan="10">
                          <div class="ps-0 label-form">Operating Hours</div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="7">
                          <table class="table align-items-center mb-0">
                            <thead>
                              <tr>
                                <th class="text-start font-weight-bolder w-20">
                                  Day
                                </th>
                                <th class="text-start font-weight-bolder w-30">
                                  Period Type
                                </th>
                                <th class="text-start font-weight-bolder">
                                  Period
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="branch?.operation_days?.length">
                              <tr
                                v-for="(value, key) in branch.operation_days"
                                :key="key"
                              >
                                <td class="text-start">{{ value.day_name }}</td>
                                <td class="text-start">
                                  <Multiselect
                                    v-model="value.period_type"
                                    :options="periodType"
                                    placeholder="Select period"
                                    :canClear="false"
                                  />
                                </td>
                                <td
                                  class="text-start ps-4"
                                  v-if="value.period_type == 2"
                                >
                                  N/A
                                </td>
                                <template v-else>
                                  <td class="text-start ps-4">
                                    <div
                                      v-for="(
                                        item, index
                                      ) in value.operation_hours"
                                      :key="index"
                                      class="d-flex align-items-center mb-1"
                                    >
                                      {{
                                        item.start_time_format +
                                        " - " +
                                        item.end_time_format
                                      }}
                                      <i
                                        class="material-icons opacity-10 ms-2 text-danger cursor-pointer"
                                        @click="removePeriod(key, index)"
                                        >remove</i
                                      >
                                    </div>
                                    <div
                                      class="cursor-pointer"
                                      @click="addPeriod(value.day_of_week)"
                                    >
                                      <i
                                        class="material-icons opacity-10 text-info"
                                        >add</i
                                      >
                                    </div>
                                  </td>
                                </template>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr class="">
                        <td class="align-middle text-left text-sm" colspan="2">
                          <div class="d-flex">
                            <div class="ps-0 label-form">Menuset</div>
                          </div>
                        </td>
                        <td colspan="8">
                          <Multiselect
                            v-model="branch.menuset_id"
                            :options="menuset"
                            placeholder="Select menuset"
                            :canClear="false"
                            class="menuset-select"
                          />
                        </td>
                      </tr>
                      <tr class="">
                        <td class="align-middle text-left text-sm" colspan="2">
                          <div class="d-flex">
                            <div class="ps-0 label-form">Branch Waiting Time</div>
                          </div>
                        </td>
                        <td class="d-flex align-items-center" colspan="8">
                          <MaterialRadio
                            class="ps-0"
                            id="none"
                            v-model="branch.waiting_time_type"
                            value="1"
                          >
                            Exact time
                          </MaterialRadio>
                          <MaterialRadio
                            class="ps-0 ms-3"
                            id="none"
                            v-model="branch.waiting_time_type"
                            value="2"
                          >
                            Range time
                          </MaterialRadio>
                        </td>
                      </tr>
                      <tr class="">
                        <td colspan="2"></td>
                        <td colspan="8">
                          <div class="d-flex align-items-center">
                            <div v-if="branch.waiting_time_type == 1">
                              <material-input
                                type="number"
                                id="branch_waiting_time"
                                v-model="branch.waiting_time"
                                name="branch_waiting_time"
                                class="branch-address mw-150px"
                                placeholder="Enter time"
                              />
                            </div>
                            <div class="d-flex align-items-center" v-else>
                              <material-input
                                type="number"
                                id="branch_waiting_time_start"
                                v-model="branch.waiting_time_start"
                                name="branch_waiting_time_start"
                                class="branch-address mw-150px"
                                placeholder="Enter time"
                              />
                              <div class="label-form px-3">{{ $t('To') }}</div>
                              <material-input
                                type="number"
                                id="branch_waiting_time_end"
                                v-model="branch.waiting_time_end"
                                name="branch_waiting_time_end"
                                class="branch-address mw-150px"
                                placeholder="Enter time"
                              />
                            </div>
                            <Multiselect
                              v-model="branch.waiting_time_unit"
                              :options="waitingTime"
                              placeholder="Select time"
                              :canClear="false"
                              class="mw-150px ms-3"
                            />
                          </div>
                          <span
                            class="is-invalid"
                            v-if="fieldRequired.isWaitingTimeRequire"
                            >Number must be greater than or equal to 0.</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="align-middle text-left text-sm" colspan="2">
                          <div class="d-flex">
                            <div class="ps-0 label-form">Destination Link</div>
                          </div>
                        </td>
                        <td colspan="8">
                          <material-input
                            maxLength="100"
                            id="destination_link"
                            v-model="branch.destination_link"
                            name="destination_link"
                            class="branch-address"
                            placeholder="Enter destination link"
                          />
                          <span
                            class="is-invalid"
                            v-if="fieldRequired.invalidLink"
                            >Invalid link.</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirm
        message="Are you sure you want to delete ?"
        :process-yes="processYes"
      />
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
      <add-period
        :keyReload="keyReload"
        :day="daySelect"
        @handle-add-period="handleAddPeriod"
      ></add-period>
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
// import Datepicker from "@vuepic/vue-datepicker";
// import "@vuepic/vue-datepicker/dist/main.css";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import SettingStoreService from "../../../services/settingStore.service";
import ModalConfirm from "@/components/ModalConfirm.vue";
import MaterialInput from "@/components/MaterialInput.vue";
// import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import Multiselect from "@vueform/multiselect";
import AddPeriod from "../stores/ModelAddPeriod.vue";
import moment from "moment";
import $ from "jquery";

export default {
  name: "branch-detail",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    ModalConfirm,
    MaterialInput,
    // Datepicker,
    // MaterialCheckbox,
    Multiselect,
    AddPeriod,
    MaterialRadio
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      fieldRequired: {},
      textRequiredDateCustom: "",
      urlImagePreview: null,
      messageImageError: "",
      isErrorUploadImage: false,
      isImageTooSmall: false,
      branch: {
        id: null,
        branch_name: "",
        branch_code: "",
        branch_address: "",
        icon: null,
        address: "",
        status: 1,
        branch_id: "",
        branch_id_text: "",
        menuset_id: 0,
        operation_days: [],
        destination_link: "",
        waiting_time_type: 1,
        waiting_time: 0,
        waiting_time_start: 0,
        waiting_time_end: 0,
        waiting_time_unit: 1
      },
      branchCreateAt: "",
      branchCreateBy: "",
      menuset: [],
      waitingTime: [],
      periodType: [],
      daySelect: 0,
    };
  },
  created() {
    this.getBranchDetail();
  },
  mounted() {},
  methods: {
    handleFileUpload(event) {
      this.isImageTooSmall = false;
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        this.branch.icon = null;
        this.urlImagePreview = null;
        if (
          !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
            file.type
          )
        ) {
          this.fieldRequired.isImageRequire = true;
          this.isErrorUploadImage = false;
          this.messageImageError = `File must be an image.`;
        } else {
          this.branch.icon = file;
          this.urlImagePreview = URL.createObjectURL(this.branch.icon);
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (evt) => {
            let img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              if (width < 200 || height < 115) {
                this.messageImageError = `The image width ${width} x ${height} is too small.`;
                this.fieldRequired.isImageRequire = true;
                this.isErrorUploadImage = true;
                this.isImageTooSmall = true;
              } else {
                this.fieldRequired.isImageRequire = false;
                this.isErrorUploadImage = false;
                this.isImageTooSmall = false;
              }
            };
            img.src = evt.target.result;
          };
        }
      } else {
        this.branch.icon = null;
        this.urlImagePreview = null;
        this.fieldRequired.isImageRequire = true;
        this.isErrorUploadImage = false;
        this.messageImageError = `File must be an image.`;
      }
    },

    clearImage() {
      this.branch.icon = null;
      this.urlImagePreview = null;
      this.fieldRequired.isImageRequire = false;
      this.isErrorUploadImage = false;
      this.isImageTooSmall = false;
    },

    timeFormat(time) {
      if (time == null) {
        return null;
      } else {
        return moment(time).format("H:m");
      }
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    addPeriod(day) {
      this.daySelect = day;
      this.keyReload = Math.random();
      $("#show-modal-add-period").click();
    },

    removePeriod(index, index2) {
      this.branch.operation_days[index].operation_hours.splice(index2, 1)
    },

    handleAddPeriod(index, start_time, end_time) {
      this.branch.operation_days[index].operation_hours.push({
        branch_id: this.branch.branch_id,
        day_of_week: this.branch.operation_days[index].day_of_week,
        start_time: start_time,
        end_time: end_time,
        start_time_format: start_time,
        end_time_format: end_time,
      })
    },

    changeStatus() {
      var branchId = this.$route.params.id ?? 0;
      this.showLoader();
      SettingStoreService.changeStatus(branchId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.snackbar = "success";
            this.message = "Change Status Success";
            this.getBranchDetail();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
          }
          this.hideLoader();
          this.setTimeOutNotification();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    getBranchDetail() {
      var branchId = this.$route.params.id ?? 0;
      this.showLoader();
      SettingStoreService.getDataBranchDetail(branchId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.branch = response.data.data.branch;
            this.menuset = response.data.data.menusetDropdown;
            this.waitingTime = response.data.data.waitingTimeDropdown;
            this.branchCreateAt = response.data.data.create_at;
            this.branchCreateBy = response.data.data.create_by;
            this.periodType = response.data.data.period_type;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    saveBranch() {
      var page = this.$route.query.page;
      if (this.isImageTooSmall) {
        this.isErrorUploadImage = true;
        this.fieldRequired.isImageRequire = true;
      } else {
        this.fieldRequired.isImageRequire = false;
      }
      this.showLoader();
      if (this.processValidate() && !this.isErrorUploadImage) {
        SettingStoreService.addOrUpdateBranch(this.branch).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message = "Save Branch Success";
              this.hideLoader();
              this.setTimeOutNotification();
              setTimeout(() => {
                if (page) {
                  this.$router.push(
                    "/branches?page=" + page
                  );
                } else {
                  this.$router.push("/branches");
                }
              }, 1000)
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      } else {
        this.hideLoader();
      }
    },

    resetRequired() {
      this.fieldRequired.isBranchNameRequire = false;
      this.fieldRequired.isDateRequire = false;
      this.fieldRequired.isBranchCodeRequire = false;
      this.fieldRequired.invalidLink = false;
      this.fieldRequired.isWaitingTimeRequire = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.branch.branch_name == "" || this.branch.branch_name == null) {
        this.fieldRequired.isBranchNameRequire = true;
        isValid = false;
      }

      if (this.branch.branch_code == "" || this.branch.branch_code == null) {
        this.fieldRequired.isBranchCodeRequire = true;
        isValid = false;
      }

      if (this.branch.waiting_time_type == 1 && this.branch.waiting_time < 0) {
        this.fieldRequired.isWaitingTimeRequire = true;
        isValid = false;
      }

      if (this.branch.waiting_time_type == 2 && (this.branch.waiting_time_start < 0 || this.branch.waiting_time_end < 0)) {
        this.fieldRequired.isWaitingTimeRequire = true;
        isValid = false;
      }

      if (this.branch.destination_link != "" && this.branch.destination_link != null) {
        var urlPattern = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
        if (!urlPattern.test(this.branch.destination_link)) {
          isValid = false;
          this.fieldRequired.invalidLink = true;
        }
      }

      return isValid;
    },

    backBanchesList() {
      var page = this.$route.query.page;
      if (page) {
        this.$router.push("/branches" + "?page=" + page);
      } else {
        this.$router.push("/branches");
      }
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    uploadFile() {
      $("#image").click();
    },
  },
};
</script>
<style scoped>
.text-back {
  color: black !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

#preview img {
  max-width: 168px;
  max-height: 115px;
}

.branch-address {
  width: 600px !important;
  max-width: 600px;
}

.menuset-select {
  max-width: 600px;
  float: left;
}

.autocomplete-custom {
  cursor: pointer;
  position: absolute;
  z-index: 999;
}

.branch-autocomplete ul {
  padding: 0px !important;
  margin: 0px !important;
}
.branch-autocomplete li {
  height: 40px;
  text-transform: lowercase;
  padding-left: 10px;
  padding-right: 10px;
  letter-spacing: 1px;
  border-bottom: 1px solid #e1e3e4;
  font-size: 14px;
}
.mw-150px {
  max-width: 150px !important;
}
</style>
