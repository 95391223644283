<template>
    <div>
      <div class="row mb-2 pe-4">
        <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
          <div class="color-common">
            <h3 class="title-page mb-0 ps-3">Item Quick Management</h3>
          </div>
        </div>
      </div>
      <div class="page-header align-items-start min-vh-100 my-transacions">
        <span class="mask bg-white"></span>
        <div class="container">
          <div class="row">
            
            <div class="col-lg-12 col-md-12 col-12 mx-auto">
                <div class="d-flex mt-5">
                  <div class="col-lg-3 col-md-3 col-3 mx-auto">
                    <!-- <Multiselect v-model="menu_set_id" :options="menuSetDropdown" :canClear="false" /> -->
                    <Multiselect
                      @change="onchangeBranchList($event)"
                      placeholder="Branches"
                      v-model="branchIdSelect"
                      :options="branches"
                      :canClear="false"
                    />
                  </div>
                  <div class="col-lg-9 col-md-9 col-9 d-flex justify-content-end">
                    <material-button
                      class="mx-2 btn btn-sm btn-background"
                      @click="redirectToItemList()"
                    >
                      BACK TO ITEM LIST
                    </material-button>
                    <material-button
                      class="mx-2 btn btn-sm btn-background"
                      @click="handleEnableOrDisableAll(true)"
                    >
                      Disable
                    </material-button>
                    <material-button
                      class="mx-2 btn btn-sm btn-background"
                      @click="handleEnableOrDisableAll(false)"
                    >
                      Enable
                    </material-button>
                  </div>
                </div>
                <div class="row mt-3" style="align-items: center;border-bottom: 1px solid #d2d2d2;">
                    <div class="col-lg-9 col-md-9 col-9">
                        <input
                          class="nosubmit"
                          type="search"
                          id="keyword_search"
                          extraClass
                          name="keyword_search"
                          placeholder="Search item ..."
                          v-model="item_name"
                        />
                    </div>
                    <div class="col-lg-1 col-md-1 col-1" v-if="this.item_name != '' && this.is_search == true">
                        Count {{ pagination.total }}
                    </div>
                    <div class="col-lg-1 col-md-1 col-1" v-else>
                        Count 0
                    </div>
                    <div class="col-lg-2 col-md-2 col-2">
                      <material-button
                        class="mx-2 btn btn-sm btn-background"
                        @click="searchItemName()"
                      >
                        Search
                      </material-button>
                    </div>
                </div>
              <div class="row mt-3">
                <div class="col-lg-6 col-12 d-flex align-items-center mb-2">
                  <div class="label-header" v-if="itemList.length">
                    Showing {{ pagination.number_items }} of {{ pagination.total }} Items
                  </div>
                </div>
                <div class="col-12" v-if="isLoadData">
                  <div class="card-custom">
                    <div class="px-0 pb-2">
                      <div class="table-responsive p-0 custom-table">
                        <table class="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th class="text-center font-weight-bolder w-1">
                                <div class="form-check">
                                    <input v-if="itemList.length" class="form-check-input" type="checkbox" :checked="isAllSelected" @change="selectAll">
                                </div>
                              </th>
                              <th class="text-left font-weight-bolder w-5">
                                {{ $t("Item Code") }}
                              </th>
                              <th class="text-left font-weight-bolder w-5">
                                {{ $t("Item Name") }}
                              </th>
                              <th class="text-left font-weight-bolder w-5">
                                {{ $t("Status") }}
                              </th>
                              <th class="text-left font-weight-bolder w-5">
                                Available
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="itemList.length">
                            <tr v-for="(value, key) in itemList" :key="key">
                                <td
                                    class="text-center text-xss w-5"
                                    >
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :value="value.id" v-model="selectedItems">
                                    </div>
                                </td>
                                <td
                                    class="text-left text-xss w-15"
                                    >
                                    {{ value.item_no }}
                                </td>
                                <td
                                    class="text-left text-xss w-60"
                                    >
                                    {{ value.item_name }}
                                </td>
                                <td class="text-left text-xss w-10" v-if="value.is_disabled == 0" style="color: green">
                                    Active
                                </td>
                                <td class="text-left text-xss w-10" v-else style="color: red">
                                    Disable
                                </td>
                                <td
                                    class="text-left text-xss w-5"
                                    >
                                    <div
                                      :class="{ active: value.is_not_available }"
                                      class="toggle_container mt-3"
                                    >
                                      <MaterialToggleButton
                                        labelEnableText=""
                                        labelDisableText=""
                                        id="event_handle_available"
                                        v-on:change="triggerEventAvailable($event, value.id)"
                                      />
                                    </div>
                                </td>

                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td colspan="10" class="text-center">No item available</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <pagination
                      v-if="pagination.last_page > 1"
                      :pagination="pagination"
                      :current_page="pagination.current_page"
                      :last_page="pagination.last_page"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2 message-success">
          <material-snackbar
            v-if="snackbar === 'danger' || snackbar === 'success'"
            title=""
            date=""
            :description="message"
            :icon="{ component: 'campaign', color: 'white' }"
            :color="snackbar"
            :close-handler="closeSnackbar"
          />
        </div>
        <material-loading :active="loaderActive" />
        <ModalConfirmDelete
          message="Are you sure you want to delete ?"
          :process-yes="deleteItemList"
        />
      </div>
      <form :action="`${actionDownload}/merchant/item`" method="GET">
          <input type="hidden" name="merchant_code" :value="merchantCode" />
          <input type="hidden" name="token" :value="token" />
          <input type="hidden" name="action_export" :value="action_export" />
          <input type="hidden" name="action" value="export" />
          <input type="hidden" name="selectedItems" :value="selectedItems" />
          <input id="downloadForm" type="submit" class="d-none" />
      </form>
    </div>
  </template>
  
  <script>
  import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
  import "bootstrap/dist/js/bootstrap.bundle";
  import MaterialLoading from "@/components/MaterialLoading.vue";
  import Pagination from "@/components/Pagination.vue";
  import "bootstrap/js/dist/modal";
  import MaterialButton from "@/components/MaterialButton.vue";
  import "@vuepic/vue-datepicker/dist/main.css";
  import "@vueform/multiselect/themes/default.css";
  import ItemService from "../../services/product/item.service";
  import Multiselect from "@vueform/multiselect";
  import TokenService from "../../services/token.service";
  import $ from "jquery";
  import { saveAs } from 'file-saver';
  import ModalConfirmDelete from "@/components/ModalConfirmDelete.vue";
  import MenusetService from "../../services/product/menuset.service";
  import MaterialToggleButton from "@/components/MaterialToggleButton.vue";
  
  export default {
    name: "campaign-list",
    components: {
      MaterialSnackbar,
      MaterialLoading,
      MaterialButton,
      Pagination,
      Multiselect,
      ModalConfirmDelete,
      MaterialToggleButton
    },
    data() {
      return {
        token: TokenService.getLocalAccessToken(),
        actionDownload: process.env.VUE_APP_BASE_URL,
        message: "",
        snackbar: null,
        loaderActive: false,
        pagination: {},
        page: 1,
        isLoadData: true,
        itemList: [],
        filterListData: [
            {value: 1, label: "Select Menu Set"}
        ],
        allSelected: false,
        selectedItems: [],
        item_name: "",
        is_search: false,
        menuSetDropdown: [],
        typeDropdown: [
          {value: 0, label: "Select Type"},
          {value: 1, label: "Main"},
          {value: 2, label: "Modifier"},
        ],
        statusDropdown: [
          {value: -1, label: "Select Status"},
          {value: 0, label: "Active"},
          {value: 1, label: "Disable"},
        ],
        menu_set_id: 0,
        merchantCode: "",
        merchantName: "",
        action: 'list',
        action_export: 'excel',
        type_id: 0,
        status_id: -1,
        itemIdToDelete: 0,
        branches: [],
        branchIdSelect: null,
        isActiveAvailable: false,
      };
    },
    mounted() {
      if (this.$route.query.page) {
        this.page = this.$route.query.page;
      }
      const code = localStorage.getItem("MERCHANT_CODE", "");
      const name = localStorage.getItem("MERCHANT_ADMIN_NAME", "");
      this.merchantCode = code;
      this.merchantName = name ? name : "";
      this.getBranches();
      // this.getList(this.page);
      // this.getMenuSetDropdown();
    },
    methods: {
      exportFile(type = 'csv') {
        if (this.selectedItems.length == 0) {
          this.snackbar = "danger";
          this.message = "You must select least 1 column to export file."
          this.setTimeOutNotification();
        } else {
          if(type == 'json') {
            this.exportData()
          } else {
            this.action_export = type;
            setTimeout(() => {
              $('#downloadForm').click();
            }, 150);
          }
        }
      },
      selectAll(event) {
          if (event.target.checked) {
          this.selectedItems = this.itemList.map(item => item.id)
          } else {
          this.selectedItems = []
          }
      },
      closeSnackbar() {
        this.snackbar = null;
      },
  
      setTimeOutNotification() {
        setTimeout(() => {
          this.snackbar = null;
        }, 3000);
      },
  
      getList(page, branchId) {
        this.isLoadData = false;
        this.showLoader();
        var dataForm = {
          page: page,
          branchId: branchId
        };
        ItemService.getList(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.itemList = response.data.data.items;
              this.menuSetDropdown = response.data.data.menu_set_array;
              this.pagination = response.data.data;
              this.page = this.pagination.current_page;
              this.isLoadData = true;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
          }
        );
        this.hideLoader();
      },

      getMenuSetDropdown() {
        this.isLoadData = false;
        this.showLoader();
        ItemService.getMenuSetDropdown().then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.menuSetDropdown = response.data.data;
              this.isLoadData = true;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
          }
        );
        this.hideLoader();
      },

      handleEnableOrDisable(item) {
        this.isLoadData = false;
        this.showLoader();
        var dataForm = {
          id: item.id,
          value: item.is_disabled
        };
        ItemService.handleEnableOrDisable(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
                this.getList(this.page, this.branchIdSelect);
                this.getMenuSetDropdown();
            } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
            }
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
          }
        );
        this.hideLoader();
      },
  
    //   menusetDetail(id) {
    //     this.$router.push('/create-menu/' + id);
    //   },
  
      showLoader() {
        this.loaderActive = true;
      },
  
      hideLoader() {
        this.loaderActive = false;
      },

      handleEnableOrDisableAll(is_disabled) {
        if(this.selectedItems && this.selectedItems.length > 0) {
          this.isLoadData = false;
          this.showLoader();
          var dataForm = {
            is_disabled: is_disabled,
            selectedItems: this.selectedItems
          };
          ItemService.handleEnableOrDisableAll(dataForm).then(
            (response) => {
              if (response.data.result.isSuccess) {
                  this.getList(this.page, this.branchIdSelect);
              } else {
                  this.snackbar = "danger";
                  this.message = response.data.result.message;
                  this.setTimeOutNotification();
              }
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.setTimeOutNotification();
            }
          );
          this.hideLoader();
        } else {
          this.snackbar = "danger";
          this.message = "You must select least 1 column to export file."
          this.setTimeOutNotification();
        }
      },
      searchItemName() {
        if(this.branchIdSelect && this.branchIdSelect > 0) {
          this.isLoadData = false;
          this.showLoader();
          var dataForm = {
            page: this.page,
            branchId: this.branchIdSelect
          };
          if(this.item_name != "") {
            dataForm.search_keyword = this.item_name;
          }
          if(this.menu_set_id > 0) {
            dataForm.set_id = this.menu_set_id;
          }
          if(this.type_id > 0) {
            dataForm.item_type = this.type_id;
          }
          if(this.status_id >= 0) {
            dataForm.status = this.status_id;
          }
          ItemService.getList(dataForm).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.itemList = response.data.data.items;
                this.pagination = response.data.data;
                this.page = this.pagination.current_page;
                this.isLoadData = true;
                this.is_search = true;
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.setTimeOutNotification();
            }
          );
          this.hideLoader();
        }
      },
      exportData() {
        const newData = [];
        const selectedObjects = this.itemList.filter((obj) => this.selectedItems.includes(obj.id));

        selectedObjects.forEach((value) => {
          const dataExport = {
            "Item Code": value.item_no,
            "Type": value.item_type_format,
            "Image": value.image,
            "Item Name": value.item_name,
            "Description": value.description,
            "Variables": value.item_price_merchant && value.item_price_merchant.length > 0 && (value.item_price_merchant && value.item_price_merchant.length > 0 ? value.item_price_merchant.map(item => item.name).join(", ") : ""),
            "Price": value.item_price_format,
            "Tags & Allergents": "",
            "Status": value.is_disabled === 0 ? 'Active' : 'Disable',
            "Branch": ""
          };
          newData.push(dataExport);
        });

        const jsonData = JSON.stringify(newData, null, 2);
        const blob = new Blob([jsonData], { type: 'application/json' });
        const fileName = 'itemList.json';
        saveAs(blob, fileName);
      },
      handleEdit(item) {
        var url = '/edit-item/';
        if (item.is_combo) {
          url = '/edit-combo/';
        }

        this.$router.push(url + item.id)
      },
      reloadListItem(mess) {
        this.snackbar = "success";
        this.message = mess;
        this.setTimeOutNotification();
        this.getList(this.page, this.branchIdSelect);
        this.getMenuSetDropdown();
      },
      openModalDeleteListItem(itemId) {
        this.itemIdToDelete = itemId;
        $(".btn-confirm-modal-delete").click();
      },
      deleteItemList() {
        this.showLoader();
        ItemService.deleteItem(this.itemIdToDelete).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.reloadListItem("Deleted item successfullyy");
                $(".btn-close-modal").click();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
      },
      redirectToItemList() {
        var url = '/item-list';

        this.$router.push(url)
      },
      getBranches() {
        this.showLoader();
        MenusetService.getBranchs().then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.branches = response.data.data.branchSelect;
              this.branchIdSelect = response.data.data.branchIdSelect;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      },
      onchangeBranchList(value) {
        // console.log(value);
        if(value) {
          this.getList(this.page, value);
        }
      },
      triggerEventAvailable(event, item_id) {
        if (typeof event == "boolean") {
          // this.isActiveAvailable = !this.isActiveAvailable;
          this.showLoader();
          var dataForm = {
            id: item_id
          };
          ItemService.handleAvailable(dataForm).then(
            (response) => {
              if (response.data.result.isSuccess) {
                  this.getList(this.page, this.branchIdSelect);
              } else {
                  this.snackbar = "danger";
                  this.message = response.data.result.message;
                  this.setTimeOutNotification();
              }
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.setTimeOutNotification();
            }
          );
          this.hideLoader();
        }
      },
    },
    computed: {
      isAllSelected: {
        get() {
          return this.selectedItems && this.selectedItems.length === this.itemList.length
        },
        set(value) {
          if (value) {
              this.selectedItems = this.itemList.map(item => item.id)
          } else {
              this.selectedItems = []
          }
        }
      }
    }
  };
  </script>
  <style scoped>
  .css-icon {
    position: absolute;
    left: 17px !important;
    font-size: 18px !important;
    top: 7px !important;
  }
  
  .label-header {
    font-size: 16px;
    color: #222;
    font-weight: 500;
  }

  .nosubmit-parent {
    border: none;
    padding: 0;
    }

    input.nosubmit {
    border-bottom: 0px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    width: 100%;
    padding: 9px 4px 9px 40px;
    background: transparent
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
        no-repeat 13px center;
    }
    .dropdown:not(.dropdown-hover) .dropdown-menu.show {
      margin-top: 3.5rem !important;
    }
    .form-check {
      padding: 0px;
    }
  </style>
  