<template>
  <div>
    <div v-for="(item, index) in form" :key="item">
      <div class="row card-custom-new mb-2 pt-0">
        <div
          class="
            table-responsive
            p-2
            custom-table-noborder
            col-lg-12 col-md-12 col-12
          "
        >
          <table class="table align-items-center mb-0">
            <tbody>
              <tr>
                <td class="align-middle text-left text-sm" colspan="2">
                  <div class="ps-0 text-title">
                    Standard Rule {{ index + 1 }}
                  </div>
                </td>
                <td colspan="6">
                  <div class="d-flex justify-content-end">
                    <material-button
                      @click="saveDiscount(index)"
                      :disabled="disableBtnSubmit"
                      class="mx-2 btn-background btn-sm"
                      :style="`background: ${this.$store.state.buttonColor} !important`"
                    >
                      Save
                    </material-button>
                    <material-button
                      @click="removeDiscount(index)"
                      :disabled="disableBtnSubmit"
                      class="mx-2 btn-sm btn-danger"
                    >
                      Delete
                    </material-button>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-middle text-left text-sm" colspan="2">
                  <div class="d-flex">
                    <div class="ps-0 label-form">Discount Rule Name</div>
                    <span class="text-danger ms-2">*</span>
                  </div>
                </td>
                <td colspan="6">
                  <material-input
                    v-model="item.rule_name"
                    id="rule_name"
                    :name="`rule_name_${index}`"
                    placeholder="Enter discount rule name"
                  />
                  <span
                    class="is-invalid"
                    v-if="fieldRequired[index].isRuleNameRequire"
                    >The field is required.</span
                  >
                </td>
              </tr>
              <tr>
                <td class="align-middle text-left text-sm" colspan="2">
                  <div class="d-flex">
                    <div class="ps-0 label-form">Discount Code</div>
                    <span class="text-danger ms-2">*</span>
                  </div>
                </td>
                <td colspan="6">
                  <material-input
                    v-model="item.rule_code"
                    id="rule_code"
                    :name="`rule_code_${index}`"
                    placeholder="Enter discount rule code"
                  />
                  <span
                    class="is-invalid"
                    v-if="fieldRequired[index].isRuleCodeRequire"
                    >The field is required.</span
                  >
                </td>
              </tr>
              <tr>
                <td class="align-middle" colspan="2" style="width: 20%">
                  <div class="d-flex">
                    <div class="ps-0 label-form">Discount Amount</div>
                    <span class="text-danger ms-2">*</span>
                  </div>
                </td>
                <td colspan="3" style="width: 40%">
                  <Multiselect
                    v-model="item.discount_amount_type"
                    :options="discountAmuontType"
                    :canClear="false"
                  />
                  <span
                    class="is-invalid"
                    v-if="fieldRequired[index].isDiscountAmountTypeRequire"
                    >The field is required.</span
                  >
                </td>
                <td colspan="3" style="width: 40%">
                  <div class="d-flex align-items-center">
                    <material-input
                      type="number"
                      v-model="item.discount_amount_value"
                      id="discount_amount_value"
                      name="discount_amount_value"
                      placeholder="Enter discount amuont value"
                    />
                    <div
                      v-if="
                        item.discount_amount_type === 1 ||
                        item.discount_amount_type === 3 ||
                        item.discount_amount_type === 6
                      "
                    >
                      %
                    </div>
                  </div>
                  <span
                    class="is-invalid"
                    v-if="fieldRequired[index].isDiscountAmountValueRequire"
                    >The field is required.</span
                  >
                </td>
              </tr>
              <tr>
                <td class="align-middle" colspan="2">
                  <div class="ps-0 label-form">
                    Where is this discount valid ?
                  </div>
                </td>
                <td colspan="6">
                  <Multiselect
                    v-model="item.apply_for_store"
                    mode="tags"
                    :close-on-select="false"
                    :searchable="true"
                    :create-option="true"
                    :options="discountApplyForStore"
                    :canClear="false"
                  />
                </td>
              </tr>
              <tr>
                <td class="align-middle" colspan="2">
                  <div class="ps-0 label-form">
                    Which product is included in this discount ?
                  </div>
                </td>
                <td colspan="6">
                  <Multiselect
                    v-model="item.apply_for_product"
                    mode="tags"
                    :close-on-select="false"
                    :searchable="true"
                    :create-option="true"
                    :options="discountApplyForProducts"
                    :canClear="false"
                  />
                </td>
              </tr>
              <tr>
                <td class="align-middle" colspan="2">
                  <div class="ps-0 label-form">Order mininum</div>
                </td>
                <td colspan="1">
                  <MaterialRadio
                    class="ps-0"
                    v-model="item.order_minimum"
                    :id="`none_${index}`"
                    value="1"
                  >
                    None
                  </MaterialRadio>
                  <MaterialRadio
                    class="ps-0"
                    v-model="item.order_minimum"
                    :id="`number_item_${index}`"
                    value="2"
                  >
                    Number of item
                  </MaterialRadio>
                  <MaterialRadio
                    class="ps-0"
                    v-model="item.order_minimum"
                    :id="`order_total_${index}`"
                    value="3"
                  >
                    Order total
                  </MaterialRadio>
                </td>
                <td colspan="4" class="align-items-end">
                  <material-input
                    v-model="item.number_item"
                    id="number_item"
                    name="number_item"
                    class="align-items-end"
                    placeholder="Enter number of item"
                    style="margin-top: 32px"
                  />
                  <material-input
                    v-model="item.order_total"
                    id="order_total"
                    name="order_total"
                    class="align-items-end"
                    placeholder="Enter order total"
                  />
                </td>
              </tr>
              <tr>
                <td class="align-middle" colspan="2"></td>
                <td colspan="1"></td>
                <td colspan="4" class="align-items-end pt-0">
                  <span
                    class="is-invalid"
                    v-if="fieldRequired[index].isOrderMinimumRequire"
                    >{{ textRequiredOrderMinimumCustom[index] }}</span
                  >
                </td>
              </tr>
              <tr>
                <td class="align-middle" colspan="2" style="width: 20%">
                  <div class="ps-0 label-form">Duration</div>
                </td>
                <td style="width: 10%">From</td>
                <td style="width: 20%">
                  <Datepicker
                    v-model="item.start_date"
                    :enableTimePicker="false"
                    class="date-time"
                  />
                </td>
                <td style="width: 10%">To</td>
                <td style="width: 20%">
                  <Datepicker
                    v-model="item.end_date"
                    :enableTimePicker="false"
                    class="date-time"
                  />
                </td>
                <td style="width: 20%">
                  <material-checkbox
                    v-model="item.no_end_date"
                    :checked="item.no_end_date ? true : false"
                    value="1"
                    id="checkboxId"
                    class="checkbox-auto-save"
                    style="padding-left: 1.73em !important"
                  >
                    <template v-slot:label> No end date </template>
                  </material-checkbox>
                </td>
              </tr>
              <tr>
                <td class="align-middle" colspan="2" style="width: 40%">
                  <div class="ps-0 label-form">Hours</div>
                </td>
                <td style="width: 10%">From</td>
                <td style="width: 10%">
                  <Datepicker
                    v-model="item.start_time"
                    timePicker
                    class="date-time"
                  />
                </td>
                <td style="width: 10%">To</td>
                <td style="width: 10%">
                  <Datepicker
                    v-model="item.end_time"
                    timePicker
                    class="date-time"
                  />
                </td>
                <td style="width: 20%"></td>
              </tr>
              <tr>
                <td class="align-middle" colspan="2" style="width: 40%">
                  <div class="ps-0 label-form"></div>
                </td>
                <td style="width: 60%" colspan="6">
                  <div>
                    <span
                      class="is-invalid"
                      v-if="fieldRequired[index].isDateRequire"
                      >{{ textRequiredDateCustom }}</span
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-middle" colspan="2" style="width: 20%">
                  <div class="ps-0 label-form">Renew after use</div>
                </td>
                <td colspan="6" style="width: 80%">
                  <MaterialRadio
                    class="ps-0"
                    v-model="item.renew_type"
                    :id="`no_renew_${index}`"
                    value="0"
                    @change="onChangeRenewType(index, $event)"
                  >
                    No renew. One time for one member
                  </MaterialRadio>
                  <div class="d-flex">
                    <MaterialRadio
                      v-model="item.renew_type"
                      :id="`yes_renew_${index}`"
                      class="radio-custom d-flex align-items-center ps-0"
                      value="1"
                      @change="onChangeRenewType(index, $event)"
                    >
                      <div class="d-flex align-items-center div-custom">
                        Custom
                      </div>
                    </MaterialRadio>
                    <div class="d-flex align-items-center ms-3">1</div>
                    <div class="ms-3 d-flex align-items-center">Time</div>
                    <material-input
                      v-model="item.renew_time"
                      id="renew_time"
                      name="renew_time"
                      class="align-items-end ms-3"
                      placeholder="Enter time detail"
                    />
                    <Multiselect
                      class="ms-3"
                      v-model="item.renew_unit"
                      :options="timeUnits"
                      :canClear="false"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td class="align-middle" colspan="2" style="width: 20%"></td>
                <td colspan="6" style="width: 80%" class="pt-0 ps-10">
                  <span
                    class="is-invalid"
                    v-if="fieldRequired[index].isRenewTimeRequire"
                    >{{ textRequiredRenewTimeCustom[index] }}</span
                  >
                </td>
              </tr>
              <tr>
                <td colspan="8">
                  <div class="ps-0 label-form">Terms & Conditions</div>
                  <material-textarea v-model="item.terms_conditions" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialCheckbox from "./MaterialCheckbox.vue";
import MaterialInput from "./MaterialInput.vue";
import MaterialRadio from "./MaterialRadio.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  name: "CashbackRule",
  props: {
    id: {
      type: String,
      required: true,
    },
    form: {
      type: Array,
      required: true,
    },
    removeRow: {
      type: Function,
      default: () => {},
    },
    saveDiscount: {
      type: Function,
      default: () => {},
    },
    removeDiscount: {
      type: Function,
      default: () => {},
    },
    fieldRequired: {
      type: Array,
      required: true,
    },
    discountAmuontType: {
      type: Array,
      required: true,
    },
    discountApplyForStore: {
      type: Array,
      required: true,
    },
    discountApplyForProducts: {
      type: Array,
      required: true,
    },
    timeUnits: {
      type: Array,
      required: true,
    },
    onChangeRenewType: {
      type: Function,
      default: () => {},
    },
    textRequiredDateCustom: {
      type: String,
      required: true,
    },
    textRequiredOrderMinimumCustom: {
      type: String,
      required: true,
    },
    textRequiredRenewTimeCustom: {
      type: String,
      required: true,
    },
  },
  components: {
    MaterialInput,
    MaterialButton,
    MaterialCheckbox,
    MaterialRadio,
    Datepicker,
    MaterialTextarea,
    Multiselect,
  },
};
</script>
<style scoped>
.div-custom {
  padding-top: 11px;
}
</style>
