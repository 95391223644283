<template>
  <div>
    <div class="modal fade" id="modalCreateModalMenu" tabindex="-1" aria-hidden="true" style="z-index: 9999;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="width: unset">
          <div class="modal-body" style="overflow-y: unset">
            <div class="d-flex">
              <h5 class="modal-title" style="width: 95.6667%; cursor: pointer">
                Choose an option
              </h5>
              <i
                style="cursor: pointer"
                class="material-icons btn-close-modal justify-content-end"
                data-bs-dismiss="modal"
                aria-label="Close"
                >clear</i
              >
            </div>
            <div class="d-flex">
              <div class="content" @click="createMenu()">
                <i class="material-icons css-icon-menubook">menuoutlined</i>
                <h5>Create menu</h5>
                <div class="font-18" for="">Create a new menu form scratch</div>
              </div>
              <div class="content">
                <i class="material-icons css-icon">filedownloadoutlined</i>
                <h5>Import menu</h5>
                <div class="font-18" for="">Import your menu form a channel</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "create-menu",
  methods: {
    createMenu() {
      $(".btn-close-modal").click();
      this.$router.push("/menu-set/create");
    },
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.modal-body .row {
  margin-top: 10px;
}
.content {
  cursor: pointer;
  text-align: center;
  width: 250px;
  height: 250px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
}
.css-icon {
  margin-top: 30px;
  width: 1px !important;
  font-size: 74px !important;
  transform: translateX(-332px);
}
.css-icon-menubook {
  position: relative;
  margin-top: 30px;
  width: 1px !important;
  font-size: 74px !important;
  transform: translateX(-35px);
}
.font-18 {
  font-size: 18px;
}
</style>
