<template>
    <div>
        <div class="row mb-2 pe-4">
            <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
                <h4
                    class="col-lg-10 col-md-10 col-12 text-back d-flex align-items-center ps-2"
                >
                    <i
                        class="fa fa-chevron-circle-left pe-2"
                        aria-hidden="true"
                        @click="backBulkList()"
                        style="cursor: pointer"
                    ></i>
                    <div @click="backBulkList()" style="cursor: pointer">BACK</div>
                </h4>
            </div>
        </div>
        <div class="page-header align-items-start min-vh-100 my-transacions">
            <div class="container">
                <div class="row card-custom-new pt-3">
                    <div
                    class="col-lg-12 col-md-12 col-12 mx-auto pb-5"
                    >
                        <div style="display: inline-flex; justify-content: space-between;width: 100%;">
                            <h3 class="title-page ps-2">
                                BULK VOUCHER ISSUE
                            </h3>
                            <h3 class="title-page ps-2">
                                <material-button
                                    @click="createBulkVoucherIssue()"
                                    class="btn-background btn btn-sm capitalize"
                                >
                                    Issue ({{ issue_quantity }}) voucher code
                                </material-button>
                            </h3>
                        </div>
                        <div class="table-responsive custom-table-noborder">
                            <table class="table align-items-center mb-0">
                                <tbody>
                                    <tr>
                                        <td class="align-middle text-left text-sm" colspan="2">
                                            <div class="d-flex">
                                            <div class="ps-0 label-form">Batch ID</div>
                                            </div>
                                        </td>
                                        <td colspan="8">{{ bulk_id }}</td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle" colspan="2">
                                            <div class="d-flex">
                                            <div class="ps-0 label-form">Voucher Set</div>
                                            <span class="text-danger ms-2">*</span>
                                            </div>
                                        </td>
                                        <td colspan="6">
                                            <Multiselect
                                                :disabled="id > 0"
                                                v-model="voucherSetSelected"
                                                :close-on-select="true"
                                                :searchable="true"
                                                :options="voucherSet"
                                                placeholder="Select voucher set"
                                                :canClear="false"
                                                @change="getVoucherSetDetail"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle" colspan="2">

                                        </td>
                                        <td colspan="6">
                                            <div style="display: inline-flex;gap: 2px">
                                                <div style="color: black;">Where is this voucher valid :</div>
                                                <div style="text-wrap: balance;">{{ voucher_set_voucher_valid }}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                    <td class="align-middle" colspan="2">
                                        
                                    </td>
                                    <td colspan="6">
                                        <div style="display: inline-flex;gap: 2px">
                                            <div style="color: black;">Which product is this voucher applied to :</div>
                                            <div style="text-wrap: balance;">{{ voucher_set_applied_to }}</div>
                                        </div>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td class="align-middle" colspan="2">
                                        
                                    </td>
                                    <td colspan="6">
                                        <div style="display: inline-flex;gap: 2px">
                                            <div style="color: black;">Expiry Date :</div>
                                            <div>{{  voucher_set_expiry_date  }}</div>
                                        </div>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td class="align-middle text-left text-sm" colspan="2">
                                        <div class="d-flex">
                                        <div class="ps-0 label-form">Issue Quantity</div>
                                        <span class="text-danger ms-2">*</span>
                                        </div>
                                    </td>
                                    <td colspan="8">
                                        <material-input
                                            type="number"
                                            @input="validateQuantity"
                                            id="reward_title"
                                            v-model="issue_quantity"
                                            name="reward_title"
                                            maxLength="100"
                                            placeholder="Enter Issue Quantity"
                                        />
                                    </td>
                                    </tr>
                                    <tr>
                                    <td class="align-middle" colspan="2">
                                        <div class="ps-0 label-form">Voucher Code</div>
                                    </td>
                                    <td colspan="1" style="width: fit-content;">
                                        <div style="justify-content: space-between;display: flex;align-items: center;gap: 20px;">
                                            <MaterialRadio
                                            v-model="voucher_code_type"
                                            value="1"
                                            >
                                                Same Voucher Code for all issued Voucher
                                            </MaterialRadio>
                                            <material-input
                                            style="margin-bottom: 20px;"
                                            
                                            v-model="same_voucher"
                                            id="order_total"
                                            name="order_total"
                                            class="align-items-end"
                                            
                                            placeholder="Enter voucher code"
                                            />
                                        </div>
                                        <MaterialRadio
                                        v-model="voucher_code_type"
                                        value="2"
                                        >
                                            Different Voucher Code ( Code is auto-gererated ) 
                                        </MaterialRadio>
                                    </td>
                                    </tr>
                
                                    <tr>
                                    <td colspan="10" style="width: 100%">
                                        <div class="ps-0 label-form">
                                            <div class="d-flex">
                                            <div class="ps-0 label-form">Note</div>
                                            <span class="text-danger ms-2">*</span>
                                            </div>
                                        </div>
                                        <material-textarea v-model="notes" />
                                    </td>
                                    </tr>
                                    <tr>
                                    <td class="align-middle" colspan="8" style="width: 20%">
                                        <div style="display: inline-flex; justify-content: space-between;width: 100%;">
                                            <div class="ps-0 label-form">Issue Code List</div>
                                            <material-button
                                                v-if="id > 0"
                                                @click="showPopupAddMember()"
                                                class="btn-background btn btn-sm capitalize"
                                            >
                                                Send Email
                                            </material-button>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="table-responsive p-0 custom-table">
                                <table class="table align-items-center mb-0">
                                    <thead>
                                    <tr>
                                        <!-- <th class="text-center font-weight-bolder w-5">
                                            <div class="form-check" style="padding-left: 23px;">
                                                <input class="form-check-input" type="checkbox">
                                            </div>
                                        </th> -->
                                        <th class="text-center font-weight-bolder">
                                        {{ $t('Voucher Code') }}
                                        </th>
                                        <th class="text-center font-weight-bolder">
                                        {{ $t('Sent to') }}
                                        </th>
                                        <th class="text-center font-weight-bolder">
                                        {{ $t('Sent date') }}
                                        </th>
                                        <th class="text-center font-weight-bolder">
                                        {{ $t('Redeemed Date') }}
                                        </th>
                                        <th class="text-center font-weight-bolder">
                                        {{ $t('Status') }}
                                        </th>
                                        <!-- <th class="text-center font-weight-bolder">
                                            {{ $t('Action') }}
                                        </th> -->
                                    </tr>
                                    </thead>
                                    <tbody v-if="dataIssueCodeList.length">
                                        <tr v-for="(value, key) in dataIssueCodeList" :key="key">
                                            <!-- <td>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox">
                                                </div>
                                            </td> -->
                                            <td class="text-center text-xss w-5">
                                                {{ value.voucher_code }}
                                            </td>
                                            <td class="text-center text-xss w-25">
                                                {{ value.send_to }}
                                            </td>
                                            <td class="text-center text-xss w-10">
                                                {{ getCreatedDate(value) }}
                                            </td>
                                            <td class="text-center text-xss">
                                                {{ getRedeemedDate(value) }}
                                            </td>
                                            <td class="text-center text-xss">
                                                {{ value.status }}
                                            </td>
                                            <!-- <td class="w-10 align-middle text-right text-sm">
                                            <a
                                                class="btn btn-link text-dark px-2 mb-0"
                                            >
                                                Send Email
                                            </a>
                                            </td> -->
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="8" class="text-center">
                                            No item available
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <pagination
                                v-if="pagination.last_page > 1"
                                :pagination="pagination"
                                :current_page="pagination.current_page"
                                :last_page="pagination.last_page"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
            v-if="snackbar === 'danger' || snackbar === 'success'"
            title=""
            date=""
            :description="message"
            :icon="{ component: 'campaign', color: 'white' }"
            :color="snackbar"
            :close-handler="closeSnackbar"
        />
    </div>
    <AddRecipientsModal
        :keyReload="keyReload"
        :membersIdSelected="membersIdSelected"
        :membersSelected="membersSelected"
        :rewardId="voucherSetSelected"
        :bulkQuantity="issue_quantity"
        :bulkId="id"
        @reload-bulk-list="reloadBulkList"
    ></AddRecipientsModal>
</template>
  
<script>
      import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
    import "bootstrap/dist/js/bootstrap.bundle";
    //   import MaterialLoading from "@/components/MaterialLoading.vue";
    //   import Datepicker from "@vuepic/vue-datepicker";
    //   import "@vuepic/vue-datepicker/dist/main.css";
    import "bootstrap/js/dist/modal";
    import MaterialButton from "@/components/MaterialButton.vue";
    import SettingVoucherService from "../../services/settingVoucher.service";
    //   import ModalConfirm from "@/components/ModalConfirm.vue";
    import MaterialInput from "@/components/MaterialInput.vue";
    import MaterialRadio from "@/components/MaterialRadio.vue";
    import MaterialTextarea from "@/components/MaterialTextarea.vue";
    import Multiselect from "@vueform/multiselect";
    import "@vueform/multiselect/themes/default.css";
    //   import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
    import CommonFunctionService from "../../services/commonFunction.service";
    import moment from "moment";
    import $ from "jquery";
    import AddRecipientsModal from '../setting/email/AddRecipientsModalBulk.vue';
    import BulkVoucherIssue from "../../services/bulkVoucherIssue.service";
    import Pagination from "@/components/Pagination.vue";

    export default {
        name: "Voucher",
        components: {
          MaterialSnackbar,
        //   MaterialLoading,
        MaterialButton,
        //   ModalConfirm,
        MaterialInput,
        MaterialRadio,
        Multiselect,
        //   Datepicker,
        MaterialTextarea,
        //   MaterialCheckbox,
        AddRecipientsModal,
        Pagination
    },
    data() {
      return {
        voucherSet: [],
        issue_quantity: 0,
        backup_issue_quantity: 0,
        same_voucher: '',
        notes: '',
        keyReload: 'key-reload',
        membersIdSelected: [],
        membersSelected: [],
        message: "",
        snackbar: null,
        loaderActive: false,
        merchantCode: "",
        voucher_code_type: '1',
        data: [],
        id: 0,
        voucherSetSelected: 0,
        voucher_set_expiry_date: 'N/A',
        voucher_set_applied_to: 'N/A',
        voucher_set_voucher_valid: 'N/A',
        bulk_id: '',
        bulk_id_number: 0,
        created_at: null,
        pagination: {},
        page: 1,
        dataIssueCodeList: []
      };
    },
    created() {
        this.getDetail(this.$route.params.id);
        this.getList(this.page);
    },
    mounted() {
        const code = localStorage.getItem("MERCHANT_CODE", "");
        this.merchantCode = code ? `/${code}` : "";
        this.id = this.$route.params.id;
    },
    methods: {
        format(date) {
            return CommonFunctionService.formatDateShow(date)
        },
        closeSnackbar() {
            this.snackbar = null;
        },
  
        setTimeOutNotification() {
            setTimeout(() => {
            this.snackbar = null;
            }, 3000);
        },

        backBulkList() {
            var page = this.$route.query.page;
            if (page) {
            this.$router.push("/bulk-voucher-issuance" + "?page=" + page);
            } else {
            this.$router.push("/bulk-voucher-issuance");
            }
        },
  
        showLoader() {
            this.loaderActive = true;
        },
        hideLoader() {
            this.loaderActive = false;
        },
        showPopupAddMember() {
            $('#show-modal-add-recipients').click();
        },
        getDetail(id) {
            this.showLoader();
            BulkVoucherIssue.getDetail(id).then(
                (response) => {
                if (response.data.result.isSuccess) {
                    console.log("response.data.data", response.data.data);
                    this.data = response.data.data;
                    this.voucherSet = response.data.data.voucher_set_array;
                    this.voucherSetSelected = response.data.data.voucher_set_id_selected;
                    this.issue_quantity = response.data.data.quantity;
                    this.backup_issue_quantity = response.data.data.quantity;
                    this.bulk_id = response.data.data.bulk_id;
                    this.bulk_id_number = response.data.data.bulk_id_number;
                    this.notes = response.data.data.note;
                    this.voucher_code_type = response.data.data.is_same_voucher;
                    this.same_voucher = response.data.data.voucher_code;
                    this.created_at = response.data.data.created_at;
                    this.getVoucherSetDetail(response.data.data.voucher_set_id_selected);
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
                this.hideLoader();
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.hideLoader();
                    this.setTimeOutNotification();
                    this.$router.push("/bulk-voucher-issuance");
                }
            );
        },
        getVoucherSetDetail(voucher_set_id) {
            if(voucher_set_id) {
                this.showLoader();
                SettingVoucherService.getDataVoucherDetail(voucher_set_id).then(
                    (response) => {
                    if (response.data.result.isSuccess) {
                        this.voucher_set_expiry_date = response.data.data.reward.expiry_date ? moment(response.data.data.reward.expiry_date).format('D MMMM YYYY') : this.voucher_set_expiry_date;

                        if (response.data.data.reward.fixed_date === 1) {
                            this.voucher_set_expiry_date = response.data.data.reward.expiry_date ? moment(response.data.data.reward.expiry_date).format('D MMMM YYYY') : this.voucher_set_expiry_date;
                        } else if (response.data.data.reward.expired_after === 1 && response.data.data.reward.time_number !== null) {
                            const currentDate = this.created_at ? moment(this.created_at) : moment() ;
                            const newExpiryDate = currentDate.add(response.data.data.reward.time_number, 'days').format('D MMMM YYYY');
                            this.voucher_set_expiry_date = newExpiryDate;
                        }

                        const idArrayStore = response.data.data.reward.apply_for_store;

                        const selectedLabels = idArrayStore.map(id => {
                            const item = response.data.data.branches.find(item => item.value === id);
                            return item ? item.label : null;
                        }).filter(label => label !== null);

                        const resultStore = selectedLabels.length === response.data.data.branches.length - 1 ? 'All Branches' : selectedLabels.join(', ');

                        const idArrayProduct = response.data.data.reward.apply_for_product;

                        const selectedLabelsProduct = idArrayProduct.map(id => {
                            const item = response.data.data.items.find(item => item.value === id);
                            return item ? item.label : null;
                        }).filter(label => label !== null);

                        const resultProduct = selectedLabelsProduct.length === response.data.data.items.length - 1 ? 'All Product' : selectedLabelsProduct.join(', ');

                        // alert(resultStore);
                        // alert(resultProduct);
                        if(resultStore != "") {
                            this.voucher_set_voucher_valid = resultStore;
                        }
                        if(resultProduct != "") {
                            this.voucher_set_applied_to = resultProduct;
                        }
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                    this.hideLoader();
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.hideLoader();
                        this.setTimeOutNotification();
                    }
                );
            }
        },
        validateQuantity() {
            if (this.issue_quantity !== null) {
                if (this.issue_quantity > 100) {
                    this.issue_quantity = 100;
                } else if (this.issue_quantity < 0) {
                    this.issue_quantity = 0;
                }
            }
        },
        createBulkVoucherIssue() {
            // if(this.id == 0) {
                if(this.voucherSetSelected == 0) {
                    this.snackbar = "danger";
                    this.message = "Please select Voucher set";
                    this.setTimeOutNotification();
                    return;
                }
                if(this.issue_quantity == 0) {
                    this.snackbar = "danger";
                    this.message = "Please Enter Issue Quantity";
                    this.setTimeOutNotification();
                    return;
                } 
                if(this.issue_quantity < this.backup_issue_quantity) {
                    this.snackbar = "danger";
                    this.message = "You cannot update Issue Quantity less than current Issue Quantity";
                    this.setTimeOutNotification();
                    return;
                } 
                if(this.voucher_code_type == '1' && !this.same_voucher) {
                    this.snackbar = "danger";
                    this.message = "Please Enter voucher code";
                    this.setTimeOutNotification();
                    return;
                }
                if(this.notes == '') {
                    this.snackbar = "danger";
                    this.message = "Please Enter Note";
                    this.setTimeOutNotification();
                    return;
                }
                var dataForm = {
                    id: this.id,
                    batch_id: this.bulk_id_number,
                    voucher_set_id: this.voucherSetSelected,
                    quantity: this.issue_quantity,
                    is_same_voucher: this.voucher_code_type,
                    voucher_code: this.voucher_code_type === '2' ? "" : this.same_voucher,
                    note: this.notes
                };
                // console.log(dataForm);
                this.showLoader();
                BulkVoucherIssue.createBulkVoucherIssue(dataForm).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.snackbar = "success";
                        this.message = "Save Successfully!";
                        this.setTimeOutNotification();
                        // this.id = response.data.data.id;
                        // this.getDetail(response.data.data.id);
                        // this.getList(this.page);
                        window.location.href = "/bulk-voucher-issuance-generate-voucher/"+response.data.data.id;
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                    this.hideLoader();
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.hideLoader();
                    this.setTimeOutNotification();
                }
                );
            // }
        },
        getList(page) {
            this.isLoadData = false;
            this.showLoader();
            var dataForm = {
                bulk_voucher_issue_id: this.$route.params.id,
                page: page
            };
            this.isLoadData = false;
            this.showLoader();
            BulkVoucherIssue.getIssueCodeList(dataForm).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.dataIssueCodeList = response.data.data.items;
                        this.pagination = response.data.data;
                        this.page = this.pagination.current_page;
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                    this.hideLoader();
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.hideLoader();
                    this.setTimeOutNotification();
                }
            );
        },
        reloadBulkList(mess) {
            this.snackbar = "success";
            this.message = mess;
            this.setTimeOutNotification();
            this.getDetail(this.$route.params.id);
            this.getList(this.page);
        },
        getCreatedDate(item) {
            // return moment(item.created_at).format('D MMMM YYYY');
            return item.sent_date ? moment(item.sent_date).format('YYYY-MM-DD HH:mm') : '';
        },
        getRedeemedDate(item) {
            // return moment(item.created_at).format('D MMMM YYYY');
            return item.redeemed_date ? moment(item.redeemed_date).format('YYYY-MM-DD HH:mm') : '';
        }
    },
  };
  </script>
  <style scoped>
  .text-back {
    color: black !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    font-family: "Roboto Slab", sans-serif !important;
  }
  
  #preview img {
    max-width: 150px;
    max-height: 60px;
  }
  
  .voucher-report {
    background-color: #eeeeee;
    color: #82001e ;
  }
  .link-overview {
    color: #203ace;
    text-decoration: underline;
  }
  .link-overview:hover {
    cursor: pointer;
  }
  </style>