<template>
  <apexchart
    type="pie"
    height="300"
    :options="chartOptions"
    :series="dataChart"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import ColorChart from "../../../config/colorChart.ts";

export default {
  name: "PieChart",
  props: {
    series: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      dataChart: this.series,
      chartOptions: {
        chart: {
          height: 300,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        labels: this.labels,
        legend: {
          show: true,
          position: "left",
          fontSize: "12px",
        },
        colors: ColorChart,
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 250,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
};
</script>

<style></style>
