<template>
    <div>
        <div class="row mb-2 pe-4">
            <div class="col-lg-12 col-md-12 col-12 d-flex align-items-center">
            <div class="color-common">
                <h3 class="title-page mb-0 ps-3">DASHBOARD AND REPORTS</h3>
            </div>
            </div>
        </div>
        <div class="page-header align-items-start min-vh-100 my-transacions">
            <span class="mask bg-white"></span>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12 mt-4">
                        <div class="row">
                            <strong>
                                {{ $t('SLIDES_SHOW_REPORT') }}
                            </strong>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-4">
                        <div class="d-flex mt-4">
                            <div class="col-lg-2 col-md-2 col-6 mg-top-7">
                                {{ $t('Time') }}
                            </div>
                            <div class="col-lg-7 col-md-7 col-6 d-flex justify-content-end">
                                <Multiselect :value="0" v-model="filter_time" :options="times" @change="changeTime($event)" :canClear="false"/>
                            </div>
                            <button class="col-lg-3 col-md-3 btn mb-0 btn-success btn-md null null mx-2 btn-background btn-sm" @click="filterData()"> {{ $t('RUN') }} </button>
                        </div>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-lg-8 col-md-8 col-8">
                        <div class="d-flex mt-4" v-if="isShowTimeCustom">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-3 d-flex justify-content-start align-items-center">
                                    From
                                </div>
                                <div class="col-lg-9 col-md-9 col-9">
                                    <Datepicker :enableTimePicker="false" class="date-time-dashboad" v-model="from_date" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-3 d-flex justify-content-end align-items-center">
                                    To
                                </div>
                                <div class="col-lg-9 col-md-9 col-9">
                                    <Datepicker :enableTimePicker="false" class="date-time-dashboad" v-model="to_date" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-3">
                        <div class="mt-4 mb-4">
                            {{ $t('Count') }}: {{ items.length }}
                        </div>
                    </div>
                </div> 
                <div class="card-custom">
                    <div class="table-responsive p-0 custom-table">
                        <table class="table align-items-center mb-0">
                            
                            <tbody>
                                <tr style="color: black;border-bottom: 2px solid #222 !important;">
                                    <td>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" :checked="isAllSelected" @change="selectAll">
                                        </div>
                                    </td>
                                    <th>{{ $t('Image_ID') }}</th>
                                    <th>{{ $t('Image_File') }}</th>
                                    <th>{{ $t('Image') }}</th>
                                    <th>
                                    {{ $t('Who_can_see_this_image') }}
                                    <i class="fas fa-sort ms-2" @click="sortBy('access')"></i>
                                    </th>
                                    <th>
                                    {{ $t('Display_Period') }}
                                    <i class="fas fa-sort ms-2" @click="sortBy('period')"></i>
                                    </th>
                                    <th>
                                    {{ $t('Created_Date') }}
                                    <i class="fas fa-sort ms-2" @click="sortBy('createdDate')"></i>
                                    </th>
                                    <th>
                                    {{ $t('Creator') }}
                                    <i class="fas fa-sort ms-2" @click="sortBy('creator')"></i>
                                    </th>
                                    <th>
                                    {{ $t('status') }}
                                    <i class="fas fa-sort ms-2" @click="sortBy('status')"></i>
                                    </th>

                                </tr>
                                <tr v-for="item in items" :key="item.id">
                                    <td>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" :value="item.id" v-model="selectedItems">
                                    </div>
                                    </td>
                                    <td>{{item.id}}</td>
                                    <td>
                                    <a :href="item.file_name">{{item.file_name_old}}</a>
                                    </td>
                                    <td><img :src="item.file_name" alt="Image" width="70" height="70"></td>
                                    <td>{{item.slide_tiers_label}}</td>
                                    <td v-if="item.always_display == 0">
                                        {{item.start_date}} {{ item.start_time ? item.start_time.hours+":" : "" }}{{ item.start_time ? item.start_time.minutes : "" }}
                                        <br />{{item.end_date}} {{ item.end_time ? item.end_time.hours+":" : "" }}{{ item.end_time ? item.end_time.minutes : "" }}
                                    </td>
                                    <td v-else-if="item.always_display == 1">
                                        Always Display
                                    </td>
                                    <td>{{item.created_at_formated}}</td>
                                    <td>{{ merchantName }}</td>
                                    <td v-if="item.always_display == 0 && checkExpired(item) === 'Expired'" style="color: #ef5350">
                                        {{ checkExpired(item) }}
                                    </td>
                                    <td v-else-if="item.always_display == 0 && checkExpired(item) !== 'Expired'" style="color: rgb(51, 204, 51)">
                                        {{ checkExpired(item) }}
                                    </td>
                                    <td v-else-if="item.always_display == 1" :style="{ color: item.always_display == 1 ? 'rgb(51, 204, 51)' : null }">{{ checkExpired(item) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <pagination
                    v-if="pagination.last_page > 1"
                    :pagination="pagination"
                    :current_page="pagination.current_page"
                    :last_page="pagination.last_page"
                />
                <div class="text-center mt-4">
                    <button @click="exportFile('excel')" class="btn mb-0 btn-success btn-md null null mx-2 btn-background btn-sm" style="float: right;margin-right: 0px !important;"> {{ $t('EXPORT_TO_EXCEL') }} </button>
                </div>
                <div class="col-lg-3 col-md-3 col-3 d-flex justify-content-end">
                    <ShowHideColumn :columns="columns" :changeCheckbox="changeCheckbox" style="display: none"/>
                </div>
            </div>
            <div class="position-fixed top-1 end-1 z-index-2 message-success">
            <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date=""
                :description="message" :icon="{ component: 'campaign', color: 'white' }" :color="snackbar"
                :close-handler="closeSnackbar" />
            </div>
            <material-loading :active="loaderActive" />
        </div>
        <form :action="`${actionDownload}/merchant/download-slides-show-report`" method="POST">
            <input type="hidden" name="merchantId" :value="merchantIdExport" />
            <input type="hidden" name="merchant_code" :value="merchantCode" />
            <input type="hidden" name="token" :value="token" />
            <input type="hidden" name="time_type" :value="filter_time" />
            <input type="hidden" name="from_date" :value="dateFormat(from_date)" />
            <input type="hidden" name="to_date" :value="dateFormat(to_date)" />
            <input type="hidden" name="action_export" :value="action_export" />
            <input type="hidden" name="action" value="export" />
            <input type="hidden" name="list_field_show" :value="columnShowed" />
            <input type="hidden" name="selectedItems" :value="selectedItems" />
            <input type="hidden" name="merchantName" :value="merchantName" />
            <input id="downloadForm" type="submit" class="d-none" />
        </form>
    </div>
</template>
  
<script>
    import Multiselect from "@vueform/multiselect";
    import "@vueform/multiselect/themes/default.css";
    import Pagination from "@/components/Pagination.vue";
    import DashboardReportService from "../../services/dashboardReport.service";
    import Datepicker from "@vuepic/vue-datepicker";
    import "@vuepic/vue-datepicker/dist/main.css";
    import moment from "moment";
    import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
    import MaterialLoading from "@/components/MaterialLoading.vue";
    import TokenService from "../../services/token.service";
    import $ from "jquery";
    import ShowHideColumn from "@/components/ShowHideColumn.vue";

    export default {
        components: {
            Multiselect,
            Pagination,
            Datepicker,
            MaterialSnackbar,
            MaterialLoading,
            ShowHideColumn
        },
        created() {
            if (this.$route.query.page) {
            this.page = this.$route.query.page;
            }
            this.getList(this.page);
        },
        mounted() {
            const code = localStorage.getItem("MERCHANT_CODE", "");
            const name = localStorage.getItem("MERCHANT_ADMIN_NAME", "");
            this.merchantCode = code;
            this.merchantName = name ? name : "";
        },
        data() {
            return {
                token: TokenService.getLocalAccessToken(),
                actionDownload: process.env.VUE_APP_BASE_URL,
                allSelected: false,
                selectedItems: [],
                pagination: {},
                page: 1,
                items: [],
                message: "",
                snackbar: null,
                loaderActive: false,
                merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
                merchantCode: "",
                merchantName: "",
                searchTimerId: null,
                filter_time: 0,
                isShowTimeCustom: false,
                times: [
                    { value: 0, label: 'Select Time' },
                    { value: 1, label: 'Today' },
                    { value: 2, label: 'Yesterday' },
                    { value: 3, label: 'Last 7 days' },
                    { value: 4, label: 'Last 30 days' },
                    { value: 5, label: 'This month' },
                    { value: 6, label: 'Last 3 months' },
                    { value: 7, label: 'This year' },
                    { value: 8, label: 'Last year' },
                    { value: 9, label: 'Custom' },
                ],
                from_date: null,
                to_date: null,
                action: 'list',
                action_export: 'excel',
                columns: [
                    { value: 'all', label: 'All' },
                    { value: 'image_id', label: 'Image ID' },
                    { value: 'image_file', label: 'Image File' },
                    { value: 'image', label: 'Image' },
                    { value: 'who_can_see_this_image', label: 'Who can see this image' },
                    { value: 'display_period', label: 'Display Period' },
                    { value: 'created_date', label: 'Created Date' },
                    { value: 'creator', label: 'Creator' },
                    { value: 'status', label: 'Status' }
                ],
                columnShowed: [],
            }
        },
        methods: {
            selectAll(event) {
                if (event.target.checked) {
                this.selectedItems = this.items.map(item => item.id)
                } else {
                this.selectedItems = []
                }
            },
            runReport() {
                //TODO: Implement report generation logic
                console.log('Running report...')
            },
            exportToExcel() {
                //TODO: Implement export to excel logic
                console.log('Exporting to excel...')
            },
            changeTime($event) {
                if ($event == 9) {
                    this.isShowTimeCustom = true
                } else {
                    this.isShowTimeCustom = false
                }
            },
            sortBy(field) {
                if (this.sortField === field) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                } else {
                    this.sortField = field;
                    this.sortDirection = 'asc';
                    this.sortCount = 0;
                }
                this.sortCount++;
                if (this.sortCount > 1) {
                    this.items.reverse();
                } else {
                    this.items.sort((a, b) => {
                        let modifier = 1;
                        if (this.sortDirection === 'desc') modifier = -1;
                        if (a[this.sortField] < b[this.sortField]) return -1 * modifier;
                        if (a[this.sortField] > b[this.sortField]) return 1 * modifier;
                        return 0;
                    });
                }
            },
            showLoader() {
                this.loaderActive = true;
            },
            hideLoader() {
                this.loaderActive = false;
            },
            setTimeOutNotification() {
                setTimeout(() => {
                    this.snackbar = null;
                }, 3000);
            },
            checkExpired(item) {
                if(item.always_display == 1) {
                    return "Active";
                } else {
                    if(item && item.start_date) {
                        var start = item.start_date + " " +(item.start_time ? item.start_time.hours+":" : "") + (item.start_time ? item.start_time.minutes : "");
                        var end = item.end_date + " " +(item.end_time ? item.end_time.hours+":" : "") + (item.end_time ? item.end_time.minutes : "");
                        
                        var currentDate = new Date();
                        start = new Date(start);
                        end = new Date(end);

                        if (currentDate >= start && currentDate <= end) {
                            return "Active";
                        } else if (currentDate > end) {
                            return "Expired";
                        } else {
                            return "Awaiting Active";
                        }
                    } else {
                        return "";
                    }
                }
            },
            getList(page) {
                this.isLoadData = false;
                this.items = [];
                this.totalRecords = 0
                this.showLoader();
                var from_date = this.dateFormat(this.from_date);
                var to_date = this.dateFormat(this.to_date);
                DashboardReportService.getDataSlidesShow(page, this.filter_time, from_date, to_date).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            this.items = response.data.data.items
                            this.pagination = response.data.data;
                            this.page = this.pagination.current_page;
                            this.totalRecords = this.pagination.total
                            if (this.pagination.current_page > this.pagination.last_page) {
                            this.page = 1
                            this.getList(this.page);
                            }
                            this.$router.push({
                            query: Object.assign({}, this.$route.query, { page: this.page }),
                            });
                            this.isLoadData = true;
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                        }
                        this.hideLoader();
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.hideLoader();
                        this.setTimeOutNotification();
                    }
                );
            },
            filterData() {
                this.getList(this.page);
            },
            dateFormat(date) {
                if (date == null) {
                    return null;
                } else {
                    return moment(date).format("YYYY-MM-DD")
                }
            },
            exportFile(type = 'excel') {
                if (this.columnShowed.length == 0) {
                    this.snackbar = "danger";
                    this.message = "You must select least 1 column to export file."
                    this.setTimeOutNotification();
                } else {
                    this.action_export = type;
                    setTimeout(() => {
                        $('#downloadForm').click();
                    }, 150);
                }
            },
            changeCheckbox(columnShowed) {
                this.columnShowed = columnShowed
            },
            closeSnackbar() {
                this.snackbar = null;
            },
        },
        computed: {
            isAllSelected: {
                get() {
                    return this.selectedItems.length === this.items.length
                },
                set(value) {
                    if (value) {
                        this.selectedItems = this.items.map(item => item.id)
                    } else {
                        this.selectedItems = []
                    }
                }
            }
        }
  }
</script>

<style>
  .table td {
    vertical-align: middle;
  }
  .mg-top-7 {
      margin-top: 7px;
  }
  @media only screen and (max-width: 991px) {
  .pt-custtom {
    padding-top: 7px;
  }
}
</style>

  