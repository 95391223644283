import api from './api';

class ResetPasswordService {
  sendEmailResetPassword(email) {
    return api.post('/merchant/reset-password', {email: email});
  }

  resetPassword(token, password) {
    return api.post('/merchant/change-password', { token: token, password: password });
  }
}

export default new ResetPasswordService();
