import api from "./api";

class PosStaffService {
  getList(dataForm) {
    return api.get("/merchant/pos-staff", { params: dataForm });
  }

  detail(staffId) {
    return api.get("/merchant/pos-staff/" + staffId);
  }

  store(dataForm) {
    return api.post("/merchant/pos-staff", dataForm);
  }

  update(dataForm) {
    return api.put("/merchant/pos-staff/" + dataForm.id, dataForm);
  }
}

export default new PosStaffService();
