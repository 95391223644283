<template>
  <div>
    <button
      style="display: none"
      type="button"
      class="btn-select-voucher-modal"
      data-bs-toggle="modal"
      data-bs-target="#modalSelectVoucher"
    ></button>
    <div
      class="modal fade"
      id="modalSelectVoucher"
      tabindex="-1"
      aria-labelledby="modalSelectVoucherLabel"
      aria-hidden="true"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Select Voucher</h5>
            <button type="button" style="display: none" class="close btn-close-select-voucher-modal" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-2 d-flex align-items-center">
                Voucher
              </div>
              <div class="col-10">
                <Multiselect
                  :options="vouchers"
                  @change="changeSelectVoucher"
                  :value="voucherDefault"
                  :canClear="false"
                />
                <span class="is-invalid" v-if="isVoucherRequire"
                  >Please select voucher!</span
                >
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-success btn-background btn-sm"
              @click="confirmIssue"
            >
              Issue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
export default {
  name: "ExchangeStatus",
  components: {
    Multiselect,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: "",
    },
    btnYes: {
      type: String,
      default: "",
    },
    isVoucherRequire: {
      type: Boolean,
      default: false,
    },
    voucherDefault: {
      type: Number,
    },
    vouchers: {
      type: Array,
    },
    changeSelectVoucher: {
      type: Function,
      default: () => {},
    },
    confirmIssue: {
      type: Function,
      default: () => {},
    },
  },
};
</script>