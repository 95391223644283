import api from "../api";

class ReportGroupService {
  getList(dataForm) {
    return api.get("/merchant/report-group", { params: dataForm });
  }

  detail(id) {
    return api.get("/merchant/report-group/" + id);
  }

  store(dataForm) {
    return api.post("/merchant/report-group", dataForm);
  }

  update(dataForm) {
    return api.put("/merchant/report-group/" + dataForm.id, dataForm);
  }
  

  delete(id) {
    return api.delete('merchant/report-group/' + id);
  }
}

export default new ReportGroupService();
