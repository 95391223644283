import api from './api';

class GroupService {
  getList(dataForm) {
    return api.post('/merchant/categories', dataForm);
  }

  detail(groupId) {
    return api.post('/merchant/category/' + groupId);
  }

  save(dataForm) {
    return api.post('/merchant/category/group/create-or-update', dataForm, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  delete(groupId) {
    return api.post('/merchant/category/delete/' + groupId);
  }
}

export default new GroupService();
