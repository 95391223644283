import { createI18n } from 'vue-i18n'
import messages from './language'

export default {
    install(app) {
      const savedLocale = localStorage.getItem('locale');
      var languageNow = savedLocale || process.env.VUE_APP_LANG_EN;
      const i18n = createI18n({
        legacy: false,
        locale: languageNow,
        messages,
      })
  
      app.use(i18n);
      localStorage.setItem('locale', languageNow)
    }
}