<template>
  <div>
    <button
      style="display: none"
      type="button"
      class="btn-confirm-select-voucher-modal"
      data-bs-toggle="modal"
      data-bs-target="#modalConfirmSelectVoucher"
    ></button>
    <div
      class="modal fade"
      id="modalConfirmSelectVoucher"
      tabindex="-1"
      aria-labelledby="modalConfirmSelectVoucherLabel"
      aria-hidden="true"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header" style="display: none">
            <button type="button" class="close btn-close-confirm-select-voucher-modal" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row p-3 d-flex justify-content-center">
              {{ message }}
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-success btn-background btn-sm"
              style="width: 77px"
              @click="okIssue"
            >
              {{ btnYes }}
            </button>
            <button
              type="button"
              class="btn btn-success btn-background btn-sm"
              style="width: 77px"
              data-bs-dismiss="modal" aria-label="Close"
              @click="cancelIssue"
            >
              {{ btnNo }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExchangeStatus",
  props: {
    message: {
      type: String,
      default: "",
    },
    btnYes: {
      type: String,
      default: "",
    },
    btnNo: {
      type: String,
      default: "",
    },
    okIssue: {
      type: Function,
      default: () => {},
    },
    cancelIssue: {
      type: Function,
      default: () => {},
    },
  },
};
</script>