import api from "./api";

class MemberPreferenceService {
    add(dataForm) {
        return api.post("/merchant/add-or-update-member-preference", dataForm);
    }
    getList() {
        return api.get("/merchant/get-member-preference");
    }
    getListDropdown(memberId) {
        // return api.post("/merchant/get-member-preference-dropdown", { memberId });
        return api.get('/merchant/get-member-preference-dropdown/' + memberId);
    }
    changeMemberPreference(dataForm) {
        return api.post("/merchant/change-member-preference", dataForm);
    }
}

export default new MemberPreferenceService();
