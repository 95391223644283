<template>
  <div v-if="this.nodeEnv === 'staging'">
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <h4
          class="
            col-lg-10 col-md-10 col-12
            text-back
            d-flex
            align-items-center
            ps-2
          "
        >
          <i
            class="fa fa-chevron-circle-left pe-2"
            aria-hidden="true"
            @click="backProductList()"
            style="cursor: pointer"
          ></i>
          <div @click="backProductList()" style="cursor: pointer">BACK</div>
        </h4>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions pb-5">
      <!-- <span class="mask bg-white"></span> -->
      <div class="container">
        <div class="row card-custom-new pt-3">
          <div class="color-common pb-2 font-weight-bolder row ps-3">
            <h4 class="col-lg-10 col-md-10 col-12 title-page">
              {{
                product.id != null
                  ? "PRODUCTS DETAILS > " + product.name
                  : "PRODUCTS NEW"
              }}
            </h4>
            <div class="col-2 col-md-2 col-12 d-flex justify-content-end">
              <material-button
                @click="saveProduct()"
                class="btn-background btn-sm"
              >
                Save
              </material-button>
            </div>
          </div>
          <div class="px-4 py-0">
            <div class="col-lg-12 col-md-12 col-12 py-1">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-12 label-form">
                  Product ID
                </div>
                <div class="col-lg-7 col-md-7 col-12">
                  ID{{ product.id ?? productIdDummy }}
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 py-1">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-12 label-form">SKU Code</div>
                <div class="col-lg-7 col-md-7 col-12">
                  <material-input
                    id="sku_code"
                    v-model="product.sku"
                    placeholder="Enter sku code"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 py-1">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-12 label-form">
                  Product Type
                </div>
                <div class="col-lg-7 col-md-7 col-12 d-flex">
                  <MaterialRadio
                    class="ps-0"
                    v-model="product.type"
                    id="product_type_1"
                    value="1"
                  >
                    Product
                  </MaterialRadio>
                  <MaterialRadio
                    v-model="product.type"
                    id="product_type_2"
                    value="2"
                  >
                    Merchandise
                  </MaterialRadio>
                  <MaterialRadio
                    v-model="product.type"
                    id="product_type_3"
                    value="3"
                  >
                    Hybrid
                  </MaterialRadio>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 py-1">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-12 label-form">
                  Product Category
                  <span class="text-danger ms-2">*</span>
                </div>
                <div class="col-lg-7 col-md-7 col-12">
                  <Multiselect
                    v-model="product.category_id"
                    :options="categories"
                    :canClear="false"
                  />
                  <span
                    class="is-invalid"
                    v-if="fieldRequired.isCategoryIdRequire"
                    >The field is required.</span
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 py-1">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-12 label-form">
                  Product Name
                  <span class="text-danger ms-2">*</span>
                </div>
                <div class="col-lg-7 col-md-7 col-12">
                  <material-input
                    v-model="product.name"
                    placeholder="Enter product name"
                  />
                  <span class="is-invalid" v-if="fieldRequired.isNameRequire"
                    >The field is required.</span
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 py-1">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-12 label-form">
                  Product Second Name
                </div>
                <div class="col-lg-7 col-md-7 col-12">
                  <material-input
                    v-model="product.second_name"
                    placeholder="Enter product second name"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 py-1">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-12 label-form">
                  Description
                </div>
                <div class="col-lg-7 col-md-7 col-12">
                  <material-textarea v-model="product.description" />
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 py-1">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-12 label-form">Image</div>
                <div class="col-lg-7 col-md-7 col-12 d-flex align-items-center">
                  <div>
                    <material-input
                      style="display: none"
                      type="file"
                      id="image"
                      @change="handleFileUpload($event)"
                      accept="image/*"
                    />
                    <material-button
                      @click="uploadFile()"
                      class="btn-background btn-sm capitalize"
                    >
                      Upload Icon
                    </material-button>
                    <div class="is-invalid" v-if="fieldRequired.isImageRequire">
                      {{ messageImageError }}
                    </div>
                    <div class="pt-2 text-size-13">Upload image maximum 2M</div>
                  </div>
                  <div id="preview" style="position: relative">
                    <img
                      v-if="urlImagePreview || product.image"
                      :src="urlImagePreview ? urlImagePreview : product.image"
                    />
                    <i
                      class="fa fa-times icon-close-sidenav-main danger"
                      v-if="urlImagePreview || product.image"
                      style="
                        position: absolute;
                        top: -8px;
                        left: 100px;
                        color: #f44335;
                        font-size: 20px;
                      "
                      aria-hidden="true"
                      @click="clearImage()"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 py-1">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-12 label-form">
                  Product Link
                </div>
                <div class="col-lg-7 col-md-7 col-12">
                  <material-input
                    placeholder="Enter product link"
                    v-model="product.link"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 py-1">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-12 label-form">Stock</div>
                <div class="col-lg-5 col-md-5 col-6">
                  <material-input
                    placeholder="Enter quantity"
                    v-model="product.quantity"
                  />
                </div>
                <div class="col-lg-3 col-md-3 col-6">
                  <material-checkbox
                    v-model="product.always_available"
                    :checked="product.always_available ? true : false"
                    name="always_available"
                    id="always_available"
                    class="align-items-center checkbox-custom"
                  >
                    <template v-slot:label> Always available </template>
                  </material-checkbox>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 py-1">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-12 label-form">
                  Product Status
                </div>
                <div class="col-lg-7 col-md-7 col-12 d-flex pt-1">
                  <MaterialRadio
                    class="ps-0"
                    v-model="product.status"
                    id="status_1"
                    value="1"
                  >
                    Active
                  </MaterialRadio>
                  <MaterialRadio
                    v-model="product.status"
                    id="status_2"
                    value="2"
                  >
                    Deactivated
                  </MaterialRadio>
                </div>
              </div>
            </div>
            <div
              class="col-lg-12 col-md-12 col-12 py-1"
              v-if="product.type == 1 || product.type == 3"
            >
              <div class="row group-custom">> Prices</div>
            </div>
            <div v-if="product.type == 1 || product.type == 3">
              <div
                class="col-lg-12 col-md-12 col-12 py-1"
                v-for="(item, index) in product.prices"
                :key="item"
              >
                <div class="row">
                  <div class="col-lg-3 col-md-3 col-12 label-form">
                    Price {{ index + 1 }}
                  </div>
                  <div class="col-lg-4 col-md-4 col-4">
                    <material-input
                      v-model="item.price"
                      name="price"
                      placeholder="Enter price"
                    />
                  </div>
                  <div class="col-lg-2 col-md-3 col-6">
                    <MaterialRadio
                      v-model="item.default_price"
                      name="default_price"
                      value="1"
                      :id="`default_price_${index}`"
                      class="radio-custom"
                      v-on:change="changeDefaultPrice(event, index)"
                    >
                      Default Price
                    </MaterialRadio>
                  </div>
                  <div
                    class="col-lg-2 col-md-1 col-2 d-flex align-items-center"
                  >
                    <i
                      class="fas fa-trash-alt"
                      @click="removeRowPrice(index, item.default_price)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-lg-12 col-md-12 col-12 py-1 d-flex justify-content-end"
              v-if="product.type == 1 || product.type == 3"
            >
              <div class="row">
                <a
                  @click="addRowPrice()"
                  class="text-decoration-underline tag-a-custom"
                  >Add more price</a
                >
              </div>
            </div>
            <div
              class="col-lg-12 col-md-12 col-12 py-1"
              v-if="product.type == 2 || product.type == 3"
            >
              <div class="row group-custom">> Exchange Values</div>
            </div>
            <div v-if="product.type == 2 || product.type == 3">
              <div
                class="col-lg-12 col-md-12 col-12 py-1"
                v-for="(item, index) in product.points"
                :key="item"
              >
                <div class="row">
                  <div class="col-lg-3 col-md-3 col-12 label-form">
                    Point {{ index + 1 }}
                  </div>
                  <div class="col-lg-4 col-md-4 col-4">
                    <material-input
                      v-model="item.point"
                      placeholder="Enter point"
                    />
                  </div>
                  <div class="col-lg-2 col-md-3 col-6">
                    <MaterialRadio
                      v-model="item.default_point"
                      name="default_point"
                      value="1"
                      :id="`default_point_${index}`"
                      class="radio-custom"
                      v-on:change="changeDefaultPoint($event, index)"
                    >
                      Default Point
                    </MaterialRadio>
                  </div>
                  <div
                    class="col-lg-2 col-md-1 col-2 d-flex align-items-center"
                  >
                    <i
                      class="fas fa-trash-alt"
                      @click="removeRowPoint(index, item.default_point)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-12 col-md-12 col-12 py-1 d-flex justify-content-end"
              v-if="product.type == 2 || product.type == 3"
            >
              <div class="row">
                <a
                  @click="addRowPoint()"
                  class="text-decoration-underline tag-a-custom"
                  >Add more point</a
                >
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 py-1">
              <div class="row group-custom">> Visibility</div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 py-1">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-12 label-form">
                  Who can see this product/merchandise?
                </div>
                <div class="col-lg-7 col-md-7 col-12">
                  <Multiselect
                    v-model="product.member_group_see"
                    :options="tiers"
                    :canClear="false"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 py-1">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-12 label-form">
                  Who can exchange/obtain this product/merchandise?
                </div>
                <div class="col-lg-7 col-md-7 col-12">
                  <Multiselect
                    v-model="product.member_group_exchange"
                    :options="tiers"
                    :canClear="false"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 py-1">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-12 label-form">
                  Product Tags
                </div>
                <div class="col-lg-7 col-md-7 col-12">
                  <vue3-tags-input
                    :tags="product.tags"
                    placeholder="Enter some tags"
                    @on-tags-changed="handleChangeTag"
                  />
                </div>
              </div>
            </div>
            <div
              class="col-lg-12 col-md-12 col-12 py-1"
              v-for="(item, index) in product.fieldsSelfDefined"
              :key="item"
            >
              <div class="row">
                <div class="col-lg-3 col-md-3 col-12 label-form">
                  Self-defined field {{ index + 1 }}
                </div>
                <div class="col-lg-6 col-md-7 col-10">
                  <material-input
                    v-model="item.content"
                    placeholder="Enter content field self define"
                  />
                </div>
                <div class="col-lg-2 col-md-1 col-2 d-flex align-items-center">
                  <i
                    class="fas fa-trash-alt"
                    @click="removeFieldSelfDefined(index)"
                  ></i>
                </div>
              </div>
            </div>
            <div
              class="col-lg-12 col-md-12 col-12 py-1 d-flex justify-content-end"
            >
              <div class="row">
                <a
                  @click="addRowMoreselfDefineField()"
                  class="text-decoration-underline tag-a-custom"
                >
                  Add more self-defined field</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirm
        message="Are you sure ./ProductDetail.vuelete ?"
        :process-yes="processYes"
      />
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import ProductService from "../../services/product.service";
import ModalConfirm from "@/components/ModalConfirm.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import Vue3TagsInput from "vue3-tags-input";
import $ from "jquery";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
export default {
  name: "find-store",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    ModalConfirm,
    MaterialInput,
    MaterialRadio,
    Multiselect,
    MaterialTextarea,
    MaterialCheckbox,
    Vue3TagsInput,
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      fieldRequired: [],
      merchantCode: "",
      isLoadDoneData: false,
      urlImagePreview: null,
      image: null,
      messageImageError: "",
      isErrorUploadImage: false,
      isSizeImageTooLarge: false,
      product: {
        id: null,
        sku: "",
        type: 1,
        category_id: 0,
        name: "",
        second_name: "",
        description: "",
        image: "",
        link: "",
        prices: [],
        points: [],
        quantity: 0,
        always_available: 0,
        status: 2,
        member_group_see: 0,
        member_group_exchange: 0,
        fieldsSelfDefined: [],
        tags: [],
      },
      categories: [],
      tiers: [],
      disableBtnSubmit: false,
      productIdDummy: "",
      nodeEnv: process.env.VUE_APP_NODE_ENV,
    };
  },
  created() {
    this.getProductDetail();
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
  },
  methods: {
    handleChangeTag(tags) {
      this.product.tags = tags;
    },
    handleFileUpload(event) {
      this.isSizeImageTooLarge = false;
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        this.product.image = null;
        this.urlImagePreview = null;
        if (
          !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
            file.type
          )
        ) {
          this.fieldRequired.isImageRequire = true;
          this.isErrorUploadImage = false;
          this.messageImageError = `File must be an image.`;
        } else {
          this.product.image = file;
          this.urlImagePreview = URL.createObjectURL(this.product.image);
          if (file.size / 1024 / 1024 > 2) {
            this.messageImageError = "Uploaded image must be under 2M.";
            this.fieldRequired.isImageRequire = true;
            this.isErrorUploadImage = true;
            this.isSizeImageTooLarge = true;
          } else {
            this.fieldRequired.isImageRequire = false;
            this.isErrorUploadImage = false;
            this.isSizeImageTooLarge = false;
          }
        }
      } else {
        this.product.image = null;
        this.urlImagePreview = null;
        this.fieldRequired.isImageRequire = true;
        this.isErrorUploadImage = false;
        this.messageImageError = `File must be an image.`;
      }
    },

    clearImage() {
      this.product.image = null;
      this.urlImagePreview = null;
      this.fieldRequired.isImageRequire = false;
      this.isErrorUploadImage = false;
      this.isSizeImageTooLarge = false;
    },

    getProductDetail() {
      var productId = this.$route.params.id;
      this.isLoadDoneData = false;
      this.showLoader();
      if (productId) {
        ProductService.getDataProductDetail(productId).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.product = response.data.data.product;
              this.categories = response.data.data.categories;
              this.tiers = response.data.data.tiers;
              this.isLoadDoneData = true;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      } else {
        ProductService.createNewProduct().then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.product.sku = response.data.data.product_sku;
              this.productIdDummy = response.data.data.product_id;
              this.categories = response.data.data.categories;
              this.tiers = response.data.data.tiers;
              this.isLoadDoneData = true;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    saveProduct() {
      var page = this.$route.query.page;
      this.product.fieldsSelfDefined.forEach((item, index) => {
        if (item.content == "") {
          this.removeFieldSelfDefined(index);
        }
      });
      this.showLoader();
      if (this.isSizeImageTooLarge) {
        this.isErrorUploadImage = true;
        this.fieldRequired.isImageRequire = true;
      } else {
        this.fieldRequired.isImageRequire = false;
      }
      if (this.processValidate() && !this.isErrorUploadImage) {
        this.product.always_available = this.product.always_available ? 1 : 0;
        ProductService.addOrUpdateProduct(this.product).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message = "Save Product Success";
              this.product.id = response.data.data.id
              if (page) {
                this.$router.push(
                    "/product/" +
                    response.data.data.id +
                    "?page=" +
                    page
                );
              } else {
                this.$router.push(
                  "/product/" + response.data.data.id
                );
              }
              this.setTimeOutNotification();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      } else {
        this.hideLoader();
      }
    },

    changeDefaultPrice(event, index) {
      this.product.prices.forEach((item, indexItem) => {
        if (index != indexItem) {
          item.default_price = 0;
        } else {
          item.default_price = event.target.value;
        }
      });
    },

    changeDefaultPoint(event, index) {
      this.product.points.forEach((item, indexItem) => {
        if (index != indexItem) {
          item.default_point = 0;
        } else {
          item.default_point = event.target.value;
        }
      });
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    addRowPrice() {
      if (this.product.prices.length == 0) {
        this.product.prices.push({
          price: 0,
          default_price: 1,
        });
      } else {
        this.product.prices.push({
          price: 0,
          default_price: 0,
        });
      }
    },

    addRowPoint() {
      if (this.product.points.length == 0) {
        this.product.points.push({
          point: 0,
          default_point: 1,
        });
      } else {
        this.product.points.push({
          point: 0,
          default_point: 0,
        });
      }
    },

    addRowMoreselfDefineField() {
      this.product.fieldsSelfDefined.push({
        content: "",
      });
    },

    removeRowPrice(index, defaultPrice) {
      if (this.product.prices.length > 0) {
        this.product.prices.splice(index, 1);
      }
      if (defaultPrice == 1) {
        this.product.prices[0].default_price = 1;
      }
    },

    removeRowPoint(index, defaultPoint) {
      if (this.product.points.length > 0) {
        this.product.points.splice(index, 1);
      }
      if (defaultPoint == 1) {
        this.product.points[0].default_point = 1;
      }
    },

    removeFieldSelfDefined(index) {
      if (this.product.fieldsSelfDefined.length > 0) {
        this.product.fieldsSelfDefined.splice(index, 1);
      }
    },

    resetRequired() {
      this.fieldRequired.isNameRequire = false;
      this.fieldRequired.isCategoryIdRequire = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.product.name == "" || this.product.name == null) {
        this.fieldRequired.isNameRequire = true;
        isValid = false;
      }
      if (
        this.product.category_id == "" ||
        this.product.category_id == null ||
        this.product.category_id == 0
      ) {
        this.fieldRequired.isCategoryIdRequire = true;
        isValid = false;
      }
      return isValid;
    },

    uploadFile() {
      $("#image").click();
    },

    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    backProductList() {
      var page = this.$route.query.page;
      if (page) {
        this.$router.push("/products" + "?page=" + page);
      } else {
        this.$router.push("/products");
      }
    },
  },
};
</script>
<style scoped>
.text-back {
  color: black !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: "Roboto Slab", sans-serif !important;
}
.card-body .label-form {
  color: black !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.card-body .group-custom {
  color: #72959d !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.radio-custom {
  margin-top: 11px !important;
}

.tag-a-custom {
  color: #007bff !important;
  text-decoration: underline !important;
  cursor: pointer;
}

#preview img {
  max-width: 150px;
  max-height: 60px;
}
</style>