<template>
    <div>
      <div v-for="(item, index) in form" :key="item">
        <div
          class="row card-custom-new mt-2 pt-0"
          v-bind:class="typePoint == 2 ? 'background-superior' : ''"
        >
          <div class="table-responsive p-2 custom-table-noborder">
            <table class="table align-items-center mb-0">
              <tbody>
                <tr>
                  <td class="align-middle text-left text-sm" colspan="2">
                    <div class="ps-0 text-title">
                      {{ $t('STANDARD_RULE') }} {{ index + 1 }}
                    </div>
                  </td>
                  <td colspan="6">
                    <div class="d-flex justify-content-end">
                      <material-button
                        @click="savePoint(typePoint, index)"
                        :disabled="disableBtnSubmit"
                        class="mx-2 btn-background btn-sm"
                      >
                        {{ $t('Save') }}
                      </material-button>
                      <material-button
                        @click="removePoint(typePoint, index)"
                        :disabled="disableBtnSubmit"
                        class="mx-2 btn-background btn-sm"
                      >
                        {{ $t('Delete') }}
                      </material-button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="align-middle text-left text-sm" colspan="2">
                    <div class="d-flex">
                      <div class="ps-0 label-form">{{ $t('Rule_Name') }}</div>
                      <span class="text-danger ms-2">*</span>
                    </div>
                  </td>
                  <td colspan="6">
                    <material-input
                      v-model="item.rule_name"
                      id="rule_name"
                      :name="`rule_name_${index}`"
                      placeholder="Enter rule name"
                    />
                    <span
                      class="is-invalid"
                      v-if="
                        typePoint == 1
                          ? fieldRequiredStandard[index].isRuleNameRequire
                          : fieldRequiredSuperior[index].isRuleNameRequire
                      "
                      >The field is required.</span
                    >
                  </td>
                </tr>
                <tr>
                  <td class="align-middle" colspan="2">
                    <div class="d-flex">
                      <div class="ps-0 label-form">{{ $t('Spend_Minimum') }}</div>
                      <span class="text-danger ms-2">*</span>
                    </div>
                  </td>
                  <td colspan="2">
                    <material-input
                      type="number"
                      v-model="item.spend_every"
                      id="spend_every"
                      :name="`spend_every_${index}`"
                      placeholder="Enter spend every"
                    />
                    <span
                      class="is-invalid"
                      v-if="
                        typePoint == 1
                          ? fieldRequiredStandard[index].isSpendEveryRequire
                          : fieldRequiredSuperior[index].isSpendEveryRequire
                      "
                      >The field is required.</span
                    >
                  </td>
                  <td class="label-form text-center" colspan="1">{{ $t('Rebate') }}</td>
                  <td colspan="1">
                    <material-input
                      type="number"
                      v-model="item.rebate"
                      id="rebate"
                      :name="`rebate_${index}`"
                      placeholder="Enter rebate"
                    />
                    <span
                      class="is-invalid"
                      v-if="
                        typePoint == 1
                          ? fieldRequiredStandard[index].isRebateRequire
                          : fieldRequiredSuperior[index].isRebateRequire
                      "
                      >The field is required.</span
                    >
                  </td>
                </tr>
                <tr>
                  <td class="align-middle" colspan="2">
                    <div class="ps-0 label-form">{{ $t('Duration') }}</div>
                  </td>
                  <td class="label-form">{{ $t('From') }}</td>
                  <td>
                    <Datepicker
                      v-model="item.start_date"
                      :enableTimePicker="false"
                      class="date-time"
                      :format="format"
                    />
                  </td>
                  <td class="label-form text-center">{{ $t('To') }}</td>
                  <td>
                    <Datepicker
                      v-model="item.end_date"
                      :enableTimePicker="false"
                      class="date-time"
                      :format="format"
                    />
                  </td>
                  <td>
                    <material-checkbox
                      v-if="typePoint == 1"
                      v-model="item.no_end_date"
                      :checked="item.no_end_date ? true : false"
                      value="1"
                      :id="`checkbox_id_${typePoint}_${index}`"
                      class="checkbox-auto-save"
                      style="padding-left: 1.73em !important"
                    >
                      <template v-slot:label> {{ $t('No_end_date') }} </template>
                    </material-checkbox>
                  </td>
                </tr>
                <tr>
                  <td class="align-middle" colspan="2">
                    <div class="ps-0 label-form">{{ $t('Hours') }}</div>
                  </td>
                  <td class="label-form">{{ $t('From') }}</td>
                  <td>
                    <Datepicker
                      v-model="item.start_time"
                      timePicker
                      class="date-time"
                    />
                  </td>
                  <td class="label-form text-center">{{ $t('To') }}</td>
                  <td>
                    <Datepicker
                      v-model="item.end_time"
                      timePicker
                      class="date-time"
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td class="align-middle" colspan="2">
                    <div class="ps-0 label-form"></div>
                  </td>
                  <td colspan="6">
                    <div>
                      <span
                        class="is-invalid"
                        v-if="
                          typePoint == 1
                            ? fieldRequiredStandard[index].isDateRequire
                            : fieldRequiredSuperior[index].isDateRequire
                        "
                        >{{
                          typePoint == 1
                            ? textRequiredStandardDateCustom
                            : textRequiredSuperiorDateCustom
                        }}</span
                      >
                    </div>
                  </td>
                </tr>
                <!-- <tr>
                  <td class="align-middle" colspan="2">
                    <div class="ps-0 label-form">{{ $t('Pending_time_before_point_can_be_redeemed') }}</div>
                  </td>
                  <td colspan="2">
                    <Multiselect
                      v-model="item.pending_time_use_point"
                      :options="pendingTimeUsePoint"
                      placeholder="Select pending time"
                      :canClear="false"
                    />
                  </td>
                </tr> -->
                <tr>
                  <td class="align-middle" colspan="2">
                    <div class="ps-0 label-form">{{ $t('Expiry_of_each_point_amount') }}</div>
                  </td>
                  <td colspan="2">
                    <Multiselect
                      v-model="item.expire_of_point_amount"
                      :options="expireOfPointAmount"
                      placeholder="Select expire"
                      :canClear="false"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import "bootstrap/dist/js/bootstrap.bundle";
  import "bootstrap/js/dist/modal";
  import MaterialButton from "@/components/MaterialButton.vue";
  import MaterialCheckbox from "./MaterialCheckbox.vue";
  import MaterialInput from "./MaterialInput.vue";
  import Datepicker from "@vuepic/vue-datepicker";
  import "@vuepic/vue-datepicker/dist/main.css";
  import Multiselect from "@vueform/multiselect";
  import "@vueform/multiselect/themes/default.css";
  import CommonFunctionService from "../services/commonFunction.service";
  
  export default {
    name: "PointRule",
    props: {
      id: {
        type: String,
        required: true,
      },
      form: {
        type: Array,
        required: true,
      },
      typePoint: {
        type: Number,
      },
      textRequiredStandardDateCustom: {
        type: String,
        required: true,
      },
      textRequiredSuperiorDateCustom: {
        type: String,
        required: true,
      },
      savePoint: {
        type: Function,
        default: () => {},
      },
      removePoint: {
        type: Function,
        default: () => {},
      },
      fieldRequiredStandard: {
        type: Array,
        required: true,
      },
      fieldRequiredSuperior: {
        type: Array,
        required: true,
      },
      pendingTimeUsePoint: {
        type: Array,
      },
      expireOfPointAmount: {
        type: Array,
      },
    },
    components: {
      MaterialInput,
      MaterialButton,
      MaterialCheckbox,
      Datepicker,
      Multiselect
    },
    methods: {
      format(date) {
        return CommonFunctionService.formatDateShow(date)
      },
    }
  };
  </script>
  <style scoped>
  .background-superior {
    background: #dcd3c6 !important;
  }
  </style>
  