<template>
  <div>
    <button
      style="display: none"
      type="button"
      class="btn-voucher-exchnaged"
      data-bs-toggle="modal"
      data-bs-target="#member-voucher"
    ></button>
    <div
      class="modal fade"
      id="member-voucher"
      tabindex="-1"
      aria-labelledby="member-voucher-label"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ getTitles() }}</h5>
            <div class="dropdown ">
              <button class="btn btn-secondary dropdown-toggle btn-sm d-flex align-items-center mb-0 ms-2" type="button" id="dropdownMenuButton"
                data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
                Download
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li>
                  <a class="dropdown-item" @click="exportFile('csv')" href="#">Export CSV</a>
                </li>
                <li>
                  <a class="dropdown-item" @click="exportFile('xlsx')" href="#">Export Excel</a>
                </li>
              </ul>
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div class="table-responsive custom-table">
              <table
                class="table align-items-center mb-0"
                style="border-top: 1px solid #d9d9d9"
              >
                <thead>
                  <tr>
                    <th class="text-center font-weight-bolder">Issued Date</th>
                    <th class="text-center font-weight-bolder">
                      Redeemed Date
                    </th>
                    <th class="text-left font-weight-bolder">Voucher Code</th>
                    <th class="text-center font-weight-bolder">Expiry Date</th>
                    <th
                      v-if="typeStock == 2"
                      class="text-center font-weight-bolder"
                    >
                      Revenue
                    </th>
                  </tr>
                </thead>
                <tbody v-if="vouchers.length">
                  <tr v-for="(value, key) in vouchers" :key="key">
                    <td class="text-center">{{ value.exchange_date }}</td>
                    <td class="text-center">{{ value.redeemed_date }}</td>
                    <td class="text-center">{{ value.code }}</td>
                    <td class="text-center">{{ value.expired_date }}</td>
                    <td v-if="typeStock == 2">
                      {{ value.payment_amount ?? 0 }}
                    </td>
                  </tr>
                  <tr v-if="typeStock == 2">
                    <td colspan="4"></td>
                    <td>{{totalRevenue}}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="5" class="text-center">No item available</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form :action="`${actionDownload}/merchant/download-vouchers-exchanged`" method="POST">
      <input type="hidden" name="merchantId" :value="merchantIdExport" />
      <input type="hidden" name="merchant_code" :value="merchantCodeExport" />
      <input type="hidden" name="token" :value="token" />
      <input type="hidden" name="id" :value="id" />
      <input type="hidden" name="type" :value="typeStock" />
      <input type="hidden" name="action_export" :value="action_export" />
      <input type="hidden" name="action" value="export" />
      <input id="downloadForm" type="submit" class="d-none" />
    </form>
  </div>
</template>

<script>
import $ from "jquery";
import TokenService from "../../services/token.service";
export default {
  name: "VoucherExchanged",
  props: {
    id: {
      type: Number,
    },
    typeStock: {
      type: Number,
      default: 1,
    },
    totalRevenue: {
      type: String,
      default: "S$0",
    },
    vouchers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      action_export: 'csv',
      actionDownload: process.env.VUE_APP_BASE_URL,
      token: TokenService.getLocalAccessToken(),
      merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
      merchantCodeExport: localStorage.getItem("MERCHANT_CODE", ""),
    };
  },
  methods: {
    exportFile(type = 'csv') {
      this.action_export = type;
      setTimeout(() => {
        $('#downloadForm').click();
      }, 150);
    },
    getTitles() {
      if (this.typeStock == 1) {
        return "Redeemed Voucher List";
      }
      if (this.typeStock == 2) {
        return "Total revenue Voucher List";
      }
      if (this.typeStock == 3) {
        return "Total Expired Voucher List";
      }
      if (this.typeStock == 4) {
        return "Redeemable Voucher List";
      }
    },
  },
};
</script>
<style scoped>
.btn-close {
  color: #222222;
}
.btn-close:hover {
  color: #222222;
}
</style>
