import api from './api';

class SettingCampaignService {
  getDataCampaigns(dataForm) {
    return api.post('/merchant/campaigns', dataForm);
  }

  getDataCampaignDetail(campaignId) {
    if (campaignId) {
      return api.post('/merchant/campaign/' + campaignId);
    } else {
      return api.post('/merchant/campaign');
    }
  }

  addOrUpdateCampaign(dataForm) {
    return api.post('/merchant/campaign-create-or-update', dataForm, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  createGroupAudience(dataForm) {
    return api.post('/merchant/campaign-create-group-audience', dataForm);
  }

  getDataTargetAudience() {
    return api.post('/merchant/target-audience');
  }

  deleteCampaign(campaignId) {
    return api.post('/merchant/campaign/delete/' + campaignId);
  }
}

export default new SettingCampaignService();
