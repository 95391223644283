import api from './api';

class SettingTierService {
  getDataTier() {
    return api.get('/merchant/tier-setting');
  }

  addOrUpdateTier(dataForm) {
    return api.post('/merchant/tier-setting/create-or-update', dataForm, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    });
  }

  deleteTier(discountId) {
    return api.post('/merchant/tier-setting/delete/' + discountId);
  }
}

export default new SettingTierService();
